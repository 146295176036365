import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Checkbox,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  ListItemText,
} from "@material-ui/core";
import { getQueryTab } from "../common/CygniRequests";
import { UserContext } from "../contexts/user-context";
import { TotalNotasTweets } from "./components/TotalNotasTweets";
import { SentimientoNotasTweets } from "./components/SentimientoNotasTweets";
import { PosicionamientoNotas } from "./components/PosicionamientoNotas";
import { ParticipacionNotas } from "./components/ParticipacionNotas";
import { TiposNotas } from "./components/TiposNotas";
import { AmbitoNotas } from "./components/AmbitoNotas";
import { ClasificacionNotas } from "./components/ClasificacionNotas";
import { TemasRel } from "./components/TemasRel";
import { Valoracion } from "../common/vis/Valoracion";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  boldText: {
    fontWeight: "bold",
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const Visualizacion = (props) => {
  // userContext
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const visId = props.id;
  const [qry, setQry] = useState({
    fi: formatDate(new Date()),
    ff: formatDate(new Date()),
    id: [-1],
    nombre: "Todos",
    query: "ALL",
    twitter: "ALL",
  });
  const [current, setCurrent] = useState(null);
  const [vis, setVis] = useState(null);
  const [visControl, setVisControl] = useState({
    all: false,
    // totalNotas: false,
    // totalTweets: false,
    totalNotasPorEdo: false,
    sentimientoNotas: false,
    sentimientoTweets: false,
    temasRelNotas: false,
    temasRelTweets: false,
    posicionamientoNotas: false,
    tiposMedios: false,
    participacionNotas: false,
    ambitoNotas: false,
    clasificacionNotas: false,
    porMedioNotas: false,
    porConductorNotas: false,
    porGeneroNotas: false,
  });

  useEffect(() => {
    getQueryTab("USR-VIS", userContext.id, visId)
      .then(function (response) {
        setVis(response.data[visId]);

        var oldState = [-1];
        response.data[visId].queries.forEach((el) => {
          oldState.push(el.qry_id);
        });

        setQry({
          ...qry,
          id: oldState,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    var c = 0;
    Object.values(visControl).forEach((v) => {
      if (v) c++;
    });
    if (c === 10 && !visControl.all) {
      setVisControl({
        ...visControl,
        all: true,
      });
    } else if (c === 10 && visControl.all) {
      setVisControl({
        ...visControl,
        all: false,
      });
    }
  }, [visControl]);

  const handleVisSelect = (val) => {
    // if (event.target.name === "all") {
    if (val[0] === "all") {
      setVisControl({
        all: !visControl.all,
        // totalNotas: event.target.checked,
        // totalTweets: event.target.checked,
        totalNotasPorEdo: !visControl.all,
        sentimientoNotas: !visControl.all,
        sentimientoTweets: !visControl.all,
        temasRelNotas: !visControl.all,
        temasRelTweets: !visControl.all,
        posicionamientoNotas: !visControl.all,
        tiposMedios: !visControl.all,
        participacionNotas: !visControl.all,
        ambitoNotas: !visControl.all,
        clasificacionNotas: !visControl.all,
        porMedioNotas: !visControl.all,
        porConductorNotas: !visControl.all,
        porGeneroNotas: !visControl.all,
      });
    } else {
      setVisControl({
        ...visControl,
        [val]: !visControl[val],
      });
    }
  };

  const handleQrySelect = (val) => {
    if (val[0] === -1) {
      var oldState = qry.id;
      if (oldState.includes(-1)) {
        setQry({
          ...qry,
          id: [],
        });
      } else {
        var oldState = [-1];
        vis.queries.forEach((el) => {
          oldState.push(el.qry_id);
        });
        setQry({
          ...qry,
          id: oldState,
        });
      }
    } else {
      var oldState = qry.id;
      if (oldState.includes(val[0])) {
        const index = oldState.indexOf(val[0]);
        if (index > -1) oldState.splice(index, 1);
        if (oldState.includes(-1)) {
          oldState.splice(oldState.indexOf(-1), 1);
        }
        setQry({
          ...qry,
          id: oldState,
        });
      } else {
        var oldState = qry.id;
        oldState.push(val[0]);
        if (oldState.length === vis.queries.length && !oldState.includes(-1))
          oldState.push(-1);
        setQry({
          ...qry,
          id: oldState,
        });
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" color="primary" align="center">
        {vis && `"${vis.nombre}"`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        {vis && `Tablero: "${vis.nom_tab}"`}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ paddingRight: "1rem" }}
          >
            <InputLabel id="demo-simple-select-outlined-label2">
              Visualizaciones
            </InputLabel>
            <Select
              multiple
              labelId="demo-simple-select-outlined-label2"
              id="demo-simple-select-outlined2"
              value={[]}
              onChange={(event) => handleVisSelect(event.target.value)}
              label="Ver visualizaciones"
            >
              <MenuItem value="">
                <em>Seleccione...</em>
              </MenuItem>
              <MenuItem value="all">
                <Checkbox
                  checked={visControl.all}
                  // onChange={handleChange}
                  name="all"
                />
                <ListItemText primary="TODAS" />
              </MenuItem>
              <MenuItem value="totalNotasPorEdo">
                <Checkbox
                  checked={visControl.totalNotasPorEdo}
                  // onChange={handleChange}
                  name="totalNotasPorEdo"
                />
                <ListItemText primary="Total de Notas por Estado" />
              </MenuItem>
              <MenuItem value="sentimientoNotas">
                <Checkbox
                  checked={visControl.sentimientoNotas}
                  // onChange={handleChange}
                  name="sentimientoNotas"
                />
                <ListItemText primary="Valoración de Notas" />
              </MenuItem>
              {/* <MenuItem value="sentimientoTweets">
                <Checkbox
                  checked={visControl.sentimientoTweets}
                  // onChange={handleChange}
                  name="sentimientoTweets"
                />
                <ListItemText primary="Sentimiento de Tweets" />
              </MenuItem> */}
              <MenuItem value="temasRelNotas">
                <Checkbox
                  checked={visControl.temasRelNotas}
                  // onChange={handleChange}
                  name="temasRelNotas"
                />
                <ListItemText primary="Palabras Relevantes Medios" />
              </MenuItem>
              <MenuItem value="temasRelTweets">
                <Checkbox
                  checked={visControl.temasRelTweets}
                  // onChange={handleChange}
                  name="temasRelTweets"
                />
                <ListItemText primary="Palabras Relevantes Twitter" />
              </MenuItem>
              <MenuItem value="posicionamientoNotas">
                <Checkbox
                  checked={visControl.posicionamientoNotas}
                  // onChange={handleChange}
                  name="posicionamientoNotas"
                />
                <ListItemText primary="Posicionamiento Medios" />
              </MenuItem>
              <MenuItem value="tiposMedios">
                <Checkbox
                  checked={visControl.tiposMedios}
                  // onChange={handleChange}
                  name="tiposMedios"
                />
                <ListItemText primary="Tipos de Medios" />
              </MenuItem>
              <MenuItem value="participacionNotas">
                <Checkbox
                  checked={visControl.participacionNotas}
                  // onChange={handleChange}
                  name="participacionNotas"
                />
                <ListItemText primary="Participación Medios" />
              </MenuItem>
              <MenuItem value="ambitoNotas">
                <Checkbox
                  checked={visControl.ambitoNotas}
                  // onChange={handleChange}
                  name="ambitoNotas"
                />
                <ListItemText primary="Ámbito Medios" />
              </MenuItem>
              <MenuItem value="clasificacionNotas">
                <Checkbox
                  checked={visControl.clasificacionNotas}
                  // onChange={handleChange}
                  name="clasificacionNotas"
                />
                <ListItemText primary="Clasificación Medios" />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ paddingRight: "1rem" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Queries
            </InputLabel>
            <Select
              multiple
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={[]}
              // onChange={(event) => setQry({ ...qry, id: event.target.value })}
              onChange={(event) => handleQrySelect(event.target.value)}
              label="Ver por query"
            >
              <MenuItem value={-1}>
                <Checkbox
                  checked={qry.id.includes(-1)}
                  // onChange={handleChange}
                  name="Todos los queries del tablero"
                />
                <ListItemText primary="Todos los queries del tablero" />
              </MenuItem>
              {vis &&
                vis.queries &&
                vis.queries.map((el, i) => (
                  <MenuItem key={i} value={el.qry_id}>
                    <Checkbox
                      checked={
                        qry.id.includes(el.qry_id) || qry.id.includes(-1)
                      }
                      // onChange={handleChange}
                      name={el.qry_nombre}
                    />
                    <ListItemText primary={el.qry_nombre} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="fi"
            name="fi"
            label="Fecha Inicial"
            type="date"
            defaultValue={qry.fi}
            onChange={(event) => setQry({ ...qry, fi: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="ff"
            name="ff"
            label="Fecha Final"
            type="date"
            defaultValue={qry.ff}
            onChange={(event) => setQry({ ...qry, ff: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              // if (
              //   qry.id !== -1 &&
              //   Object.values(visControl).indexOf(true) > -1
              // ) {
              //   setCurrent(qry);
              // }
              setCurrent(qry);
            }}
          >
            Consultar
          </Button>
        </Grid>

        {current && visControl.totalNotasPorEdo && (
          <Grid item xs={12} md={12}>
            <TotalNotasTweets
              tipo="TOTAL_EDO"
              fuente="MEDIOS"
              queries={vis.queries}
              current={current}
            />
          </Grid>
        )}
        {current && visControl.sentimientoNotas && (
          <Grid item xs={12} md={12}>
            <Valoracion
              fuente="MEDIOS"
              queries={vis.queries}
              current={current}
            />
            {/* <SentimientoNotasTweets
              fuente="MEDIOS"
              queries={vis.queries}
              current={current}
            /> */}
          </Grid>
        )}
        {/* {current && visControl.sentimientoTweets && (
          <Grid item xs={12} md={6}>
            <SentimientoNotasTweets
              fuente="REDES"
              queries={vis.queries}
              current={current}
            />
          </Grid>
        )} */}
        {current && visControl.temasRelNotas && (
          <Grid item xs={12} md={6}>
            <TemasRel fuente="MEDIOS" queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.temasRelTweets && (
          <Grid item xs={12} md={6}>
            <TemasRel fuente="REDES" queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.posicionamientoNotas && (
          <Grid item xs={12} md={6}>
            <PosicionamientoNotas queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.tiposMedios && (
          <Grid item xs={12} md={4}>
            <TiposNotas queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.participacionNotas && (
          <Grid item xs={12} md={4}>
            <ParticipacionNotas queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.ambitoNotas && (
          <Grid item xs={12} md={4}>
            <AmbitoNotas queries={vis.queries} current={current} />
          </Grid>
        )}
        {current && visControl.clasificacionNotas && (
          <Grid item xs={12} md={4}>
            <ClasificacionNotas queries={vis.queries} current={current} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
