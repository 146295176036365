import React, { useEffect, useState } from "react";
import { TagCloud } from "react-tagcloud";
import { queryPrep } from "../../common/Cygni";
import { VisContainer } from "../../common/CygniContainers";
import { CygniLoadingFix } from "../../common/CygniLoading";
import { getTemas } from "../../common/CygniRequests";

export const TemasRel = React.memo(({ fuente, current, queries }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);

    var words = [];

    var resMap = queries.map(async (el, i) => {
      if (
        current.id.includes(-1) ||
        (!current.id.includes(-1) && current.id.includes(el.qry_id))
      ) {
        var nombre = "";
        if (fuente === "MEDIOS") nombre = queryPrep(el.query);
        if (fuente === "REDES") nombre = el.twitter;

        if (
          !el.twitter ||
          el.twitter === "" ||
          el.twitter === "null" ||
          el.twitter === null
        ) {
          if (el.query.includes(",")) {
            nombre = '"' + el.query.split(",")[0] + '"';
          } else if (el.query.includes(".")) {
            nombre = '"' + el.query.split(".")[0] + '"';
          } else if (el.query.includes("!")) {
            nombre = '"' + el.query.split(".!")[0] + '"';
          } else {
            nombre = '"' + el.query + '"';
          }
        }

        var res = await getTemas(
          nombre,
          fuente === "MEDIOS" ? "m" : "t",
          "d" //fuente === "MEDIOS" ? "s" : "d"
        );

        res.forEach((r) => {
          var found = words.findIndex((f) => f.value === r.value);
          if (found !== -1) {
            words[found].count += r.count;
          } else {
            words.push(r);
          }
        });
      }
    });

    // Wait for all requests, and then setState
    Promise.all(resMap).then(() => {
      setData(words);
    });
  }, [current, queries]);

  return (
    <VisContainer
      title={"Palabras " + fuente}
      desc={"Palabras relevantes en " + fuente}
      time={data ? true : false}
      showMenu={false}
    >
      {!data && <CygniLoadingFix />}
      {data && <TagCloud minSize={25} maxSize={55} tags={data} />}
    </VisContainer>
  );
});
