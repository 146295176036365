import React, { useEffect, useState } from "react";
import { getGraficas, getSentRedes } from "../../common/CygniRequests";
import { CygniLineChart } from "../../common/CygniLineChart";
import { CygniBarChart } from "../../common/CygniBarChart";
import { CygniLoadingFix } from "../../common/CygniLoading";
import { queryPrep } from "../../common/Cygni";
import { VisContainer } from "../../common/CygniContainers";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

export const SentimientoNotasTweets = React.memo(
  ({ fuente, queries, current }) => {
    const [data, setData] = useState(null);
    const [keyValBar, setKeyValBar] = useState([]);
    const r = [
      "#ff0000",
      "#730707",
      "#7d3c3c",
      "#856d6d",
      "#360101",
      "#fa8655",
      "#ba3a04",
    ];
    const y = [
      "#ffd900",
      "#f2e079",
      "#9c8400",
      "#635a26",
      "#97ab02",
      "#a4ad5c",
      "#3d4500",
    ];
    const b = [
      "#00aaff",
      "#5a869c",
      "#003854",
      "#6a7aa1",
      "#00174f",
      "#6b638a",
      "#100247",
    ];
    const [png, ref] = useRechartToPng();

    const downloadChart = React.useCallback(async () => {
      FileSaver.saveAs(png, "sent_val.png");
    }, [png]);

    useEffect(() => {
      setData(null);
      if (fuente === "MEDIOS") {
        var sents = [];
        var kvs = [];

        var resMap = queries.map(async (el, i) => {
          if (
            current.id.includes(-1) ||
            (!current.id.includes(-1) && current.id.includes(el.qry_id))
          ) {
            var res = await getGraficas(
              queryPrep(el.query),
              current.fi.replace(/-/g, "_"),
              current.ff.replace(/-/g, "_"),
              "cygnimoviltendencia"
            );

            kvs.push({ ["+" + el.qry_nombre]: b[i] + "|" + el.qry_id });
            kvs.push({ ["-" + el.qry_nombre]: r[i] + "|" + el.qry_id });
            kvs.push({ ["*" + el.qry_nombre]: y[i] + "|" + el.qry_id });

            Object.entries(res.porFecha).forEach(([k, v]) => {
              var f = sents.find((fel) => fel.name === v.name);
              if (f) {
                f["+" + el.qry_nombre] = v.pos;
                f["-" + el.qry_nombre] = v.neg;
                f["*" + el.qry_nombre] = v.neu;
              } else {
                var val = {};
                val.name = v.name;
                val["+" + el.qry_nombre] = v.pos;
                val["-" + el.qry_nombre] = v.neg;
                val["*" + el.qry_nombre] = v.neu;
                sents.push(val);
              }
            });
          }
        });

        // Wait for all requests, and then setState
        Promise.all(resMap).then(() => {
          setData(sents);
          setKeyValBar(kvs);
        });
      } else if (fuente === "REDES") {
        var f1 =
          current.fi.split("-")[2] +
          "-" +
          current.fi.split("-")[1] +
          "-" +
          current.fi.split("-")[0];
        var f2 =
          current.ff.split("-")[2] +
          "-" +
          current.ff.split("-")[1] +
          "-" +
          current.ff.split("-")[0];

        var sentsTot = [];
        var kvsTot = [];
        var resMapTot = queries.map(async (el, i) => {
          // var found = queries.find((el) => el.qry_id === current.id);
          if (
            current.id.includes(-1) ||
            (!current.id.includes(-1) && current.id.includes(el.qry_id))
          ) {
            var qry = "";
            var qry_n = "";
            if (
              !el.twitter ||
              el.twitter === "" ||
              el.twitter === "null" ||
              el.twitter === null
            ) {
              if (el.query.includes(",")) {
                qry = '"' + el.query.split(",")[0] + '"';
                qry_n = el.query.split(",")[0];
              } else if (el.query.includes(".")) {
                qry = '"' + el.query.split(".")[0] + '"';
                qry_n = el.query.split(".")[0];
              } else if (el.query.includes("!")) {
                qry = '"' + el.query.split(".!")[0] + '"';
                qry_n = el.query.split(".!")[0];
              } else {
                qry = '"' + el.query + '"';
                qry_n = el.query;
              }
            } else {
              // qry = el.twitter;
              qry = `to:${el.twitter} OR from:${el.twitter}`;
              qry_n = el.twitter;
            }

            var res = await getSentRedes(qry, f1, f2);

            kvsTot.push({ ["+" + qry_n]: b[i] + "|" + el.qry_id });
            kvsTot.push({ ["-" + qry_n]: r[i] + "|" + el.qry_id });
            kvsTot.push({ ["*" + qry_n]: y[i] + "|" + el.qry_id });

            var rData = res.linea;
            if (
              rData !== null &&
              rData !== undefined &&
              rData !== "undefined"
            ) {
              Object.entries(rData).forEach(([k, v]) => {
                Object.entries(v).forEach(([kv, vv]) => {
                  var f = sentsTot.find((e) => e.name === kv.split("T")[0]);
                  if (f) {
                    if (k === "positivos") f["+" + qry_n] = vv;
                    if (k === "negativos") f["-" + qry_n] = vv;
                    if (k === "neutros") f["*" + qry_n] = vv;
                  } else {
                    var val = {};
                    val.name = kv.split("T")[0];
                    if (k === "positivos") val["+" + qry_n] = vv;
                    if (k === "negativos") val["-" + qry_n] = vv;
                    if (k === "neutros") val["*" + qry_n] = vv;
                    sentsTot.push(val);
                  }
                });
              });
            }
          }
        });
        // Wait for all requests, and then setState
        Promise.all(resMapTot).then(() => {
          setData(sentsTot);
          setKeyValBar(kvsTot);
        });
      }
    }, [current, queries, fuente]);

    let legendRender = function (value) {
      return <span style={{ fontSize: "0.7rem" }}>{value}</span>;
    };

    return (
      <VisContainer
        title={
          fuente === "MEDIOS" ? "Valoración de Notas" : "Sentimiento de Tweets"
        }
        desc={
          fuente === "MEDIOS"
            ? "Valoración de notas por día."
            : "Sentimiento de tweets por día."
        }
        time={data ? true : false}
        downloadChart={downloadChart}
      >
        {!data && <CygniLoadingFix />}
        {data && !current.id.includes(-1) && current.id.length === 1 && (
          <CygniLineChart
            data={data}
            keysAndColors={keyValBar}
            //   chartClick={chartClick}
            exportRef={ref}
          />
        )}
        {data && (current.id.includes(-1) || current.id.length > 1) && (
          <CygniBarChart
            data={data}
            stack={true}
            keysAndColors={keyValBar}
            legendRender={legendRender}
            iconSize={11}
            exportRef={ref}
          />
        )}
      </VisContainer>
    );
  }
);
