import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  // Button,
  Card,
  // CardActions,
  CardContent,
  // Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { UserContext } from "../contexts/user-context";

// const user = {
//   avatar: "",
//   city: "Naucalpan",
//   country: "EDOMEX",
//   jobTitle: "Candidato",
//   name: "José Pérez López",
//   timezone: "GTM-7",
// };

const useStyles = makeStyles(() => ({
  root: {
    width: "300px",
  },
  avatar: {
    height: 80,
    width: 80,
  },
}));

export const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  // userContext
  const userContext = useContext(UserContext);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
            align="center"
          >
            {userContext.nombre}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {"Usuario: " + userContext.usuario}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {"Email: " + userContext.email}
          </Typography>
        </Box>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Actualizar imagen
        </Button>
      </CardActions> */}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};
