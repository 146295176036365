import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@reach/router";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const Utlis = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography variant="h3" component="h3" align="center" color="primary">
        Utilerías
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Descarga de Bases
              </Typography>
              <Typography variant="body2">
                Use esta herramienta para descargar bases de medios
                tradicionales en formato .csv a gran velocidad.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} to="/descarga_bases">
                Ver más
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Indicadores de Seguridad
              </Typography>
              <Typography variant="body2">
                Use esta herramienta para consultar, graficar y descargar (en
                .csv) datos de seguridad del SESNSP.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} to="/ind_seguridad">
                Ver más
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Indicadores de Pobreza
              </Typography>
              <Typography variant="body2">
                Use esta herramienta para consultar, graficar y descargar (en
                .csv) datos de pobreza de CONEVAL.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} to="/ind_pobreza">
                Ver más
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Censo 2020
              </Typography>
              <Typography variant="body2">
                Use esta herramienta para consultar, graficar y descargar (en
                .csv) datos del Censo 2020.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} to="/ind_censo">
                Ver más
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
