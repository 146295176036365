import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Modal, Typography, Link } from "@material-ui/core";
import {
  getTipoMedioClaveIntelite,
  numberWithCommas,
  queryPrep,
} from "../../common/Cygni";
import { CygniModalList } from "../../common/CygniModal";
import { getGraficas } from "../../common/CygniRequests";
import { CygniLoading } from "../../common/CygniLoading";

export const MediosTipos = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [filtro, setFiltro] = useState("null");
  // const [selectedData, setSelectedData] = useState(null);
  const qry = queryPrep(query);
  const f1 = fecha1 + " 00:00:00.0";
  const f2 = fecha2 + " 23:59:59.0";
  const f1Buscar = fecha1.replace(/-/g, "_");
  const f2Buscar = fecha2.replace(/-/g, "_");

  useEffect(() => {
    setData(null);

    // Gráficas
    getGraficas(qry, f1, f2, "cygnimoviltipomedio")
      .then(function (response) {
        setData(response);
      })
      .catch(function (error) {
        setData([]);
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  const chartClick = (chartData) => {
    if (chartData) {
      handleOpen(chartData);
    }
  };

  const handleOpen = async (selectedData) => {
    setOpen(true);
    setFiltro(selectedData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // En el exportData, se agrega como [] porque la libreria espera un []
  return (
    <React.Fragment>
      <PaperContainer
        title="Tipos de Medios"
        exportData={[data]}
        exportName="medios_tipo"
      >
        {!data && <CygniLoading />}
        {data && data.electronico === 0 && data.impreso === 0 && (
          <Typography
            component="h5"
            variant="h5"
            align="center"
            style={{ marginTop: "10px" }}
          >
            No hay datos disponibles.
          </Typography>
        )}
        {data && (data.electronico !== 0 || data.impreso !== 0) && (
          <React.Fragment>
            <Typography
              component="h4"
              variant="h3"
              color="primary"
              align="center"
            >
              <Link
                href="#"
                onClick={() => chartClick(getTipoMedioClaveIntelite("impreso"))}
              >
                {data.impreso && numberWithCommas(data.impreso)}
              </Link>
            </Typography>
            <Typography component="h5" variant="h5" align="center" gutterBottom>
              Impresos
            </Typography>
            <Typography
              component="h4"
              variant="h3"
              color="primary"
              align="center"
            >
              <Link
                href="#"
                onClick={() =>
                  chartClick(getTipoMedioClaveIntelite("electronico"))
                }
              >
                {data.electronico && numberWithCommas(data.electronico)}
              </Link>
            </Typography>
            <Typography component="h5" variant="h5" align="center">
              Electrónicos
            </Typography>
          </React.Fragment>
        )}
      </PaperContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CygniModalList
            qry={qry}
            f1={f1Buscar}
            f2={f2Buscar}
            title="Detalle de notas"
            loadMore={true}
            tipomedio={filtro}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
});
