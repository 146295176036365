import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Typography } from "@material-ui/core";
import { CygniSimpleList } from "../../common/CygniList";
import { CygniLoading } from "../../common/CygniLoading";
import { getInfluenRedes } from "../../common/CygniRequests";

function getTwitterAccount(query) {
  var account = "";
  switch (query.toLowerCase()) {
    case "amlo":
    case "peje":
    case "presidente":
    case "lopez obrador":
      account = "to:lopezobrador_ OR from:lopezobrador_";
      break;
    case "calderon":
    case "felipe calderon":
      account = "to:FelipeCalderon OR from:FelipeCalderon";
      break;
    case "margarita zavala":
      account = "to:Mzavalagc OR from:Mzavalagc";
      break;
    case "monreal":
    case "ricardo monreal":
      account = "to:RicardoMoneralA OR from:RicardoMoneralA";
      break;
    default:
      if (query.includes(",")) {
        account = '"' + query.split(",")[0] + '"';
      } else if (query.includes(".")) {
        account = '"' + query.split(".")[0] + '"';
      } else if (query.includes("!")) {
        account = '"' + query.split(".!")[0] + '"';
      } else {
        account = '"' + query + '"';
      }
      break;
  }
  return account;
}

export const RedesTopInfluencers = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);

    var qry = getTwitterAccount(query);
    var f1 =
      fecha1.split("-")[2] +
      "-" +
      fecha1.split("-")[1] +
      "-" +
      fecha1.split("-")[0];
    var f2 =
      fecha2.split("-")[2] +
      "-" +
      fecha2.split("-")[1] +
      "-" +
      fecha2.split("-")[0];

    getInfluenRedes(qry, f1, f2)
      .then(function (res) {
        var inf = [];
        var rData = res;
        if (rData !== null && rData !== undefined && rData !== "undefined") {
          Object.entries(rData).forEach(([k, v]) => {
            inf.push({ name: k, val: v });
          });
        }

        setData(inf);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  return (
    <PaperContainer
      title="Top Influencers"
      exportData={data}
      exportName="redes_top_influencers"
    >
      {!data && <CygniLoading />}
      {data && data.length === 0 && (
        <Typography
          component="h5"
          variant="h5"
          align="center"
          style={{ marginTop: "10px" }}
        >
          No hay datos disponibles.
        </Typography>
      )}
      {data && data.length > 0 && (
        <CygniSimpleList data={data} secondarySufix="" />
      )}
    </PaperContainer>
  );
});
