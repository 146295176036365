import React, { useState } from "react";
import { Container, Typography, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Profile } from "./Profile";
import { ConfQueries } from "./ConfQueries";
import { ConfTableros } from "./ConfTableros";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeighSmall: {
    height: 80,
  },
  fixedHeightNormal: {
    height: 340,
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightBold,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  pos: {
    marginBottom: 12,
  },
}));

export const Config = () => {
  // const theme = useTheme();
  const classes = useStyles();
  const [conf, setConf] = useState(0);

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        component="h3"
        align="center"
        color="primary"
        gutterBottom
      >
        Configuración
      </Typography>
      <Profile />
      <ButtonGroup
        style={{ marginTop: "1rem" }}
        variant="outlined"
        color="primary"
        aria-label="text primary button group"
        fullWidth
      >
        <Button onClick={() => setConf(1)} variant={conf === 1 && "contained"}>
          Queries
        </Button>
        <Button onClick={() => setConf(2)} variant={conf === 2 && "contained"}>
          Tableros
        </Button>
        {/* <Button onClick={() => setConf(3)} variant={conf === 3 && "contained"}>
          Públicos
        </Button> */}
      </ButtonGroup>
      {conf === 1 && <ConfQueries paperStyle={classes.paper} />}
      {conf === 2 && <ConfTableros paperStyle={classes.paper} />}
      {/* {conf === 3 && <ConfPublicos paperStyle={classes.paper} />} */}
    </Container>
  );
};
