import {
  AppBar,
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getFuncionarios } from "../common/CygniRequests";

const data = [
  { name: "Positiva", value: 400 },
  { name: "Negativa", value: 300 },
  { name: "Neutra", value: 300 },
];

const dataB = [
  { name: "Agua", value: 100 },
  { name: "Conflictos", value: 300 },
  { name: "Economía", value: 400 },
  { name: "Infraestructura", value: 800 },
  { name: "Social", value: 30 },
  { name: "Seguridad", value: 420 },
];

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#f54287",
  "#a13706",
];

const RADIAN = Math.PI / 180;

const data01 = [
  { hour: "187 a 1", index: 1, value: 0 },
  { hour: "113 a 1", index: 1, value: 0 },
  { hour: "0", index: 1, value: 0 },
  { hour: "67 a 1", index: 1, value: 0 },
  { hour: "23 a 1", index: 1, value: 0 },
  { hour: "31 a 1", index: 1, value: 0 },
  { hour: "7 a 1", index: 1, value: 0 },
  { hour: "3 a 1", index: 1, value: 423 },
];

const data02 = [
  { hour: "187 a 1", index: 1, value: 0 },
  { hour: "113 a 1", index: 1, value: 2265 },
  { hour: "0", index: 1, value: 0 },
  { hour: "67 a 1", index: 1, value: 2103 },
  { hour: "23 a 1", index: 1, value: 0 },
  { hour: "31 a 1", index: 1, value: 582 },
  { hour: "7 a 1", index: 1, value: 170 },
  { hour: "3 a 1", index: 1, value: 0 },
];

const data03 = [
  { hour: "187 a 1", index: 1, value: 373 },
  { hour: "113 a 1", index: 1, value: 0 },
  { hour: "0", index: 1, value: 0 },
  { hour: "67 a 1", index: 1, value: 0 },
  { hour: "23 a 1", index: 1, value: 0 },
  { hour: "31 a 1", index: 1, value: 0 },
  { hour: "7 a 1", index: 1, value: 0 },
  { hour: "3 a 1", index: 1, value: 0 },
];

const data04 = [
  { hour: "187 a 1", index: 1, value: 0 },
  { hour: "113 a 1", index: 1, value: 0 },
  { hour: "0", index: 1, value: 1153 },
  { hour: "67 a 1", index: 1, value: 0 },
  { hour: "23 a 1", index: 1, value: 208 },
  { hour: "31 a 1", index: 1, value: 0 },
  { hour: "7 a 1", index: 1, value: 0 },
  { hour: "3 a 1", index: 1, value: 0 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeighSmall: {
    height: 80,
  },
  fixedHeightNormal: {
    height: 280,
  },
  boldText: {
    fontWeight: "bold",
  },
  modalPaper: {
    position: "absolute",
    width: "55%",
    // height: "55%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
}));

const parseDomain = () => [
  0,
  Math.max(
    Math.max.apply(
      null,
      data01.map((entry) => entry.value)
    ),
    Math.max.apply(
      null,
      data02.map((entry) => entry.value)
    ),
    Math.max.apply(
      null,
      data03.map((entry) => entry.value)
    ),
    Math.max.apply(
      null,
      data04.map((entry) => entry.value)
    )
  ),
];

export const Analisis = () => {
  const classes = useStyles();
  const [funcionarios, setFuncionarios] = useState(null);
  const [selFunc, setSelFunc] = useState("");
  const [barValue, setBarValue] = React.useState(0);

  useEffect(() => {
    getFuncionarios()
      .then(function (response) {
        console.log(response);
        setFuncionarios(response);
      })
      .catch(function (error) {
        console.error(error);
        setFuncionarios(null);
      });
  }, []);

  const renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            padding: 10,
          }}
        >
          <p>{data.hour}</p>
          <p>
            <span>value: </span>
            {data.value}
          </p>
        </div>
      );
    }
  };

  const domain = parseDomain();
  const range = [0, 2500];

  const onChangeFunc = (e) => {
    if (funcionarios) {
      setSelFunc(funcionarios.find((f) => f.id === e.target.value));
    }
  };

  const handleChangeAppBar = (event, newValue) => {
    setBarValue(newValue);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3" component="h3" align="center">
        Análisis - Demanda Ciudadana/Respuesta Institucional
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
            size="small"
          >
            <InputLabel id="func_select_label">Funcionario</InputLabel>
            <Select
              labelId="func_select_label"
              id="func_select"
              name="func_select"
              value={selFunc ? selFunc.id : ""}
              onChange={onChangeFunc}
              label="Funcionario"
            >
              <MenuItem value="">
                <em>Seleccione un funcionario</em>
              </MenuItem>
              {funcionarios &&
                funcionarios.map((func, i) => {
                  return (
                    <MenuItem key={i} value={func.id}>
                      {func.nombre}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {selFunc && (
            <React.Fragment>
              <Typography variant="h6" align="center" color="primary">
                {selFunc.nombre}
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                gutterBottom
              >
                {selFunc.cargo}
              </Typography>
              <Divider />
              <Typography variant="subtitle2" align="center" gutterBottom>
                Alias: <em>{selFunc.alias}</em>
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                Twitter: <em>{selFunc.twitter}</em>
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                Estado: <em>{selFunc.estado}</em>
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                Municipio: <em>{selFunc.municipio}</em>
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                Local/Federal: <em>{selFunc.local_federal}</em>
              </Typography>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {selFunc && (
            <React.Fragment>
              <AppBar position="static" color="default">
                <Tabs
                  value={barValue}
                  onChange={handleChangeAppBar}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Índice Proyección Mediática" {...a11yProps(0)} />
                  <Tab label="Análisis Mediático" {...a11yProps(1)} />
                  <Tab label="Proyección Mediática" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={barValue} index={0}>
                <div style={{ height: "15vh" }}>
                  <ResponsiveContainer>
                    <ScatterChart
                      margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
                    >
                      <XAxis
                        type="category"
                        dataKey="hour"
                        name="hour"
                        interval={0}
                        tick={{ fontSize: 0 }}
                        tickLine={{ transform: "translate(0, -6)" }}
                      />
                      <YAxis
                        type="number"
                        dataKey="index"
                        height={10}
                        width={80}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        label={{ value: "Proactivo", position: "insideRight" }}
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        domain={domain}
                        range={range}
                      />
                      <Tooltip
                        cursor={{ strokeDasharray: "3 3" }}
                        wrapperStyle={{ zIndex: 100 }}
                        content={renderTooltip}
                      />
                      <Scatter data={data01} fill="#db4035" />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>

                <div style={{ height: "15vh" }}>
                  <ResponsiveContainer>
                    <ScatterChart
                      margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
                    >
                      <XAxis
                        type="category"
                        dataKey="hour"
                        name="hour"
                        interval={0}
                        tick={{ fontSize: 0 }}
                        tickLine={{ transform: "translate(0, -6)" }}
                      />
                      <YAxis
                        type="number"
                        dataKey="index"
                        height={10}
                        width={80}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        label={{ value: "Inercial", position: "insideRight" }}
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        domain={domain}
                        range={range}
                      />
                      <Tooltip
                        cursor={{ strokeDasharray: "3 3" }}
                        wrapperStyle={{ zIndex: 100 }}
                        content={renderTooltip}
                      />
                      <Scatter data={data02} fill="#db4035" />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>

                <div style={{ height: "15vh" }}>
                  <ResponsiveContainer>
                    <ScatterChart
                      margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
                    >
                      <XAxis
                        type="category"
                        dataKey="hour"
                        name="hour"
                        interval={0}
                        tick={{ fontSize: 0 }}
                        tickLine={{ transform: "translate(0, -6)" }}
                      />
                      <YAxis
                        type="number"
                        dataKey="index"
                        height={10}
                        width={80}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        label={{ value: "Reactivo", position: "insideRight" }}
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        domain={domain}
                        range={range}
                      />
                      <Tooltip
                        cursor={{ strokeDasharray: "3 3" }}
                        wrapperStyle={{ zIndex: 100 }}
                        content={renderTooltip}
                      />
                      <Scatter data={data03} fill="#db4035" />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>

                <div style={{ height: "15vh" }}>
                  <ResponsiveContainer>
                    <ScatterChart
                      margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
                    >
                      <XAxis
                        type="category"
                        dataKey="hour"
                        name="hour"
                        interval={0}
                        tickLine={{ transform: "translate(0, -6)" }}
                      />
                      <YAxis
                        type="number"
                        dataKey="index"
                        height={10}
                        width={80}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        label={{ value: "Limitado", position: "insideRight" }}
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        domain={domain}
                        range={range}
                      />
                      <Tooltip
                        cursor={{ strokeDasharray: "3 3" }}
                        wrapperStyle={{ zIndex: 100 }}
                        content={renderTooltip}
                      />
                      <Scatter data={data04} fill="#db4035" />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>
              </TabPanel>
              <TabPanel value={barValue} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} style={{ height: "45vh" }}>
                    <Typography>Distribución General por Medio</Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Internet</TableCell>
                            <TableCell>Periódicos</TableCell>
                            <TableCell>Radio</TableCell>
                            <TableCell>Televisión</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>30</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>60</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          data={data}
                          // cx={420}
                          cy={200}
                          startAngle={180}
                          endAngle={0}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          label
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={index}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Universo del análisis: 60 menciones</Typography>
                    <Typography variant="body1" align="justify">
                      La gráfica de hoy es de nuestro fotógrafo quien pudo
                      presenciar al secretario del Ayuntamiento del municipio ,
                      anunciar que una delegación de elementos policiacos
                      acudirán a Fort Worth, en el estado de Texas. Una acción
                      sin duda importante, ya que ante la creciente ola de
                      inseguridad que se ha experimentado en todo el país, es
                      necesario que nuestros elementos de seguridad cuenten con
                      una mejor preparación. El Valle Estoy gratamente
                      sorprendido, por primera vez respetaron nuestros acuerdos
                      que vienen desde que la alcaldesa era candidata a la
                      presidencia municipal. El secretario del Ayuntamiento fue
                      quien nos permitió retomar la venta y en 20 días
                      sostendremos una nueva reunión para saber dónde serán
                      reubicados los otros locatarios retirados de la Alameda,
                      por las obras que se llevan a cabo. líder de la
                      Organización Del Paso
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={barValue} index={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} style={{ height: "45vh" }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          data={dataB}
                          // cx={420}
                          cy={200}
                          startAngle={180}
                          endAngle={0}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          label
                        >
                          {dataB.map((entry, index) => (
                            <Cell
                              key={index}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ul>
                      <li>Incremento de inseguridad en diversas colonias.</li>
                      <li>
                        Incremento de inseguridad en zonas específicas como la
                        Central de Abastos, el Aeropuerto Internacional y la
                        Terminal.
                      </li>
                      <li>
                        Incremento de ejecuciones y delitos graves como
                        extorsiones, secuestros, narcotráfico y balaceras.
                      </li>
                      <li>Mando Único Coordinado.</li>
                      <li>
                        Aumento de delitos del fuero común –robo principalmente-
                        en comercios.
                      </li>
                      <li>Respuesta oportuna de los cuerpos de seguridad.</li>
                      <li>Protestas contra la inseguridad.</li>
                    </ul>
                  </Grid>
                </Grid>
              </TabPanel>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" align="justify">
            Vinculación Limitada (-10 a -5): Mantiene un bajo perfil de
            posicionamiento y/o la tendencia de la información es
            mayoritariamente negativa.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" align="justify">
            Reactivo (-5 a 0): Presenta una proyección mediática de corto plazo,
            que atiende a un movimiento de su agenda no propiciado por él y
            frente al que genera un nivel de exposición que no trasciende.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" align="justify">
            Inercial (0 a 5): Reacciona a la tendencia de los medios, pero no se
            percibe como un actor con una agenda propia ni construye coyunturas
            que se recuperen en la opinión pública.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" align="justify">
            Proactivo (5 a 10): Posee una agenda propia y marca el ritmo del
            movimiento de los medios. Se vincula discursivamente con los
            objetivos de gobierno y desarrolla proyectos propios que generan
            contenido informativo.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
