export function queryPrep(query) {
  return query
    .trim()
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n");
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getFormattedDate(date, sep = "/", format = "ymd") {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  var res = [];

  for (let part of format.split("")) {
    if (part === "y") {
      res.push(year);
    } else if (part === "m") {
      res.push(month);
    } else {
      res.push(day);
    }
  }

  return res.join(sep);
}

export function getDatesDiffInDays(fecha1, fecha2) {
  const diffTime = Math.abs(new Date(fecha2) - new Date(fecha1));
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function getInterval_dwmy(diffDays) {
  var interval = "d";

  if (diffDays > 35 && diffDays < 160) {
    interval = "w";
  } else if (diffDays >= 160 && diffDays < 1461) {
    interval = "m";
  } else if (diffDays >= 1461) {
    interval = "y";
  }

  return interval;
}

export function getEdoAbrev(edo) {
  var abrev = "";
  switch (edo) {
    case "CDMX":
    case "Distrito Federal":
    case "DF":
    case "Ciudad de México":
      abrev = "CDMX";
      break;
    case "Aguascalientes":
      abrev = "AGS";
      break;
    case "Baja California Norte":
    case "Baja California":
      abrev = "BC";
      break;
    case "Baja California Sur":
      abrev = "BCS";
      break;
    case "Campeche":
      abrev = "CAM";
      break;
    case "Chiapas":
      abrev = "CHI";
      break;
    case "Chihuahua":
      abrev = "CHIH";
      break;
    case "Coahuila":
      abrev = "COAH";
      break;
    case "Colima":
      abrev = "COL";
      break;
    case "Durango":
      abrev = "DUR";
      break;
    case "EDOMEX":
    case "Estado de México":
      abrev = "MEX";
      break;
    case "Guanajuato":
      abrev = "GTO";
      break;
    case "Guerrero":
      abrev = "GRO";
      break;
    case "Hidalgo":
      abrev = "HID";
      break;
    case "Jalisco":
      abrev = "JAL";
      break;
    case "Michoacán":
      abrev = "MICH";
      break;
    case "Morelos":
      abrev = "MOR";
      break;
    case "Nayarit":
      abrev = "NAY";
      break;
    case "Nuevo León":
      abrev = "NL";
      break;
    case "Oaxaca":
      abrev = "OAX";
      break;
    case "Puebla":
      abrev = "PUE";
      break;
    case "Querétaro":
      abrev = "QUE";
      break;
    case "Quintana Roo":
      abrev = "QROO";
      break;
    case "San Luis Potosí":
      abrev = "SLP";
      break;
    case "Sinaloa":
      abrev = "SIN";
      break;
    case "Sonora":
      abrev = "SON";
      break;
    case "Tabasco":
      abrev = "TAB";
      break;
    case "Tamaulipas":
      abrev = "TAM";
      break;
    case "Tlaxcala":
      abrev = "TLAX";
      break;
    case "Veracruz":
      abrev = "VER";
      break;
    case "Yucatán":
      abrev = "YUC";
      break;
    case "Zacatecas":
      abrev = "ZAC";
      break;
    case "Nacional":
    case "NACIONAL":
      abrev = "NAC";
      break;
    case "EXTRANJERO":
    case "Extranjero":
      abrev = "EXT";
      break;
    default:
      abrev = "";
      break;
  }
  return abrev;
}

export function getEdoClaveIntelite(edo) {
  var clave = "";

  switch (edo) {
    case "CDMX":
      clave = "9";
      break;
    case "AGS":
      clave = "13";
      break;
    case "BC":
      clave = "18";
      break;
    case "BCS":
      clave = "19";
      break;
    case "CAM":
      clave = "20";
      break;
    case "CHI":
      clave = "23";
      break;
    case "CHIH":
      clave = "24";
      break;
    case "COAH":
      clave = "21";
      break;
    case "COL":
      clave = "22";
      break;
    case "DUR":
      clave = "25";
      break;
    case "MEX":
      clave = "12";
      break;
    case "GTO":
      clave = "26";
      break;
    case "GRO":
      clave = "27";
      break;
    case "HID":
      clave = "28";
      break;
    case "JAL":
      clave = "17";
      break;
    case "MICH":
      clave = "16";
      break;
    case "MOR":
      clave = "29";
      break;
    case "NAY":
      clave = "30";
      break;
    case "NL":
      clave = "31";
      break;
    case "OAX":
      clave = "32";
      break;
    case "PUE":
      clave = "33";
      break;
    case "QUE":
      clave = "34";
      break;
    case "QROO":
      clave = "35";
      break;
    case "SLP":
      clave = "36";
      break;
    case "SIN":
      clave = "15";
      break;
    case "SON":
      clave = "37";
      break;
    case "TAB":
      clave = "38";
      break;
    case "TAM":
      clave = "39";
      break;
    case "TLAX":
      clave = "40";
      break;
    case "VER":
      clave = "41";
      break;
    case "YUC":
      clave = "42";
      break;
    case "ZAC":
      clave = "14";
      break;
    default:
      clave = "";
      break;
  }

  return clave;
}

export function getTipoMedioClaveIntelite(tipoMedio) {
  var clave = "";

  if (tipoMedio === "impreso") {
    clave = "1";
  } else {
    clave = "2";
  }
  return clave;
}

export function getAmbitoClaveIntelite(tipoMedio) {
  var clave = "";

  if (tipoMedio === "nacional") {
    clave = "1";
  } else if (tipoMedio === "estatal") {
    clave = "2";
  } else {
    clave = "3";
  }
  return clave;
}

export function getSentimientoIntelite(sentimiento) {
  var clave = "";

  if (sentimiento === "pos") {
    clave = "1";
  } else if (sentimiento === "neg") {
    clave = "2";
  } else {
    clave = "3";
  }

  return clave;
}

export function getTendenciaClaveDysorda(tendencia) {
  var clave = "";

  if (tendencia === "Positivos") {
    clave = "P";
  } else if (tendencia === "Negativos") {
    clave = "N";
  } else {
    clave = "Neu";
  }
  return clave;
}

export function getGeneroClaveIntelite(genero) {
  var clave = "";

  switch (genero) {
    case "Clima":
      clave = "23304";
      break;
    case "Primeras Planas (Sólo imagen)":
      clave = "7893";
      break;
    case "Información":
      clave = "1";
      break;
    case "Comentarios Políticos":
      clave = "6311";
      break;
    case "Resumen":
      clave = "7604";
      break;
    case "Esquelas":
      clave = "8086";
      break;
    case "Deportes":
      clave = "9814";
      break;
    case "Convocatorias":
      clave = "8719";
      break;
    case "Opinión":
      clave = "2";
      break;
    case "Edicto":
      clave = "23284";
      break;
    case "Programa Completo":
      clave = "38330";
      break;
    case "Entrevista":
      clave = "3";
      break;
    case "Portada Revista":
      clave = "13";
      break;
    case "Comentarios Económicos":
      clave = "6312";
      break;
    case "Publicidad":
      clave = "6307";
      break;
    case "Reportaje":
      clave = "4";
      break;
    case "Teaser":
      clave = "23324";
      break;
    case "Encuesta":
      clave = "8305";
      break;
    case "Espectáculos":
      clave = "23305";
      break;
    case "Comentario":
      clave = "5";
      break;
    case "Desplegados":
      clave = "12";
      break;
    case "Pie de Foto":
      clave = "8306";
      break;
    case "Mesa de Debates":
      clave = "6";
      break;
    case "Columnas Políticas":
      clave = "6309";
      break;
    case "PP Cultura":
      clave = "53974";
      break;
    case "Licitaciones":
      clave = "9707";
      break;
    case "Carteleras":
      clave = "6308";
      break;
    case "Crónica":
      clave = "7";
      break;
    case "PP Negocios":
      clave = "43612";
      break;
    case "Actas.":
      clave = "7643";
      break;
    case "PP Deportivas":
      clave = "54020";
      break;
    case "Columnas Deportivas":
      clave = "43492";
      break;
    case "Correspondencia":
      clave = "11";
      break;
    case "Ensayo":
      clave = "8";
      break;
    case "Epigrama":
      clave = "8304";
      break;
    case "Contraportada":
      clave = "52563";
      break;
    case "Ocho Columnas":
      clave = "6305";
      break;
    case "Cartones":
      clave = "9";
      break;
    case "Internacional":
      clave = "23306";
      break;
    case "Acuerdos.":
      clave = "7644";
      break;
    case "Encabezados ocho cols":
      clave = "7963";
      break;
    case "PP Ciudad":
      clave = "43072";
      break;
    case "Columnas Económicas":
      clave = "6310";
      break;
    case "Publirreportaje":
      clave = "8303";
      break;
    case "Fotografías":
      clave = "10";
      break;
    case "Editorial":
      clave = "9371";
      break;
    case "PP Espectáculos":
      clave = "54021";
      break;
    case "Informes":
      clave = "7646";
      break;
    case "Iniciativas":
      clave = "7645";
      break;
    case "Información/ Comentario":
      clave = "7612";
      break;
    case "Las Breves":
      clave = "11074";
      break;
    default:
      clave = "null";
      break;
  }

  return clave;
}

export function getPorTipoMedioClaveIntelite(tipo) {
  var clave = "";

  switch (tipo) {
    case "Radio":
      clave = "3";
      break;
    case "Cadenas":
      clave = "6";
      break;
    case "SIL":
      clave = "18";
      break;
    case "Podcast":
      clave = "16";
      break;
    case "Boletín":
      clave = "17";
      break;
    case "Agencias":
      clave = "19";
      break;
    case "Twitter Líderes de Opinión":
      clave = "60";
      break;
    case "Periódicos":
      clave = "1";
      break;
    case "Televisión":
      clave = "4";
      break;
    case "Diario Oficial de la Federación":
      clave = "20";
      break;
    case "Redes Sociales":
      clave = "99";
      break;
    case "Revistas":
      clave = "2";
      break;
    case "Agencias Informativas":
      clave = "5";
      break;
    case "Gacetas":
      clave = "12";
      break;
    case "Internet":
      clave = "8";
      break;
    case "Videos Web":
      clave = "39";
      break;
    case "Boletín de Prensa":
      clave = "79";
      break;
    case "Twitter Políticos y Funcionarios":
      clave = "59";
      break;
    default:
      clave = "null";
      break;
  }
  return clave;
}
