import React, { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Box,
} from "@material-ui/core";
import { CygniLoading } from "../common/CygniLoading";
import { ExportCSV } from "../common/ExportCSV";
import { numberWithCommas } from "../common/Cygni";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  // fixedHeighSmall: {
  //   height: 120,
  // },
  fixedHeightNormal: {},
  seeMore: {
    marginTop: theme.spacing(3),
  },
  boldText: {
    fontWeight: "bold",
  },
  anexoText: {
    fontSize: "small",
    fontStyle: "italic",
  },
}));

export const Resultados = ({ estado, tipo, anio, nivel }) => {
  const classes = useStyles();
  const fixedHeightPaperNormal = clsx(classes.paper, classes.fixedHeightNormal);
  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    // Cargar resultados electorales según los parámetros
    getResults();
  }, [estado, tipo, nivel, anio]);

  const getResults = () => {
    if (estado && tipo && anio && nivel) {
      setResultados(null);
      if (estado !== "" && tipo !== "" && anio !== "" && nivel !== "") {
        axios
          .get(
            "/api/electoral/fuerzas/" +
              estado +
              "/" +
              tipo +
              "/" +
              anio +
              "/" +
              nivel
          )
          .then(function (response) {
            setResultados(response.data);
          })
          .catch(function (error) {
            console.error(error);
            setResultados({});
          });
      }
    }
  };

  return (
    <Paper className={fixedHeightPaperNormal} elevation={2}>
      <Grid container>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Resultados Electorales
        </Typography>
        {resultados && resultados.data && (
          <ExportCSV
            csvData={resultados.data}
            fileName={estado + "_" + tipo + "_" + anio + "_" + nivel}
          />
        )}
      </Grid>
      {resultados && resultados.cols && (
        <Box style={{ margin: "auto", marginTop: "3px" }}>
          <Chip
            size="small"
            style={{
              backgroundColor: "#91d2fa",
              fontSize: "11px",
            }}
            label="% de Primera Fuerza"
          />
          <Chip
            size="small"
            style={{
              backgroundColor: "#c0f7a6",
              fontSize: "11px",
              marginLeft: "3px",
              marginRight: "3px",
            }}
            label="% de Segunda Fuerza"
          />
          <Chip
            size="small"
            style={{
              fontSize: "11px",
            }}
            label="% de Tercera Fuerza"
          />
        </Box>
      )}
      {!resultados && <CygniLoading />}
      {resultados && (
        <Table size="small">
          <TableHead>
            <TableRow>
              {resultados && resultados.cols && (
                <React.Fragment>
                  {resultados.cols.split(",").map((cell) => (
                    <TableCell key={cell} className={classes.boldText}>
                      {cell}
                    </TableCell>
                  ))}
                  {resultados.anexo.split(",").map((cell) => (
                    <TableCell key={cell} className={classes.boldText}>
                      {cell}
                    </TableCell>
                  ))}
                  <TableCell
                    key="1F"
                    align="right"
                    className={classes.boldText}
                  >
                    1ra Fuerza
                  </TableCell>
                  <TableCell
                    key="2F"
                    align="right"
                    className={classes.boldText}
                  >
                    2da Fuerza
                  </TableCell>
                  <TableCell
                    key="3F"
                    align="right"
                    className={classes.boldText}
                  >
                    3ra Fuerza
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {resultados &&
              resultados.data &&
              resultados.data.map((cell, i) => (
                <TableRow key={i}>
                  {resultados.cols.split(",").map((cd, j) => (
                    <TableCell key={j}>{cell[cd]}</TableCell>
                  ))}
                  {resultados.anexo.split(",").map((cd, j) => (
                    <TableCell key={j} className={classes.anexoText}>
                      {numberWithCommas(cell[cd])}
                    </TableCell>
                  ))}
                  <TableCell align="right" key={i + "-" + cell["PF"]}>
                    {cell["PF"]}{" "}
                    <Chip
                      size="small"
                      style={{
                        backgroundColor: "#91d2fa",
                        fontSize: "11px",
                      }}
                      label={cell["PF_P"] + "%"}
                    />
                  </TableCell>
                  <TableCell align="right" key={i + "-" + cell["SF"]}>
                    {cell["SF"]}{" "}
                    <Chip
                      size="small"
                      style={{
                        backgroundColor: "#c0f7a6",
                        fontSize: "11px",
                      }}
                      label={cell["SF_P"] + "%"}
                    />
                  </TableCell>
                  <TableCell align="right" key={i + "-" + cell["TF"]}>
                    {cell["TF"]}{" "}
                    <Chip
                      size="small"
                      style={{
                        fontSize: "11px",
                      }}
                      label={cell["TF_P"] + "%"}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};
