import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

export const CygniBarChart = React.memo(
  ({
    data,
    keysAndColors,
    dataKey = "name",
    chartClick = null,
    stack = false,
    legend = true,
    legendRender = null,
    iconSize = 12,
    exportRef = null,
    barLabel = false,
  }) => {
    return (
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 12, right: 5, left: 0, bottom: 4 }}
          onClick={chartClick}
          ref={exportRef}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={dataKey}
            height={53}
            tick={{
              fontSize: 9,
              scaleToFit: true,
              textAnchor: "end",
              angle: -40,
            }}
          />
          <YAxis tick={{ fontSize: 9 }} />
          <Tooltip />
          {legend && (
            <Legend
              iconSize={iconSize}
              iconType="diamond"
              formatter={legendRender}
            />
          )}
          {keysAndColors.map((entry) => {
            const kvs = Object.entries(entry);
            if (stack) {
              return (
                <Bar
                  key={kvs[0][0]}
                  dataKey={kvs[0][0]}
                  fill={kvs[0][1].split("|")[0]}
                  stackId={kvs[0][1].split("|")[1]}
                />
              );
            } else {
              return (
                <Bar
                  key={kvs[0][0]}
                  dataKey={kvs[0][0]}
                  fill={kvs[0][1]}
                  label={barLabel}
                />
              );
            }
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
);
