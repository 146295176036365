import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Typography } from "@material-ui/core"; // Modal, Link, Box
import { numberWithCommas, queryPrep } from "../../common/Cygni";
import ReactTooltip from "react-tooltip";
// import { CygniModalList } from "../../common/CygniModal";
import { getMunsEnNotas } from "../../common/CygniRequests";
import { CygniLoading } from "../../common/CygniLoading";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
} from "react-simple-maps";

export const MediosMunsEnNotas = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [perc, setPerc] = useState(null);
  const [content, setContent] = useState("");
  //   const [open, setOpen] = useState(false);
  //   const [filtro, setFiltro] = useState("null");
  // const [selectedData, setSelectedData] = useState(null);
  const qry = queryPrep(query);
  const f1 = fecha1 + " 00:00:00.0";
  const f2 = fecha2 + " 23:59:59.0";

  useEffect(() => {
    setData(null);

    // Gráficas
    getMunsEnNotas(qry, f1, f2)
      .then(function (response) {
        var d = {};
        var tot = response.numeroNotas;
        // console.log(response); // response.numeroNotas
        if (response && response.categorias) {
          Object.entries(response.categorias).forEach((entry) => {
            d[entry[0]] = (entry[1].doc_count * 100) / tot;
          });
        }
        // console.log(d);
        setData(response);
        setPerc(d);
      })
      .catch(function (error) {
        setData([]);
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  //   const chartClick = (chartData) => {
  //     if (chartData) {
  //       handleOpen(chartData);
  //     }
  //   };

  //   const handleOpen = async (selectedData) => {
  //     setOpen(true);
  //     setFiltro(selectedData);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // En el exportData, se agrega como [] porque la libreria espera un []

  function getAlpha(pr) {
    if (!pr || pr === 0) return "#dedede";
    if (pr >= 0 && pr < 10) return "#d5dbed";
    if (pr >= 10 && pr < 20) return "#bdcaf0";
    if (pr >= 20 && pr < 30) return "#a0afde";
    if (pr >= 30 && pr < 40) return "#899cd6";
    if (pr >= 40 && pr < 50) return "#6f87d1";
    if (pr >= 50 && pr < 60) return "#4b71e3";
    if (pr >= 60 && pr < 70) return "#2f58d4";
    if (pr >= 70 && pr < 80) return "#1440c4";
    if (pr >= 80 && pr < 90) return "#062a94";
    if (pr >= 90 && pr <= 100) return "#032280";
  }

  return (
    <React.Fragment>
      <PaperContainer
        title="Municipios"
        exportData={[data]}
        exportName="muns_en_notas"
      >
        {!data && !perc && <CygniLoading />}
        {data && perc && data.numeroNotas === 0 && (
          <Typography align="center">No hay datos disponible.</Typography>
        )}
        {data && perc && data.numeroNotas > 0 && (
          <React.Fragment>
            <ComposableMap
              data-tip=""
              projection="geoAlbers"
              projectionConfig={{ scale: 1800, rotate: [101, 14, 0] }}
            >
              <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
              <Geographies geography={"/Entidades.json"}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    var entidad = geo.properties.NOM_ENT;
                    if (entidad === "Coahuila de Zaragoza")
                      entidad = "Coahuila";
                    if (entidad === "Michoacán de Ocampo")
                      entidad = "Michoacán";
                    if (entidad === "Veracruz de Ignacio de la Llave")
                      entidad = "Veracruz";
                    if (entidad === "México") entidad = "Estado de México";
                    // if(entidad === "Coahuila de Zaragoza") entidad = "Coahuila"
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={getAlpha(perc[entidad])}
                        onMouseEnter={() => {
                          var det = ``;
                          var curr = Object.entries(data.categorias).find(
                            (k) => k[0] === entidad
                          );
                          if (curr) {
                            det += ` - Notas: ${numberWithCommas(
                              curr[1].doc_count
                            )}<br/>`;
                            det += `<table>`;
                            var c = 0;
                            Object.entries(curr[1]).map((e, i) => {
                              if (e[0] !== "doc_count") {
                                if (c === 0) {
                                  det += `<tr>`;
                                }
                                det += `<td><em><small>${
                                  e[0]
                                }: ${numberWithCommas(e[1])}</small></em><td>`;
                                if (c === 5) {
                                  det += `</tr>`;
                                  c = 0;
                                } else {
                                  c++;
                                }
                              }
                            });
                            det += `</table>`;
                          }
                          setContent(`Estado: ${geo.properties.NOM_ENT}${det}`);
                        }}
                        onMouseLeave={() => {
                          setContent("");
                        }}
                        style={{
                          hover: {
                            fill: "#db9c09",
                            outline: "none",
                          },
                          // pressed: {
                          //   fill: "#ff5900",
                          //   outline: "none",
                          // },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
              {/* <ZoomableGroup
            center={[-101.0, 24.9]}
            zoom={1}
            minZoom={1}
            maxZoom={2}
          >
          </ZoomableGroup> */}
            </ComposableMap>
            <ReactTooltip html={true}>{content}</ReactTooltip>
          </React.Fragment>
        )}
        {/* {data && data.numeroNotas > 0 && (
          <React.Fragment>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              {numberWithCommas(data.numeroNotas)} notas
            </Typography>
            <Box style={{ height: "100%", overflow: "auto" }}>
              {Object.entries(data.categorias).map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <Typography align="left" variant="h6" color="primary">
                      {e[0]}
                    </Typography>
                    {Object.entries(e[1]).map((se, j) => {
                      if (se[0] === "doc_count") {
                        return (
                          <React.Fragment key={i + "_" + j}></React.Fragment>
                        );
                      }
                      return (
                        <Typography
                          key={i + "_" + j}
                          variant="subtitle2"
                          align="right"
                        >
                          {se[0] + ": " + numberWithCommas(se[1])}
                        </Typography>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Box>
          </React.Fragment>
        )} */}
        {/* {data &&
          (data.estatal !== 0 ||
            data.nacional !== 0 ||
            data.internacional !== 0) && (
            <React.Fragment>
              <Typography
                component="h4"
                variant="h4"
                color="primary"
                align="center"
              >
                <Link
                  href="#"
                  onClick={() => chartClick(getAmbitoClaveIntelite("nacional"))}
                >
                  {data.nacional && numberWithCommas(data.nacional)}
                </Link>
              </Typography>
              <Typography component="h5" variant="h6" align="center">
                Nacionales
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                color="primary"
                align="center"
              >
                <Link
                  href="#"
                  onClick={() => chartClick(getAmbitoClaveIntelite("estatal"))}
                >
                  {data.estatal && numberWithCommas(data.estatal)}
                </Link>
              </Typography>
              <Typography component="h5" variant="h6" align="center">
                Estatales
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                color="primary"
                align="center"
              >
                <Link
                  href="#"
                  onClick={() =>
                    chartClick(getAmbitoClaveIntelite("internacional"))
                  }
                >
                  {data.internacional && numberWithCommas(data.internacional)}
                </Link>
              </Typography>
              <Typography component="h5" variant="h6" align="center">
                Internacionales
              </Typography>
            </React.Fragment>
          )} */}
      </PaperContainer>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CygniModalList
            qry={qry}
            f1={f1}
            f2={f2}
            title="Detalle de notas"
            loadMore={true}
            ambito={filtro}
          />
        </div>
      </Modal> */}
    </React.Fragment>
  );
});
