import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Grid,
  Paper,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { MapaResultados } from "./MapaResultados";
import axios from "axios";
import { PaperTitle } from "../common/CygniTitles";
import ReactTooltip from "react-tooltip";
import { Resultados } from "./Resultados";
import { CygniLoading } from "../common/CygniLoading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeightSmall: {
    height: 180,
  },
  fixedHeightNormal: {
    height: 290,
  },
  boldText: {
    fontWeight: "bold",
  },
}));

export const DetRiesgo = (props) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [years, setYears] = useState(null);
  const [selTipo, setSelTipo] = useState(null);
  const [selAnio, setSelAnio] = useState(null);
  const [_, setSelMun] = useState(null);
  const [selNivel, setSelNivel] = useState(null);
  const [content, setContent] = useState("");
  const [elecciones, setElecciones] = useState([]);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    loadMaps();

    //Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Elecciones disponibles
    axios
      .get("/api/electoral/resultados", config)
      .then(function (response) {
        var currentElec = response.data.filter((el) => el.estado === props.edo);
        setElecciones(currentElec);
      })
      .catch(function (error) {
        console.error(error);
        setElecciones([]);
      });
  }, []);

  const loadMaps = () => {
    //Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .get("/api/electoral/dash/1f/" + props.edo, config)
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setData({});
      });
  };

  const selectTipo = (tipo) => {
    setSelTipo(tipo);
    setYears(data[tipo].mun);
    setSelAnio(null);
    setSelMun(null);
    setSelNivel(null);
  };

  const selectAnio = (anio) => {
    setSelAnio(anio);
    setSelMun(null);
    setSelNivel(null);
  };

  const munClick = (geo) => () => {
    setSelMun({
      cve_edo: geo.ENTIDAD,
      edo: geo.NOM_ENT,
      cve_mun: geo.CVE_MUN,
      mun: geo.NOM_MUN,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} style={{ marginTop: "2px" }}>
        <Grid item xs={12} md={5}>
          <Typography variant="h5" component="h4" align="center" gutterBottom>
            ({props.nombre}) Zonas de Riesgo
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {!data && <CygniLoading />}
          {data && (
            <Box display="flex">
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                size="small"
                style={{ margin: "auto" }}
              >
                {Object.keys(data).map((tipo) => {
                  return (
                    <Button
                      key={tipo}
                      onClick={() => selectTipo(tipo)}
                      variant={tipo === selTipo ? "contained" : "outlined"}
                    >
                      {tipo}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={1}>
          {years && (
            <Box display="flex">
              <ButtonGroup
                color="secondary"
                aria-label="outlined secondary button group"
                size="small"
                style={{ margin: "auto" }}
              >
                {Object.keys(years).map((year) => {
                  return (
                    <Button
                      key={years[year].year}
                      onClick={() => selectAnio(years[year].year)}
                      variant={
                        years[year].year === selAnio ? "contained" : "outlined"
                      }
                    >
                      {years[year].year}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          )}
        </Grid>
      </Grid>
      {data && years && selTipo && selAnio && (
        <React.Fragment>
          <AppBar position="static" color="secondary">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Ver mapa" {...a11yProps(0)} />
              <Tab label="Ver resultados" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Paper className={classes.paper} elevation={2}>
              <PaperTitle
                title={
                  "1a Fuerza: " + years.find((el) => el.year === selAnio).fuerza
                }
              />
              <Typography
                component="h6"
                variant="h6"
                align="center"
                color="textSecondary"
                style={{ marginTop: "2px", marginBottom: "3px" }}
              >
                {" con " +
                  years.find((el) => el.year === selAnio).por +
                  "% (" +
                  years.find((el) => el.year === selAnio).cant +
                  "/" +
                  years.find((el) => el.year === selAnio).total +
                  ")"}
              </Typography>
              <MapaResultados
                idEdo={props.id}
                estado={props.edo}
                tipo={selTipo}
                anio={selAnio}
                setTooltipContent={setContent}
                handleClick={munClick}
              />
              <ReactTooltip html={true}>{content}</ReactTooltip>
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {elecciones && (
              <Box display="flex" mb={1}>
                <ButtonGroup
                  color="secondary"
                  aria-label="outlined primary button group"
                  size="small"
                  style={{ margin: "auto" }}
                >
                  {elecciones
                    .filter(
                      (el) => el.tipo_elec === selTipo && el.anio === selAnio
                    )
                    .map((el) => {
                      return (
                        <Button
                          key={el.nivel}
                          onClick={() => setSelNivel(el.nivel)}
                          variant={
                            el.nivel === selNivel ? "contained" : "outlined"
                          }
                        >
                          {el.nivel}
                        </Button>
                      );
                    })}
                </ButtonGroup>
              </Box>
            )}
            {selNivel && (
              <Resultados
                estado={props.edo}
                tipo={selTipo}
                anio={selAnio}
                nivel={selNivel}
              />
            )}
          </TabPanel>
        </React.Fragment>
      )}
      {/* {data && years && selTipo && selAnio && (
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={12} md={selMun ? 8 : 12}>
            <Paper className={classes.paper} elevation={2}>
              <PaperTitle
                title={
                  "1a Fuerza: " + years.find((el) => el.year === selAnio).fuerza
                }
              />
              <Typography
                component="h6"
                variant="h6"
                align="center"
                color="textSecondary"
                style={{ marginTop: "2px", marginBottom: "3px" }}
              >
                {" con " +
                  years.find((el) => el.year === selAnio).por +
                  "% (" +
                  years.find((el) => el.year === selAnio).cant +
                  "/" +
                  years.find((el) => el.year === selAnio).total +
                  ")"}
              </Typography>
              <MapaResultados
                idEdo={props.id}
                estado={props.edo}
                tipo={selTipo}
                anio={selAnio}
                setTooltipContent={setContent}
                handleClick={munClick}
              />
              <ReactTooltip html={true}>{content}</ReactTooltip>
            </Paper>
            {props.edo && selTipo && selAnio && (
              <React.Fragment>
                <SectionTitle title="Resultados Electorales" />
                {elecciones && (
                  <Box display="flex" mb={1}>
                    <ButtonGroup
                      color="secondary"
                      aria-label="outlined primary button group"
                      size="small"
                      style={{ margin: "auto" }}
                    >
                      {elecciones
                        .filter(
                          (el) =>
                            el.tipo_elec === selTipo && el.anio === selAnio
                        )
                        .map((el) => {
                          return (
                            <Button
                              key={el.nivel}
                              onClick={() => setSelNivel(el.nivel)}
                              variant={
                                el.nivel === selNivel ? "contained" : "outlined"
                              }
                            >
                              {el.nivel}
                            </Button>
                          );
                        })}
                    </ButtonGroup>
                  </Box>
                )}
                {selNivel && (
                  <Resultados
                    estado={props.edo}
                    tipo={selTipo}
                    anio={selAnio}
                    nivel={selNivel}
                  />
                )}
              </React.Fragment>
            )}
          </Grid>
          {selMun && (
            <Grid item xs={12} md={4}>
              <IndicadoresBar cve_edo={props.id} selMun={selMun} />
            </Grid>
          )}
        </Grid>
      )} */}
    </Container>
  );
};
