import React, { useState, useEffect } from "react";
import { CircularProgress, Button, makeStyles } from "@material-ui/core";
import { CygniNotesList } from "./CygniList";
import { getNotas } from "./CygniRequests";
import { getFormattedDate } from "./Cygni";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: "absolute",
    width: "85%", //400,
    height: "85%", //500,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const CygniModalList = React.forwardRef(
  (
    {
      qry,
      f1,
      f2,
      title,
      interval = null,
      selectedData = null,
      selectedDataLabel = "",
      loadMore = false,
      tipomedio = "null",
      porTipoTipo = "null",
      nombreMedio = "null",
      ambito = "null",
      estados = "null",
      conductor = "null",
      genero = "null",
      tendencia = "null",
      participacion = "null",
      posicionamiento = "null",
    },
    ref
  ) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [listData, setListData] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
      var f1Fmt = f1;
      var f2Fmt = f2;

      if (interval) {
        if (interval === "d") {
          f1Fmt = selectedData.name.replace(/-/g, "_");
          f2Fmt = selectedData.name.replace(/-/g, "_");
        } else if (interval === "w") {
          f2Fmt =
            selectedData.name.split("-")[0] +
            "_" +
            selectedData.name.split("-")[1] +
            "_" +
            getFormattedDate(
              new Date(selectedData.name).addDays(7),
              "_",
              "ymd"
            );
        } else if (interval === "m") {
          f1Fmt =
            selectedData.name.split("-")[0] +
            "_" +
            selectedData.name.split("-")[1] +
            "_01";
          // f2Fmt =
          //   selectedData.name.split("-")[0] +
          //   "_" +
          //   selectedData.name.split("-")[1] +
          //   "_" +
          //   getFormattedDate(
          //     new Date(
          //       selectedData.name.split("-")[0],
          //       selectedData.name.split("-")[1],
          //       0
          //     ),
          //     "_",
          //     "ymd"
          //   );
          f2Fmt = getFormattedDate(
            new Date(
              selectedData.name.split("-")[0],
              selectedData.name.split("-")[1],
              0
            ),
            "_",
            "ymd"
          );
        } else if (interval === "y") {
          f1Fmt = selectedData.name.split("-")[0] + "_01_01";
          f2Fmt = selectedData.name.split("-")[0] + "_12_31";
        }
      } else {
        if (selectedData && selectedDataLabel === "fecha") {
          f1Fmt = selectedData;
          f2Fmt = selectedData;
        }
      }

      getNotas(
        qry,
        f1Fmt,
        f2Fmt,
        page,
        tipomedio,
        porTipoTipo,
        nombreMedio,
        ambito,
        estados,
        conductor,
        genero,
        tendencia,
        participacion,
        posicionamiento
      )
        .then(function (response) {
          if (
            listData === null &&
            response &&
            response.length > 0 &&
            "name" in response[0]
          ) {
            setListData(response);
          } else {
            if (listData && listData.length > 0) {
              setListData([...listData, ...response]);
            }
          }
        })
        .catch(function (error) {
          setListData([]);
          console.error(error);
        });
    }, [page]);

    function loadMoreNotes() {
      setPage((page) => page + 1);
    }

    return (
      <div style={modalStyle} className={classes.modalPaper} ref={ref}>
        <h2 id="simple-modal-title">{title}</h2>
        {!listData || listData.length < 1 ? (
          <CircularProgress style={{ margin: "auto" }} />
        ) : (
          <React.Fragment>
            <CygniNotesList
              data={listData}
              clickItemOpenUrl={true}
              query={qry}
            />
            {loadMore && (
              <Button color="primary" onClick={loadMoreNotes}>
                Más notas...
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
);
