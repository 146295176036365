import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
  Tooltip,
  withStyles,
  // useTheme,
  Toolbar,
  IconButton,
  Modal,
  List,
  ListItem,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { Search, Info, ListAlt, SaveOutlined } from "@material-ui/icons";
import { MediosCategorias } from "./components/MediosCategorias";
import { MediosHistograma } from "./components/MediosHistograma";
import { MediosSentimiento } from "./components/MediosSentimiento";
import { MediosPosicionamiento } from "./components/MediosPosicionamiento";
import { MediosTipos } from "./components/MediosTipos";
import { MediosParticipacion } from "./components/MediosParticipacion";
import { MediosAmbito } from "./components/MediosAmbito";
import { MediosClasificacion } from "./components/MediosClasificacion";
import { MediosPorMedio } from "./components/MediosPorMedio";
import { MediosPorConductor } from "./components/MediosPorConductor";
import { MediosPorGenero } from "./components/MediosPorGenero";
import { RedesMencionesTendencia } from "./components/RedesMencionesTendencia";
import { RedesMencionesTotales } from "./components/RedesMencionesTotales";
import { RedesTendenciaTweets } from "./components/RedesTendenciaTweets";
import { RedesTopInfluencers } from "./components/RedesTopInfluencers";
import { RedesFuentesPrincipales } from "./components/RedesFuentesPrincipales";
import { MediosMunsEnNotas } from "./components/MediosMunsEnNotas";
import { getQueryTab, setQryTab } from "../common/CygniRequests";
import { UserContext } from "../contexts/user-context";

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: "absolute",
    width: "65%",
    // height: "55%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
  bgIconBtn: {
    backgroundColor: "#edebeb",
    marginRight: "0.6rem",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black, //"#f5f5f9",
    color: theme.palette.common.white, //"rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const Busqueda = () => {
  // const theme = useTheme();
  const classes = useStyles();

  const userContext = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [value, setValue] = useState(0);
  const [busqueda, setBusqueda] = useState({
    query: null,
    fecha1: formatDate(new Date()),
    fecha2: formatDate(new Date()),
    results: null,
  });
  const [formData, setFormData] = useState(null);
  const [qryData, setQryData] = useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openAlertWarning, setOpenAlertWarning] = React.useState(false);

  useEffect(() => {
    loadQueries();
  }, []);

  const loadQueries = () => {
    getQueryTab("QUERY", userContext.id)
      .then(function (response) {
        setQryData(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setQryData(null);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setBusqueda({
      ...busqueda,
      [e.target.name]: e.target.value,
    });
  };

  const onSearch = () => {
    if (busqueda.query) {
      setFormData(busqueda);
    }
  };

  function getTwitterAccount(query) {
    var account = "";
    switch (query.toLowerCase()) {
      case "amlo":
      case "peje":
      case "presidente":
      case "lopez obrador":
        account = "to:lopezobrador_ OR from:lopezobrador_";
        break;
      case "calderon":
      case "felipe calderon":
        account = "to:FelipeCalderon OR from:FelipeCalderon";
        break;
      case "margarita zavala":
        account = "to:Mzavalagc OR from:Mzavalagc";
        break;
      case "monreal":
      case "ricardo monreal":
        account = "to:RicardoMoneralA OR from:RicardoMoneralA";
        break;
      default:
        if (query.includes(",")) {
          account = '"' + query.split(",")[0] + '"';
        } else if (query.includes(".")) {
          account = '"' + query.split(".")[0] + '"';
        } else if (query.includes("!")) {
          account = '"' + query.split(".!")[0] + '"';
        } else {
          account = '"' + query + '"';
        }
        break;
    }
    return account;
  }

  const saveQry = () => {
    var form = {
      usuario: userContext.id,
      nombre: busqueda.query,
      query: busqueda.query,
      twitter: getTwitterAccount(busqueda.query),
    };

    if (qryData) {
      var f = qryData.find((q) => q.query === form.query);

      if (!f) {
        setQryTab("QUERY", form)
          .then(function (response) {
            if (
              response.data &&
              response.data.insertId &&
              response.data.insertId !== 0
            ) {
              setOpenAlert(true);
              loadQueries();
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        setOpenAlertWarning(true);
      }
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleCloseAlertWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertWarning(false);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Búsqueda{" "}
        <HtmlTooltip
          enterTouchDelay={50}
          leaveDelay={3000}
          leaveTouchDelay={3000}
          title={
            <React.Fragment>
              <Typography color="inherit">¿Cómo realizar búsquedas?</Typography>
              <ul>
                <li>
                  <b>
                    <u>Búsqueda sencilla: </u>
                  </b>
                  Ingrese el nombre de un actor o un concepto como "
                  <em>amlo</em>"" o "<em>huachicoleo</em>".
                </li>
                <li>
                  <b>
                    <u>Búsqueda A y B: </u>
                  </b>
                  Para buscar notas que contengan dos actores o conceptos juntos
                  deberá usar un punto de la siguiente manera "
                  <em>amlo.morena</em>" o "<em>lopez obrador.presidente</em>".
                </li>
                <li>
                  <b>
                    <u>Búsqueda A o B: </u>
                  </b>
                  Para buscar notas o uno u otro (actores/conceptos) deberá usar
                  una coma de la siguiente manera "<em>amlo,peje</em>" o "
                  <em>huachicoleo,robo de combustible</em>".
                </li>
                <li>
                  <b>
                    <u>Búsqueda A y no B: </u>
                  </b>
                  Para buscar notas que contengan un actor/concepto pero no otro
                  deberá usar un punto seguido de un signo de exclamación de la
                  siguiente manera "<em>amlo.!prd</em>" o "
                  <em>morena.!ebrard</em>",
                </li>
              </ul>
            </React.Fragment>
          }
        >
          <Info color="secondary" />
        </HtmlTooltip>
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Box display="flex" flexDirection="row">
            <IconButton aria-label="queries" onClick={handleOpen}>
              <ListAlt />
            </IconButton>
            <TextField
              value={busqueda.query || ""}
              id="query"
              name="query"
              label="¿Qué desea buscar?"
              variant="outlined"
              onChange={onChange}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" flexDirection="row">
            <TextField
              id="fecha1"
              name="fecha1"
              label="Fecha Inicial"
              type="date"
              defaultValue={busqueda.fecha1}
              onChange={onChange}
              style={{ marginRight: "0.5rem" }}
            />
            <TextField
              id="fecha2"
              name="fecha2"
              label="Fecha Final"
              type="date"
              defaultValue={busqueda.fecha2}
              onChange={onChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Box display="flex" flexDirection="row">
            {formData && (
              <IconButton
                aria-label="queries"
                color="secondary"
                className={classes.bgIconBtn}
                onClick={() => saveQry()}
              >
                <SaveOutlined />
              </IconButton>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onSearch}
              startIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
      {formData && (
        <React.Fragment>
          <AppBar position="static" style={{ marginTop: "10px" }}>
            <Toolbar>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Medios" />
                <Tab label="Twitter" />
              </Tabs>
            </Toolbar>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <MediosCategorias
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <MediosHistograma
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosMunsEnNotas
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <MediosSentimiento
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosPosicionamiento
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MediosTipos
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosParticipacion
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MediosAmbito
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <MediosClasificacion
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosPorMedio
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosPorConductor
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediosPorGenero
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <RedesMencionesTendencia
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RedesMencionesTotales
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RedesTendenciaTweets
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RedesTopInfluencers
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RedesFuentesPrincipales
                  query={formData.query}
                  fecha1={formData.fecha1}
                  fecha2={formData.fecha2}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </React.Fragment>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <Typography variant="h5" color="primary" gutterBottom>
            Búsquedas Guardadas
          </Typography>
          <List style={{ height: "20rem" }}>
            {qryData &&
              qryData.map((qry, i) => {
                return (
                  <ListItem
                    key={i}
                    button
                    onClick={() => {
                      setBusqueda({ ...busqueda, query: qry.query });
                      handleClose();
                    }}
                  >
                    <ListItemText
                      primary={`(${qry.id}) ` + qry.nombre}
                      secondary={qry.query}
                    />
                  </ListItem>
                );
              })}
          </List>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          ¡QUERY guardado exitosamente!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlertWarning}
        autoHideDuration={6000}
        onClose={handleCloseAlertWarning}
      >
        <Alert onClose={handleCloseAlertWarning} severity="warning">
          Ya existe un query guardado con estas características.
        </Alert>
      </Snackbar>
    </Container>
  );
};
