import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Modal, Typography } from "@material-ui/core";
import { CygniLineChart } from "../../common/CygniLineChart";
import { queryPrep, numberWithCommas } from "../../common/Cygni";
import { CygniModalList } from "../../common/CygniModal";
import { getGraficas } from "../../common/CygniRequests";
import { CygniLoading } from "../../common/CygniLoading";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

export const MediosSentimiento = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  // const [filtro, setFiltro] = useState("null");
  const [selectedData, setSelectedData] = useState(null);
  const [png, ref] = useRechartToPng();
  const [tot, setTot] = useState({ pos: 0, neg: 0, neu: 0 });
  const qry = queryPrep(query);
  const f1 = fecha1 + " 00:00:00.0";
  const f2 = fecha2 + " 23:59:59.0";
  const f1Buscar = fecha1.replace(/-/g, "_");
  const f2Buscar = fecha2.replace(/-/g, "_");

  useEffect(() => {
    setData(null);
    setTot({ pos: 0, neg: 0, neu: 0 });

    // Gráficas
    getGraficas(qry, f1, f2, "cygnimoviltendencia")
      .then(function (response) {
        setData(response.porFecha);
        var totales = { pos: 0, neg: 0, neu: 0 };
        Object.entries(response.porFecha).forEach(([_, v]) => {
          totales.pos += v.pos;
          totales.neg += v.neg;
          totales.neu += v.neu;
        });
        setTot(totales);
      })
      .catch(function (error) {
        setData([]);
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  const chartClick = (chartData, index) => {
    if (chartData && chartData.activeLabel !== null) {
      handleOpen(chartData.activeLabel);
    }
  };

  const handleOpen = async (selectedData) => {
    setOpen(true);
    // setFiltro(selectedData); // deberia tomar pos/neg/neu
    setSelectedData(selectedData.replace(/-/g, "_"));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "valoracion.png");
  }, [png]);

  return (
    <React.Fragment>
      <PaperContainer
        title="Sentimiento"
        subtitle={
          data &&
          data.length > 0 &&
          tot &&
          numberWithCommas(tot.pos) +
            " pos, " +
            numberWithCommas(tot.neg) +
            " neg, " +
            numberWithCommas(tot.neu) +
            " neu"
        }
        exportData={data}
        exportName="medios_sent"
        downloadChart={downloadChart}
      >
        {!data && <CygniLoading />}
        {data && data.length === 0 && (
          <Typography
            component="h5"
            variant="h5"
            align="center"
            style={{ marginTop: "10px" }}
          >
            No hay datos disponibles.
          </Typography>
        )}
        {/* {data && data.length > 0 && tot && (
          <Typography variant="subtitle2" align="center" color="secondary">
            {numberWithCommas(tot.pos)} POS, {numberWithCommas(tot.neg)} NEG,{" "}
            {numberWithCommas(tot.neu)} NEU.
          </Typography>
        )} */}
        {data && data.length > 0 && (
          <CygniLineChart
            data={data}
            keysAndColors={[
              { pos: "#457ef7" },
              { neg: "#f74b45" },
              { neu: "#f7d045" },
            ]}
            chartClick={chartClick}
            exportRef={ref}
          />
        )}
      </PaperContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CygniModalList
            qry={qry}
            f1={f1Buscar}
            f2={f2Buscar}
            title="Detalle de notas"
            loadMore={true}
            selectedData={selectedData}
            selectedDataLabel="fecha"
            // tendencia={filtro}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
});
