import React from "react";
import { Link } from "@reach/router";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from "@material-ui/core";
import {
  Dashboard,
  Map,
  TableChart,
  Poll,
  // Group,
  Search,
  FilterNone,
  CenterFocusStrong,
} from "@material-ui/icons";

export const mainListItems = (
  <div>
    <ListSubheader inset>Medios</ListSubheader>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <Dashboard />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/medios">
      <ListItemIcon>
        <FilterNone />
      </ListItemIcon>
      <ListItemText primary="Columnas y PPs" />
    </ListItem>
    <ListItem button component={Link} to="/busqueda">
      <ListItemIcon>
        <Search />
      </ListItemIcon>
      <ListItemText primary="Búsqueda" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Datos</ListSubheader>
    <ListItem button component={Link} to="/cygni_maps">
      <ListItemIcon>
        <Map />
      </ListItemIcon>
      <ListItemText primary="Cygni Maps" />
    </ListItem>
    <ListItem button component={Link} to="/analisis">
      <ListItemIcon>
        <CenterFocusStrong />
      </ListItemIcon>
      <ListItemText primary="Análisis" />
    </ListItem>
    <ListItem button component={Link} to="/riesgos">
      <ListItemIcon>
        <TableChart />
      </ListItemIcon>
      <ListItemText primary="Electoral" />
    </ListItem>
    <ListItem button component={Link} to="/encuestas">
      <ListItemIcon>
        <Poll />
      </ListItemIcon>
      <ListItemText primary="Encuestas" />
    </ListItem>
    {/* <ListItem button component={Link} to="/candidatos">
      <ListItemIcon>
        <Group />
      </ListItemIcon>
      <ListItemText primary="Candidatos" />
    </ListItem> */}
  </div>
);
