import React from "react";
import { useContext } from "react";
import { Redirect } from "@reach/router";
import { UserContext } from "./contexts/user-context";

export const Logout = () => {
  // userContext
  const userContext = useContext(UserContext);

  localStorage.setItem("logged", "false");

  userContext.toggleLogged();

  return <Redirect from="/logout" to="/" noThrow />;
};
