import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
// import {} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  red: {
    backgroundColor: red[500],
  },
  orange: {
    backgroundColor: deepOrange[500],
  },
  grey: {},
  // paper: {
  //   padding: theme.spacing(2),
  //   display: "flex",
  //   overflow: "auto",
  //   flexDirection: "column",
  // },
  // heading: {
  //   fontSize: theme.typography.pxToRem(19),
  //   fontWeight: theme.typography.fontWeightBold,
  // },
  // fab: {
  //   margin: 0,
  //   top: "auto",
  //   right: 20,
  //   bottom: 20,
  //   left: "auto",
  //   position: "fixed",
  // },
  // pos: {
  //   marginBottom: 12,
  // },
}));

export const Incidentes = () => {
  const classes = useStyles();

  const [incidentes, setIncidentes] = useState([]);

  useEffect(() => {
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // API
    axios
      .get("http://162.243.166.142:5002/api/incidentes", config)
      .then(function (response) {
        setIncidentes(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setIncidentes([]);
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Incidentes Reportados
      </Typography>
      <List className={classes.listRoot}>
        {incidentes &&
          incidentes.map((inc) => (
            <React.Fragment key={inc._id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    className={
                      inc.prioridad === "ALTA"
                        ? classes.red
                        : inc.prioridad === "MEDIA"
                        ? classes.orange
                        : classes.grey
                    }
                  >
                    {inc.prioridad.substring(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={inc.titulo}
                  secondary={inc.descripcion}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
      </List>
    </Container>
  );
};
