import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { getQueryTab, setQryTab } from "../common/CygniRequests";
import { UserContext } from "../contexts/user-context";

export const ConfQueries = ({ paperStyle }) => {
  // userContext
  const userContext = useContext(UserContext);
  const [data, setData] = useState(null);
  const [form, setForm] = useState({
    usuario: userContext.id,
    nombre: "",
    query: "",
    twitter: "",
  });

  useEffect(() => {
    loadQueries();
  }, []);

  const loadQueries = () => {
    getQueryTab("QUERY", userContext.id)
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    var val = event.target.value;
    if (name === "twitter" && val !== "" && !val.includes("@")) {
      val = "@" + val;
    }
    setForm({
      ...form,
      [name]: val,
    });
  };

  const saveQry = () => {
    if (
      !form.nombre ||
      form.nombre === "" ||
      !form.query ||
      form.query === ""
    ) {
      return;
    }
    setQryTab("QUERY", form)
      .then(function (response) {
        if (
          response.data &&
          response.data.insertId &&
          response.data.insertId !== 0
        ) {
          setForm({
            usuario: userContext.id,
            nombre: "",
            query: "",
            twitter: "",
          });
          loadQueries();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <Paper
        className={paperStyle}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        elevation={2}
      >
        <Typography variant="h5" color="primary" gutterBottom>
          Nuevo Query
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
              value={form.nombre}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="query"
              name="query"
              label="Query"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
              value={form.query}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="twitter"
              name="twitter"
              label="@twitter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
              value={form.twitter}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button variant="contained" color="primary" onClick={saveQry}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={paperStyle} elevation={2}>
        <Typography variant="h5" color="primary" gutterBottom>
          Todos Mis Queries
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>#QryId</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Nombre</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Query</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Twitter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{"q#" + el.id}</TableCell>
                  <TableCell>{el.nombre}</TableCell>
                  <TableCell>{el.query}</TableCell>
                  <TableCell>
                    {el.twitter === "null" ? "" : el.twitter}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};
