import React from "react";

export const UserContext = React.createContext({
  id: localStorage.getItem("id"),
  usuario: localStorage.getItem("usuario"),
  nombre: localStorage.getItem("nombre"),
  email: localStorage.getItem("email"),
  cliente: localStorage.getItem("cliente"),
  tipo: localStorage.getItem("tipo"),
  region: localStorage.getItem("region"),
  dep_user: localStorage.getItem("dep_user"),
  logged: localStorage.getItem("logged") === "true" ? true : false,
  tableros: [
    {
      id: "1",
      nombre: "Presidente 2024",
      actores: [
        { nombre: "AMLO", query: "amlo", twitter: "@lopezobrador_" },
        {
          nombre: "Felipe Calderón",
          query: "felipe_calderon",
          twitter: "@FelipeCalderon",
        },
        {
          nombre: "Enrique Alfaro",
          query: "enrique_alfaro",
          twitter: "@EnriqueAlfaroR",
        },
        {
          nombre: "Silvano Aureoles",
          query: "silvano_aureoles",
          twitter: "@Silvano_A",
        },
      ],
    },
    {
      id: "2",
      nombre: "Sinaloa 2021",
      actores: [
        {
          nombre: "Tatiana Clouthier",
          query: "tatiana_clouthier",
          twitter: "@tatclouthier",
        },
        {
          nombre: "Ruben Rocha",
          query: "ruben_rocha_moya",
          twitter: "@rochamoya_",
        },
        {
          nombre: "Gerardo Vargas Landeros",
          query: "gerardo_vargas_landeros",
          twitter: "@GVargasLanderos",
        },
        {
          nombre: "Heriberto Felix",
          query: "heriberto_felix_guerra",
          twitter: "@HeribertoFelixG",
        },
      ],
    },
    {
      id: "3",
      nombre: "Nuevo León 2021",
      actores: [
        {
          nombre: "Alfonso Romo",
          query: "alfonso_romo_garza",
          twitter: "Alfonso Romo Garza",
        },
        {
          nombre: "Idelfonso Guajardo",
          query: "idelfonso_guajardo",
          twitter: "@ildefonsogv",
        },
        {
          nombre: "Adrián de la Garza",
          query: "adrian_de_la_garza",
          twitter: "@AdrianDeLaGarza",
        },
        {
          nombre: "Clara Luz Flores",
          query: "clara_luz_flores",
          twitter: "@claraluzflores",
        },
      ],
    },
  ],
  loggedIn: () => {},
  loggedOut: () => {},
});
