import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { getTopPrimerasPlanas } from "../common/CygniRequests";
import { CygniLoading } from "../common/CygniLoading";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

export const PrimerasPlanas = ({ classHeight, handleClick = null }) => {
  const classes = useStyles();
  const [pps, setPps] = useState(null);

  useEffect(() => {
    getTopPrimerasPlanas()
      .then(function (response) {
        setPps(response);
      })
      .catch(function (error) {
        setPps([]);
        console.error(error);
      });
  }, []);

  if (!pps) {
    return <CygniLoading />;
  }

  if (pps && pps.length === 0) {
    return (
      <Typography
        component="h5"
        variant="h5"
        align="center"
        style={{ marginTop: "10px" }}
      >
        No hay datos disponibles.
      </Typography>
    );
  }

  if (pps && pps.length > 0) {
    return (
      <GridList
        cellHeight={180}
        className={clsx(classHeight, classes.gridList)}
        cols={3.65}
      >
        {pps.map((tile) => (
          <GridListTile key={tile.clave}>
            <img src={tile.url} alt={tile.clave} />
            <GridListTileBar
              style={{
                backgroundColor:
                  tile.clasif === "AAA" ? "#030c4aDF" : "#4f0f0aDF",
              }}
              title={tile.nombre}
              // subtitle={<span>{tile.clasif}</span>}
              actionIcon={
                <IconButton
                  aria-label={`info about ${tile.title}`}
                  className={classes.icon}
                  onClick={() => handleClick(tile.url)}
                >
                  <Info />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    );
  }
};
