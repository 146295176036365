import React, { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Box,
} from "@material-ui/core";
import { ExportCSV } from "../common/ExportCSV";

// function preventDefault(event) {
//   event.preventDefault();
// }

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  // fixedHeighSmall: {
  //   height: 120,
  // },
  fixedHeightNormal: {},
  seeMore: {
    marginTop: theme.spacing(3),
  },
  boldText: {
    fontWeight: "bold",
  },
  anexoText: {
    fontSize: "small",
    fontStyle: "italic",
  },
}));

export const Resultados = () => {
  const classes = useStyles();
  // const fixedHeightPaperSmall = clsx(classes.paper, classes.fixedHeighSmall);
  const fixedHeightPaperNormal = clsx(classes.paper, classes.fixedHeightNormal);
  const [params, setParams] = useState({
    estado: "",
    nivel: "",
    tipo: "",
    anio: "",
  });
  const [elecciones, setElecciones] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [tiposDict, setTiposDict] = useState([]);
  const [niveles, setNiveles] = useState([]);
  const [nivelesDict, setNivelesDict] = useState([]);
  const [anios, setAnios] = useState([]);
  const [resultados, setResultados] = useState([]);

  // Init
  useEffect(() => {
    //Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Estados disponibles
    axios
      .get("/api/electoral/estados", config)
      .then(function (response) {
        setEstados(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setEstados([]);
      });

    // Tipos disponibles
    axios
      .get("/api/electoral/tipo_elec", config)
      .then(function (response) {
        setTiposDict(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setTiposDict([]);
      });

    // Niveles disponibles
    axios
      .get("/api/electoral/niveles", config)
      .then(function (response) {
        setNivelesDict(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setNivelesDict([]);
      });

    // Elecciones disponibles
    axios
      .get("/api/electoral/resultados", config)
      .then(function (response) {
        setElecciones(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setElecciones([]);
      });
  }, []);

  useEffect(() => {
    // Cargar resultados electorales según los parámetros
    getResults();
  }, [params]);

  const getResults = () => {
    setResultados([]);
    if (
      params.estado !== "" &&
      params.tipo !== "" &&
      params.anio !== "" &&
      params.nivel !== ""
    ) {
      // consulta servicio
      const { estado, tipo, anio, nivel } = params;
      axios
        .get(
          "/api/electoral/fuerzas/" +
            estado +
            "/" +
            tipo +
            "/" +
            anio +
            "/" +
            nivel
        )
        .then(function (response) {
          setResultados(response.data);
        })
        .catch(function (error) {
          console.error(error);
          setResultados({});
        });
    }
  };

  const handleOnChange = (e) => {
    if (elecciones) {
      if (e.target.name === "estado") {
        var tiposRes = [];
        elecciones.forEach((elecc) => {
          if (
            elecc.estado === e.target.value &&
            !tiposRes.includes(elecc.tipo_elec)
          ) {
            tiposRes.push(elecc.tipo_elec);
          }
        });
        setTipos(tiposRes);
        setAnios([]);
        setNiveles([]);
        setParams({
          estado: e.target.value,
          nivel: "",
          tipo: "",
          anio: "",
        });
      } else if (e.target.name === "tipo") {
        var aniosRes = [];
        elecciones.forEach((elecc) => {
          if (
            elecc.estado === params.estado &&
            elecc.tipo_elec === e.target.value &&
            !aniosRes.includes(elecc.anio)
          ) {
            aniosRes.push(elecc.anio);
          }
        });
        setAnios(aniosRes);
        setNiveles([]);
        setParams({
          ...params,
          nivel: "",
          tipo: e.target.value,
          anio: "",
        });
      } else if (e.target.name === "anio") {
        var nivelRes = [];
        elecciones.forEach((elecc) => {
          if (
            elecc.estado === params.estado &&
            elecc.tipo_elec === params.tipo &&
            elecc.anio === e.target.value &&
            !nivelRes.includes(elecc.nivel)
          ) {
            nivelRes.push(elecc.nivel);
          }
        });
        setNiveles(nivelRes);
        setParams({
          ...params,
          nivel: "",
          anio: e.target.value,
        });
      } else if (e.target.name === "nivel") {
        setParams({
          ...params,
          nivel: e.target.value,
        });
      }
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>Estado</InputLabel>
            <Select
              id="estado"
              name="estado"
              value={params.estado}
              onChange={handleOnChange}
            >
              <MenuItem value="">Seleccione Estado</MenuItem>
              {estados &&
                estados.map((edo) => (
                  <MenuItem key={edo.codigo} value={edo.codigo}>
                    {edo.nombre}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {params.estado && (
          <Grid item xs={12} md={3}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel>Tipo de Elección</InputLabel>
              <Select
                id="tipo"
                name="tipo"
                value={params.tipo}
                onChange={handleOnChange}
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {tipos &&
                  tipos.map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      {tiposDict.find((el) => el.codigo === tipo).nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {params.tipo && (
          <Grid item xs={12} md={3}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel>Año</InputLabel>
              <Select
                id="anio"
                name="anio"
                value={params.anio}
                onChange={handleOnChange}
              >
                <MenuItem value="">Seleccione Año</MenuItem>
                {anios &&
                  anios.map((anio) => (
                    <MenuItem key={anio} value={anio}>
                      {anio}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {params.anio && (
          <Grid item xs={12} md={3}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel>Nivel</InputLabel>
              <Select
                id="nivel"
                name="nivel"
                value={params.nivel}
                onChange={handleOnChange}
              >
                <MenuItem value="">Seleccione Nivel</MenuItem>
                {niveles &&
                  niveles.map((nivel) => (
                    <MenuItem key={nivel} value={nivel}>
                      {nivelesDict.find((el) => el.codigo === nivel).nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {/* TABLA DE RESULTADOS */}
        <Grid item xs={12}>
          <Paper className={fixedHeightPaperNormal}>
            <Grid container>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Resultados Electorales
              </Typography>
              {resultados && resultados.data && (
                <ExportCSV
                  csvData={resultados.data}
                  fileName={
                    params.estado +
                    "_" +
                    params.tipo +
                    "_" +
                    params.anio +
                    "_" +
                    params.nivel
                  }
                />
              )}
            </Grid>
            {resultados && resultados.cols && (
              <Box style={{ margin: "auto" }}>
                <Chip
                  size="small"
                  style={{
                    backgroundColor: "#91d2fa",
                    fontSize: "11px",
                  }}
                  label="% de Primera Fuerza"
                />
                <Chip
                  size="small"
                  style={{
                    backgroundColor: "#c0f7a6",
                    fontSize: "11px",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                  label="% de Segunda Fuerza"
                />
                <Chip
                  size="small"
                  style={{
                    fontSize: "11px",
                  }}
                  label="% de Tercera Fuerza"
                />
              </Box>
            )}
            <Table size="small">
              <TableHead>
                <TableRow>
                  {resultados && resultados.cols && (
                    <React.Fragment>
                      {resultados.cols.split(",").map((cell) => (
                        <TableCell key={cell} className={classes.boldText}>
                          {cell}
                        </TableCell>
                      ))}
                      {resultados.anexo.split(",").map((cell) => (
                        <TableCell key={cell} className={classes.boldText}>
                          {cell}
                        </TableCell>
                      ))}
                      <TableCell
                        key="1F"
                        align="right"
                        className={classes.boldText}
                      >
                        1ra Fuerza
                      </TableCell>
                      <TableCell
                        key="2F"
                        align="right"
                        className={classes.boldText}
                      >
                        2da Fuerza
                      </TableCell>
                      <TableCell
                        key="3F"
                        align="right"
                        className={classes.boldText}
                      >
                        3ra Fuerza
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultados &&
                  resultados.data &&
                  resultados.data.map((cell, i) => (
                    <TableRow key={i}>
                      {resultados.cols.split(",").map((cd, j) => (
                        <TableCell key={j}>{cell[cd]}</TableCell>
                      ))}
                      {resultados.anexo.split(",").map((cd, j) => (
                        <TableCell key={j} className={classes.anexoText}>
                          {cell[cd]}
                        </TableCell>
                      ))}
                      <TableCell align="right" key={i + "-" + cell["PF"]}>
                        {cell["PF"]}{" "}
                        <Chip
                          size="small"
                          style={{
                            backgroundColor: "#91d2fa",
                            fontSize: "11px",
                          }}
                          label={cell["PF_P"] + "%"}
                        />
                      </TableCell>
                      <TableCell align="right" key={i + "-" + cell["SF"]}>
                        {cell["SF"]}{" "}
                        <Chip
                          size="small"
                          style={{
                            backgroundColor: "#c0f7a6",
                            fontSize: "11px",
                          }}
                          label={cell["SF_P"] + "%"}
                        />
                      </TableCell>
                      <TableCell align="right" key={i + "-" + cell["TF"]}>
                        {cell["TF"]}{" "}
                        <Chip
                          size="small"
                          style={{
                            fontSize: "11px",
                          }}
                          label={cell["TF_P"] + "%"}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div className={classes.seeMore}>
              {/* <a color="primary" href="#" onClick={preventDefault}>
                Ver más datos
              </a> */}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
