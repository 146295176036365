import { CircularProgress } from "@material-ui/core";
import React from "react";

export const CygniLoading = React.memo(() => {
  return <CircularProgress color="secondary" style={{ margin: "auto" }} />;
});

export const CygniLoadingFix = React.memo(() => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ marginTop: "5%", marginLeft: "50%" }}>
        <CircularProgress color="secondary" style={{ margin: "auto" }} />
      </div>
    </div>
  );
});
