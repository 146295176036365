import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getColumnistaNotas, getColumnistas } from "../common/CygniRequests";
import { PrimerasPlanas } from "../dashboard/PrimerasPlanas";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "220px",
    padding: ".5rem",
    overflow: "auto",
  },
  pps: {
    height: "200px",
  },
  modalPaper: {
    position: "absolute",
    width: "60%",
    height: "80%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
}));

export const Medios = () => {
  const classes = useStyles();
  const cols = {
    1: "Trascendidos",
    2: "Columnas Políticas",
    3: "Columnas Económicas",
    4: "Columnas Deportivas",
    // 5: "Columnas de Espectáculos",
    // 6: "Columnas de Negocios",
    // 7: "Columnas Tecnológicas",
    // 8: "Columnas Culturales",
    9: "Opinión",
    10: "Comentaristas",
  };
  const [tipos, setTipos] = useState(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [columnista, setColumnista] = useState(null);
  const [nota, setNota] = useState(null);
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = (url) => {
    setOpen(true);
    setUrl(url);
    setColumnista(null);
    setNota(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenNote = (event, tipo, note) => {
    setOpen(true);
    setUrl(null);
    setNota(null);
    setColumnista(tipo + "|" + note);
  };

  useEffect(() => {
    var tData = [];
    var resMap = Object.entries(cols).map(async ([k, v]) => {
      var res = await getColumnistas(k);

      tData.push({ tipo: v, columnistas: res });
    });

    // Wait for all requests, and then setState
    Promise.all(resMap).then(() => {
      setTipos(tData);
    });
  }, []);

  useEffect(() => {
    if (columnista) {
      getColumnistaNotas(columnista.split("|")[0], columnista.split("|")[1])
        .then(function (response) {
          if (response.length > 0) {
            setNota(response[0]);
          } else {
            setNota(null);
          }
        })
        .catch(function (error) {
          console.error(error);
          setNota(null);
        });
    }
  }, [columnista]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center">
        Medios Tradicionales
      </Typography>
      <Grid container spacing={1}>
        {tipos &&
          tipos.length > 0 &&
          tipos.map((t, i) => {
            var tId = Object.keys(cols).find((key) => cols[key] === t.tipo);
            return (
              <Grid key={i} item xs={12} md={4}>
                <Paper className={classes.paper} elevation={3}>
                  <Typography variant="h6" color="primary" align="center">
                    {t.tipo}
                  </Typography>
                  <List component="nav" aria-label="list-notes">
                    {t.columnistas &&
                      t.columnistas.map((tc, j) => {
                        return (
                          <ListItem
                            key={"li_" + j}
                            button
                            onClick={(e) =>
                              handleOpenNote(
                                e,
                                tId,
                                tId === "9" || tId === "10"
                                  ? tc.nombre
                                  : tc.clave
                              )
                            }
                          >
                            <ListItemText
                              primary={
                                tId === "9" || tId === "10"
                                  ? tc.nombre
                                  : tc.descripcion
                              }
                              secondary={
                                tId === "9" || tId === "10" ? "" : tc.medio
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
      <Divider />
      <Typography variant="h5" component="h5" align="center" gutterBottom>
        Primeras Planas
      </Typography>
      <PrimerasPlanas classHeight={classes.pps} handleClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.modalPaper}>
          {url && !columnista && <img src={url} alt="pp" />}
          {!url && columnista && nota && (
            <React.Fragment>
              <Typography variant="h4" align="center" gutterBottom>
                {nota.titulo}
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                {nota.fecha}
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                {nota.genero}
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                {nota.nombreMedio}
              </Typography>
              <Typography variant="body1" align="justify" gutterBottom>
                {nota.texto}
              </Typography>
              <Typography variant="caption" align="center" gutterBottom>
                {nota.testigo}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </Container>
  );
};
