import React, { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";
import { Box, Grid, Typography } from "@material-ui/core";
import { getGraficas } from "../CygniRequests";
import { queryPrep } from "../Cygni";
import { VisContainer } from "../CygniContainers";
import { CygniLoadingFix } from "../CygniLoading";
import { CygniBarChart } from "../../common/CygniBarChart";
import { CygniLineChart } from "../../common/CygniLineChart";

export const Valoracion = React.memo(({ fuente, queries, current }) => {
  const [data, setData] = useState(null);
  const totKeyVal = [
    { positivo: "#4cdc8b" },
    { negativo: "#e94a36" },
    { neutro: "#9d9d9c" },
  ];
  const [png, ref] = useRechartToPng();

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "valoracion_total_notas.png");
  }, [png]);

  useEffect(() => {
    setData(null);
    if (fuente === "MEDIOS") {
      var tots = [];
      var fechas = {};

      var resMap = queries.map(async (el, i) => {
        if (
          current.id.includes(-1) ||
          (!current.id.includes(-1) && current.id.includes(el.qry_id))
        ) {
          var res = await getGraficas(
            queryPrep(el.query),
            current.fi.replace(/-/g, "_"),
            current.ff.replace(/-/g, "_"),
            "cygnimoviltendencia"
          );

          tots.push({
            name: el.qry_nombre,
            positivo: res.porTendencia.positiva,
            negativo: res.porTendencia.negativa,
            neutro: res.porTendencia.neutra,
          });

          fechas[el.qry_nombre] = [];

          Object.entries(res.porFecha).forEach(([k, v]) => {
            fechas[el.qry_nombre].push({
              name: v.name,
              positivo: v.pos,
              negativo: v.neg,
              neutro: v.neu,
            });
          });
        }
      });

      // Wait for all requests, and then setState
      Promise.all(resMap).then(() => {
        setData({ totales: tots, porFecha: fechas });
      });
    }
  }, [current, queries, fuente]);

  let legendRender = function (value) {
    return <span style={{ fontSize: "0.7rem" }}>{value}</span>;
  };

  return (
    <VisContainer
      title={
        fuente === "MEDIOS" ? "Valoración de Notas" : "Sentimiento de Tweets"
      }
      desc={
        fuente === "MEDIOS"
          ? "Valoración de notas total y por fecha."
          : "Sentimiento de tweets por día."
      }
      time={data ? true : false}
      downloadChart={downloadChart}
    >
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={12} sm={5}>
          {!data && <CygniLoadingFix />}
          {data && data.totales && (
            <CygniBarChart
              data={data.totales}
              keysAndColors={totKeyVal}
              legendRender={legendRender}
              iconSize={11}
              exportRef={ref}
              barLabel={{ position: "top", fontSize: 9 }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          {!data && <CygniLoadingFix />}
          {data &&
            data.porFecha &&
            Object.entries(data.porFecha).map((pf) => {
              var singleItem =
                !current.id.includes(-1) && current.id.length === 1;
              return (
                <Box key={"bx-" + pf[0]} height={singleItem ? "100%" : "33%"}>
                  <Typography
                    variant="caption"
                    component="h6"
                    color="textSecondary"
                  >
                    {pf[0]}
                  </Typography>
                  {!singleItem && (
                    <React.Fragment>
                      <Box width="33%" height={56} display="inline-block">
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ positivo: "#4cdc8b" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                      <Box width="33%" height={56} display="inline-block">
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ negativo: "#e94a36" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                      <Box width="33%" height={56} display="inline-block">
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ neutro: "#9d9d9c" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                  {singleItem && (
                    <React.Fragment>
                      <Box height={75}>
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ positivo: "#4cdc8b" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                      <Box height={75}>
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ negativo: "#e94a36" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                      <Box height={75}>
                        <CygniLineChart
                          data={pf[1]}
                          keysAndColors={[{ neutro: "#9d9d9c" }]}
                          legend={false}
                          dot={false}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              );
            })}
        </Grid>
      </Grid>
    </VisContainer>
  );
});
