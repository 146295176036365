import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
// import axios from "axios";
// import { Link } from "@reach/router";
import {
  Container,
  Typography,
  // Avatar,
  Grid,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  // List,
  // ListItem,
  // Divider,
  // ListItemText,
  // ListItemAvatar,
  // ListItemSecondaryAction,
  Paper,
  Fab,
  Menu,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import { MoreVert, GroupAdd } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { PieChart, Pie, Cell } from "recharts"; //Sector
import { TagCloud } from "react-tagcloud";
import {
  getSentimientoTot,
  getTemas,
  getTotalPubs,
} from "../common/CygniRequests";
import { UserContext } from "../contexts/user-context";
import { numberWithCommas } from "../common/Cygni";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 100,
    width: 100,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "70vh",
  },
  paperContent: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightBold,
  },
  fixedHeightNormal: {
    height: 280,
  },
  fixedHeightSmall: {
    height: 180,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    height: 300,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
}));

let renderLabel = function (entry) {
  var num = "";
  if (entry.value) {
    num = numberWithCommas(entry.value);
  }
  return entry.name + ":" + num;
};

const COLORS = ["#4287f5", "#fc3845", "#fad623"];

export const Candidatos = () => {
  // const theme = useTheme();
  const classes = useStyles();
  // const fixedHeightPaperNormal = clsx(classes.paper, classes.fixedHeightNormal);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCandidato, setSelectedCandidato] = useState(0);
  const [selPeriodo, setSelPeriodo] = useState("d");
  const [totMedios, setTotMedios] = useState(null);
  const [totRedes, setTotRedes] = useState(null);
  const [sentMedios, setSentMedios] = useState(null);
  const [sentRedes, setSentRedes] = useState(null);
  const [temasMedios, setTemasMedios] = useState(null);
  const [temasRedes, setTemasRedes] = useState(null);

  // userContext
  const userContext = useContext(UserContext);
  const tableros = userContext.tableros.map((tab) => tab.nombre);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (i) => {
    if (typeof i === "number") {
      setSelectedIndex(i);
      setSelectedCandidato(0);
    }
    setAnchorEl(null);
  };

  const pickCandidate = (i) => {
    setSelectedCandidato(i);
  };

  useEffect(() => {
    setTotMedios(null);
    setTotRedes(null);
    setSentMedios(null);
    setSentRedes(null);
    setTemasMedios(null);
    setTemasRedes(null);

    var nombre =
      userContext.tableros[selectedIndex].actores[selectedCandidato].nombre;
    var twitter =
      userContext.tableros[selectedIndex].actores[selectedCandidato].twitter;

    // total medios
    getTotalPubs(nombre, "m", selPeriodo)
      .then(function (response) {
        setTotMedios(response.cantidad);
      })
      .catch(function (error) {
        setTotMedios(null);
        console.error(error);
      });

    getSentimientoTot(nombre, "m", selPeriodo)
      .then(function (response) {
        setSentMedios(response);
      })
      .catch(function (error) {
        console.log(error);
        setSentMedios(null);
      });

    getTemas(nombre, "m", selPeriodo)
      .then(function (response) {
        setTemasMedios(response);
      })
      .catch(function (error) {
        console.log(error);
        setTemasMedios(null);
      });

    // total redes
    getTotalPubs(twitter, "t", selPeriodo)
      .then(function (response) {
        setTotRedes(response.cantidad);
      })
      .catch(function (error) {
        setTotMedios(null);
        console.error(error);
      });

    getSentimientoTot(twitter, "t", selPeriodo)
      .then(function (response) {
        setSentRedes(response);
      })
      .catch(function (error) {
        console.log(error);
        setSentRedes(null);
      });

    getTemas(twitter, "t", selPeriodo)
      .then(function (response) {
        setTemasRedes(response);
      })
      .catch(function (error) {
        console.log(error);
        setTemasRedes(null);
      });
  }, [
    selectedCandidato,
    setSelectedCandidato,
    selPeriodo,
    setSelPeriodo,
    selectedIndex,
    setSelectedIndex,
  ]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Tablero: {tableros[selectedIndex]}
      </Typography>
      {/* <Avatar className={classes.avatar} src="" style={{ margin: "auto" }} /> */}
      {/* <Typography
        variant="h5"
        component="h5"
        align="center"
        color="secondary"
        gutterBottom
      >
        {userContext.tableros[selectedIndex] &&
          userContext.tableros[selectedIndex].actores[selectedCandidato] &&
          '"' +
            userContext.tableros[selectedIndex].actores[selectedCandidato]
              .nombre +
            '"'}
      </Typography> */}
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="outlined primary button group"
        fullWidth
        style={{ marginBottom: "10px" }}
      >
        {userContext.tableros[selectedIndex] &&
          userContext.tableros[selectedIndex].actores.map((el, i) => (
            <Button
              key={i}
              variant={selectedCandidato === i ? "contained" : "outlined"}
              onClick={() => pickCandidate(i)}
            >
              {el.nombre}
            </Button>
          ))}
      </ButtonGroup>
      <ButtonGroup
        size="small"
        color="secondary"
        fullWidth
        style={{ marginBottom: "10px" }}
      >
        <Button
          variant={selPeriodo === "d" ? "contained" : "outlined"}
          onClick={() => setSelPeriodo("d")}
        >
          Díario (-1d)
        </Button>
        <Button
          variant={selPeriodo === "s" ? "contained" : "outlined"}
          onClick={() => setSelPeriodo("s")}
        >
          Semanal (-7d)
        </Button>
        <Button
          variant={selPeriodo === "q" ? "contained" : "outlined"}
          onClick={() => setSelPeriodo("q")}
        >
          Quincenal (-14d)
        </Button>
        <Button
          variant={selPeriodo === "m" ? "contained" : "outlined"}
          onClick={() => setSelPeriodo("m")}
        >
          Mensual (-30d)
        </Button>
      </ButtonGroup>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography component="h5" variant="h5" align="center" gutterBottom>
            Medios
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Card className={classes.fixedHeightNormal}>
                <CardHeader
                  action={
                    <IconButton aria-label="total notas settings">
                      <MoreVert />
                    </IconButton>
                  }
                  title="Total de notas"
                  // subheader="01-01-2020 a 03-01-2020"
                />
                <CardContent>
                  {!totMedios && (
                    <LinearProgress
                      color="secondary"
                      style={{ marginTop: "1rem" }}
                    />
                  )}
                  {totMedios && (
                    <Typography
                      variant="h4"
                      component="h4"
                      color="primary"
                      align="center"
                    >
                      {numberWithCommas(totMedios)} notas
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card className={classes.fixedHeightNormal}>
                <CardHeader
                  action={
                    <IconButton aria-label="sentimiento notas settings">
                      <MoreVert />
                    </IconButton>
                  }
                  title="Sentimiento notas"
                  // subheader="01-01-2020 a 03-01-2020"
                />
                <CardContent align="center">
                  {!sentMedios && (
                    <LinearProgress
                      color="secondary"
                      style={{ marginTop: "1rem" }}
                    />
                  )}
                  {sentMedios && (
                    <PieChart width={280} height={200}>
                      <Pie
                        nameKey="name"
                        dataKey="value"
                        data={sentMedios}
                        cx={145}
                        cy={110}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={10}
                        outerRadius={50}
                        fill="#8884d8"
                        paddingAngle={5}
                        isAnimationActive={false}
                        label={renderLabel}
                      >
                        {sentMedios.map((entry, index) => (
                          <Cell
                            key={index}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            component="h6"
            variant="h6"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Temas relevantes en medios
          </Typography>
          <Paper
            className={clsx(classes.paperContent, classes.fixedHeightNormal)}
          >
            {!temasMedios && (
              <LinearProgress color="secondary" style={{ marginTop: "1rem" }} />
            )}
            {temasMedios && (
              <TagCloud minSize={25} maxSize={55} tags={temasMedios} />
            )}
          </Paper>
          {/* <Typography
            component="h6"
            variant="h6"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Notas relevantes publicadas
          </Typography>
          <Paper className={classes.paperContent}>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Brunch this weekend?"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Ali Connors
                      </Typography>
                      {" — I'll be in your neighborhood doing errands this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Travis Howard" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Summer BBQ"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        to Scott, Alex, Jennifer
                      </Typography>
                      {" — Wish I could come, but I'm out of town this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Brunch this weekend?"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Ali Connors
                      </Typography>
                      {" — I'll be in your neighborhood doing errands this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Travis Howard" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Summer BBQ"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        to Scott, Alex, Jennifer
                      </Typography>
                      {" — Wish I could come, but I'm out of town this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h5" variant="h5" align="center" gutterBottom>
            Redes
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Card className={classes.fixedHeightNormal}>
                <CardHeader
                  action={
                    <IconButton aria-label="total tweets settings">
                      <MoreVert />
                    </IconButton>
                  }
                  title="Total de tweets"
                  // subheader="01-01-2020 a 03-01-2020"
                />
                <CardContent>
                  {!totRedes && (
                    <LinearProgress
                      color="secondary"
                      style={{ marginTop: "1rem" }}
                    />
                  )}
                  {totRedes && (
                    <Typography
                      variant="h4"
                      component="h4"
                      color="primary"
                      align="center"
                    >
                      {numberWithCommas(totRedes)} tweets
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card className={classes.fixedHeightNormal}>
                <CardHeader
                  action={
                    <IconButton aria-label="sentimiento tweets settings">
                      <MoreVert />
                    </IconButton>
                  }
                  title="Sentimiento tweets"
                  // subheader="01-01-2020 a 03-01-2020"
                />
                <CardContent>
                  {!sentRedes && (
                    <LinearProgress
                      color="secondary"
                      style={{ marginTop: "1rem" }}
                    />
                  )}
                  {sentRedes && (
                    <PieChart width={280} height={200}>
                      <Pie
                        nameKey="name"
                        dataKey="value"
                        data={sentRedes}
                        cx={145}
                        cy={110}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={10}
                        outerRadius={50}
                        fill="#8884d8"
                        paddingAngle={1}
                        isAnimationActive={false}
                        label={renderLabel}
                      >
                        {sentRedes.map((entry, index) => (
                          <Cell
                            key={index}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            component="h6"
            variant="h6"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Temas relevantes en redes
          </Typography>
          <Paper
            className={clsx(classes.paperContent, classes.fixedHeightNormal)}
          >
            {!temasRedes && (
              <LinearProgress color="secondary" style={{ marginTop: "1rem" }} />
            )}
            {temasRedes && (
              <TagCloud minSize={25} maxSize={55} tags={temasRedes} />
            )}
          </Paper>
          {/* <Typography
            component="h6"
            variant="h6"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Tweets relevantes publicados
          </Typography>
          <Paper className={classes.paperContent}>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Brunch this weekend?"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Ali Connors
                      </Typography>
                      {" — I'll be in your neighborhood doing errands this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Travis Howard" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Summer BBQ"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        to Scott, Alex, Jennifer
                      </Typography>
                      {" — Wish I could come, but I'm out of town this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Brunch this weekend?"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Ali Connors
                      </Typography>
                      {" — I'll be in your neighborhood doing errands this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Travis Howard" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Summer BBQ"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        to Scott, Alex, Jennifer
                      </Typography>
                      {" — Wish I could come, but I'm out of town this…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary="Oui Oui"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Sandra Adams
                      </Typography>
                      {" — Do you have Paris recommendations? Have you ever…"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="ver nota">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper> */}
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleClick}
      >
        <GroupAdd />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tableros &&
          tableros.map((el, i) => (
            <MenuItem key={i} onClick={() => handleClose(i)}>
              {el}
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
};
