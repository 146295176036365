import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { CygniRadialChart } from "../../common/CygniRadialChart";
import { CygniLoadingFix } from "../../common/CygniLoading";
import { VisContainer } from "../../common/CygniContainers";
import { queryPrep } from "../../common/Cygni";
import { getGraficas } from "../../common/CygniRequests";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

export const ParticipacionNotas = React.memo(({ queries, current }) => {
  const [data, setData] = useState(null);
  const [png, ref] = useRechartToPng();

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "particip.png");
  }, [png]);

  useEffect(() => {
    setData(null);

    var part = [];
    var resMap = queries.map(async (el, i) => {
      if (
        current.id.includes(-1) ||
        (!current.id.includes(-1) && current.id.includes(el.qry_id))
      ) {
        var qry = queryPrep(el.query);
        var f1 = current.fi + " 00:00:00.0";
        var f2 = current.ff + " 23:59:59.0";

        var res = await getGraficas(qry, f1, f2, "cygnimovilparticipacion");

        var partD = [];

        var rData = res.valoradas.participacion;
        if (rData !== null && rData !== undefined && rData !== "undefined") {
          Object.entries(rData).forEach(([k, v]) => {
            var tot = 0;
            Object.entries(v).forEach(([kv, vv]) => {
              tot += vv.cantidad;
            });
            var fill = "";
            if (k === "referente") fill = "#8884d8";
            if (k === "declarante") fill = "#83a6ed";
            if (k === "circunstancial") fill = "#8dd1e1";
            partD.push({ name: k, PART: tot, fill: fill });
          });
        }

        part.push({ nombre: el.qry_nombre, data: partD });
      }
    });

    // Wait for all requests, and then setState
    Promise.all(resMap).then(() => {
      setData(part);
    });
  }, [current, queries]);

  return (
    <VisContainer
      title="Participación Medios"
      desc="Participación de actor(es) en medios tradicionales."
      time={data ? true : false}
      showMenu={!(current.id.includes(-1) || current.id.length > 1)}
      downloadChart={downloadChart}
    >
      {!data && <CygniLoadingFix />}
      {data && !current.id.includes(-1) && current.id.length === 1 && (
        <CygniRadialChart data={data[0].data} dataKey="PART" exportRef={ref} />
      )}
      {data && (current.id.includes(-1) || current.id.length > 1) && (
        <Box>
          {data &&
            data.length > 0 &&
            data.map((el, i) => {
              return (
                <React.Fragment key={i}>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginLeft: "1rem" }}
                  >
                    {el.nombre}
                  </Typography>
                  {el.data &&
                    el.data.length > 0 &&
                    el.data.map((d, j) => {
                      return (
                        <Typography
                          key={j}
                          variant="subtitle1"
                          color="textSecondary"
                          align="right"
                          style={{ marginRight: "1rem" }}
                        >
                          {d.name + ": " + d.PART}
                        </Typography>
                      );
                    })}
                  <Divider style={{ margin: "1rem" }} />
                </React.Fragment>
              );
            })}
        </Box>
      )}
    </VisContainer>
  );
});
