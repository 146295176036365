import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

// const RADIAN = Math.PI / 180;
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#9c16f5",
  "#037801",
  "#d303fc",
];

export const CygniPieChart = React.memo(
  ({
    data,
    dataKey,
    nameKey = "name",
    cx = "50%",
    cy = "50%",
    labelLine = false,
    label = true,
    outerRadius = "65%",
    chartClick = null,
    legend = false,
    tooltip = true,
    exportRef = null,
  }) => {
    return (
      <ResponsiveContainer>
        <PieChart ref={exportRef}>
          {tooltip && <Tooltip />}
          {legend && <Legend />}
          <Pie
            data={data}
            dataKey={dataKey}
            nameKey={nameKey}
            cx={cx}
            cy={cy}
            labelLine={labelLine}
            label={label}
            outerRadius={outerRadius}
            onClick={chartClick}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
);
