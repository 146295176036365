import React, { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Graticule,
} from "react-simple-maps";
import { numberWithCommas, queryPrep } from "../common/Cygni";
import axios from "axios";

export const MapaResultados = ({
  idEdo,
  estado,
  tipo,
  anio,
  setTooltipContent,
  handleClick = null,
}) => {
  const scale = {
    4: 1600,
    5: 1150,
    13: 1800,
    15: 1800,
  };
  const centers = {
    4: [-90.5, 19.1],
    5: [-102, 27.2],
    13: [-99, 20.5],
    15: [-99.6, 19.3],
  };
  const zoom = {
    4: 4,
    5: 5,
    13: 8,
    15: 8,
  };
  const [resultados, setResultados] = useState(null);

  useEffect(() => {
    // console.log(idEdo, estado, tipo, anio);
    axios
      .get(
        "/api/electoral/fuerzas/" + estado + "/" + tipo + "/" + anio + "/mun"
      )
      .then(function (response) {
        setResultados(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setResultados({});
      });
  }, [anio]);

  return (
    <ComposableMap
      data-tip=""
      projection="geoAlbers"
      projectionConfig={{ scale: scale[idEdo] }}
      style={{ padding: "5px" }}
    >
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      <ZoomableGroup
        center={centers[idEdo]}
        zoom={zoom[idEdo]}
        minZoom={zoom[idEdo] - 1}
        maxZoom={14}
      >
        <Geographies geography={"/ENT_" + idEdo + ".json"}>
          {({ geographies }) =>
            geographies.map((geo) => {
              var color = "#93bfb4";
              var nom_num = queryPrep(geo.properties.NOM_MUN);
              var ganador_nom = "";
              var ganador_por = "";
              var sf_nom = "";
              var sf_por = "";
              var tf_nom = "";
              var tf_por = "";
              if (resultados && resultados.data) {
                var found = resultados.data.find((el) => {
                  if (estado === "edomex" || estado === "camp") {
                    if (
                      estado === "camp" &&
                      (anio === 2006 || anio === 2012) &&
                      el.ID_MUNICIPIO === 10 &&
                      geo.properties.CVE_MUN === 11
                    ) {
                      return true;
                    } else {
                      return el.ID_MUNICIPIO === geo.properties.CVE_MUN;
                    }
                  } else {
                    if (typeof el.municipio === "string") {
                      return queryPrep(el.municipio) === nom_num;
                    } else {
                      return el.municipio === geo.properties.CVE_MUN;
                    }
                  }
                });
                if (found !== undefined && found !== "undefined") {
                  const ganador = found.PF;
                  ganador_nom = ganador;
                  ganador_por = found.PF_P;
                  sf_nom = found.SF;
                  sf_por = found.SF_P;
                  tf_nom = found.TF;
                  tf_por = found.TF_P;
                  if (ganador === "PRI") {
                    color = "red";
                  } else if (ganador === "PAN") {
                    color = "blue";
                  } else if (ganador === "PRD") {
                    color = "yellow";
                  } else if (ganador === "MORENA") {
                    color = "#590215";
                  } else if (ganador === "PVEM") {
                    color = "green";
                  } else if (ganador === "PT") {
                    color = "#de1b55";
                  } else if (ganador === "PES") {
                    color = "#df71f5";
                  } else {
                    if (
                      ganador.includes("PRI") ||
                      ganador.includes("Compromiso por México") ||
                      ganador.includes("Unidos por Ti") ||
                      ganador.includes("Compromiso con el Estado de México") ||
                      ganador.includes(
                        "Comprometidos por el Estado de México"
                      ) ||
                      ganador.includes("Todos por México")
                    )
                      color = "red";
                    if (
                      ganador.includes("PAN") ||
                      ganador.includes("Por el Estado de México al Frente")
                    )
                      color = "blue";
                    if (
                      ganador.includes("PRD") ||
                      ganador.includes("Coalición Por el Bien de Todos") ||
                      ganador.includes("El Cambio Verdadero")
                    )
                      color = "yellow";
                    if (
                      ganador.includes("MORENA") ||
                      ganador.includes("Juntos Haremos Historia")
                    )
                      color = "#590215";
                  }

                  // PARA DATOS DE CARENCIAS AYUN 2018 EDOMEX
                  var d = {
                    1: { ganador: "MORENA-PT-ES", color: "#590215" },
                    2: { ganador: "MORENA-PT-ES", color: "#590215" },
                    3: { ganador: "PRI", color: "red" },
                    14: { ganador: "MORENA-PT-ES", color: "#590215" },
                    17: { ganador: "PAN-PRD-MC", color: "blue" },
                    25: { ganador: "MORENA-PT-ES", color: "#590215" },
                    27: { ganador: "PRI", color: "red" },
                    24: { ganador: "MORENA-PT-ES", color: "#590215" },
                    34: { ganador: "MORENA-PT-ES", color: "#590215" },
                    64: { ganador: "PRI", color: "red" },
                    36: { ganador: "PRI", color: "red" },
                    42: { ganador: "PAN-PRD-MC", color: "blue" },
                    45: { ganador: "MORENA-PT-ES", color: "#590215" },
                    47: { ganador: "PRI", color: "red" },
                    50: { ganador: "MORENA-PT-ES", color: "#590215" },
                    123: { ganador: "PRI", color: "red" },
                    62: { ganador: "MORENA-PT-ES", color: "#590215" },
                    69: { ganador: "MORENA-PT-ES", color: "#590215" },
                    73: { ganador: "PRI", color: "red" },
                    76: { ganador: "PRI", color: "red" },
                    82: { ganador: "MORENA-PT-ES", color: "#590215" },
                    88: { ganador: "MORENA-PT-ES", color: "#590215" },
                    89: { ganador: "PRI", color: "red" },
                    92: { ganador: "MORENA-PT-ES", color: "#590215" },
                    94: { ganador: "PRI", color: "red" },
                    101: { ganador: "MORENA-PT-ES", color: "#590215" },
                    102: { ganador: "PRI", color: "red" },
                    104: { ganador: "MORENA-PT-ES", color: "#590215" },
                    106: { ganador: "MORENA-PT-ES", color: "#590215" },
                    125: { ganador: "PRI", color: "red" },
                    110: { ganador: "PRI", color: "red" },
                    118: { ganador: "MORENA-PT-ES", color: "#590215" },
                  };
                  if (
                    [
                      1,
                      2,
                      3,
                      14,
                      17,
                      25,
                      27,
                      24,
                      34,
                      64,
                      36,
                      42,
                      45,
                      47,
                      50,
                      123,
                      62,
                      69,
                      73,
                      76,
                      82,
                      88,
                      89,
                      92,
                      94,
                      101,
                      102,
                      104,
                      106,
                      125,
                      110,
                      118,
                    ].includes(found.ID_MUNICIPIO) &&
                    estado === "edomex" &&
                    tipo === "ayun" &&
                    anio === 2018
                  ) {
                    ganador_nom = d[found.ID_MUNICIPIO].ganador;
                    ganador_por = "";
                    sf_nom = "";
                    sf_por = "";
                    tf_nom = "";
                    tf_por = "";
                    color = d[found.ID_MUNICIPIO].color;
                  }
                }
              }
              // console.log(geo.properties); // geo.properties.NOM_MUN
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={color}
                  stroke="#454545"
                  strokeWidth={0.2}
                  onClick={handleClick(geo.properties)}
                  onMouseEnter={() => {
                    // const { NAME, POP_EST } = geo.properties;
                    // setTooltipContent(`${NAME} — ${rounded(POP_EST)}`);
                    // geo.properties.CVE_MUN
                    var d2 = {
                      1: {
                        ln: 48413,
                        votos: 10208,
                        benef: 30843,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      2: {
                        ln: 87248,
                        votos: 25503,
                        benef: 21907,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      3: {
                        ln: 34621,
                        votos: 10841,
                        benef: 23679,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      14: {
                        ln: 79259,
                        votos: 26203,
                        benef: 32786,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      17: {
                        ln: 6194,
                        votos: 2799,
                        benef: 4573,
                        carencias:
                          "Carencia por calidad y espacios de la vivienda",
                      },
                      25: {
                        ln: 260564,
                        votos: 80202,
                        benef: 67848,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      27: {
                        ln: 8446,
                        votos: 2185,
                        benef: 3476,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      24: {
                        ln: 98844,
                        votos: 23555,
                        benef: 24457,
                        carencias: "Vulnerables por ingreso",
                      },
                      34: {
                        ln: 7384,
                        votos: 1855,
                        benef: 6053,
                        carencias:
                          "Carencia por calidad y espacios de la vivienda",
                      },
                      64: {
                        ln: 27163,
                        votos: 4849,
                        benef: 17258,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      36: {
                        ln: 32241,
                        votos: 5705,
                        benef: 15222,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      42: {
                        ln: 113969,
                        votos: 24571,
                        benef: 48826,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      45: {
                        ln: 69516,
                        votos: 13506,
                        benef: 33928,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      47: {
                        ln: 54664,
                        votos: 13241,
                        benef: 33639,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      50: {
                        ln: 18476,
                        votos: 7780,
                        benef: 10348,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      123: {
                        ln: 23022,
                        votos: 9874,
                        benef: 17433,
                        carencias: "Rezago educativo",
                      },
                      62: {
                        ln: 51249,
                        votos: 10228,
                        benef: 15906,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      69: {
                        ln: 4005,
                        votos: 1266,
                        benef: 2447,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      73: {
                        ln: 18660,
                        votos: 3451,
                        benef: 7397,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      76: {
                        ln: 68262,
                        votos: 16217,
                        benef: 21495,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      82: {
                        ln: 59169,
                        votos: 13993,
                        benef: 33824,
                        carencias: "Rezago educativo",
                      },
                      88: {
                        ln: 73351,
                        votos: 11073,
                        benef: 31535,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      89: {
                        ln: 8945,
                        votos: 2801,
                        benef: 5326,
                        carencias:
                          "Carencia por calidad y espacios de la vivienda",
                      },
                      92: {
                        ln: 46755,
                        votos: 9891,
                        benef: 16380,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      94: {
                        ln: 14741,
                        votos: 3796,
                        benef: 7291,
                        carencias:
                          "Carencia por calidad y espacios de la vivienda",
                      },
                      101: {
                        ln: 57586,
                        votos: 11798,
                        benef: 24162,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      102: {
                        ln: 13513,
                        votos: 4489,
                        benef: 9144,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      104: {
                        ln: 564182,
                        votos: 166462,
                        benef: 105792,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      106: {
                        ln: 675344,
                        votos: 180560,
                        benef: 143480,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      125: {
                        ln: 9278,
                        votos: 1370,
                        benef: 4191,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                      110: {
                        ln: 55056,
                        votos: 10074,
                        benef: 23321,
                        carencias: "Rezago educativo",
                      },
                      118: {
                        ln: 140307,
                        votos: 31572,
                        benef: 46736,
                        carencias: "Carencia por acceso a la alimentación",
                      },
                    };
                    if (
                      [
                        1,
                        2,
                        3,
                        14,
                        17,
                        25,
                        27,
                        24,
                        34,
                        64,
                        36,
                        42,
                        45,
                        47,
                        50,
                        123,
                        62,
                        69,
                        73,
                        76,
                        82,
                        88,
                        89,
                        92,
                        94,
                        101,
                        102,
                        104,
                        106,
                        125,
                        110,
                        118,
                      ].includes(geo.properties.CVE_MUN) &&
                      estado === "edomex" &&
                      tipo === "ayun" &&
                      anio === 2018
                    ) {
                      setTooltipContent(
                        `Municipio: ${
                          geo.properties.NOM_MUN
                        }<br />LN: ${numberWithCommas(
                          d2[geo.properties.CVE_MUN].ln
                        )} <br />VOTOS: ${numberWithCommas(
                          d2[geo.properties.CVE_MUN].votos
                        )}<br />Beneficiarios: ${numberWithCommas(
                          d2[geo.properties.CVE_MUN].benef
                        )} <br />CARENCIAS: ${
                          d2[geo.properties.CVE_MUN].carencias
                        }`
                      );
                    } else {
                      setTooltipContent(
                        `Municipio: ${geo.properties.NOM_MUN}<br />1°: ${ganador_nom} (${ganador_por}%)<br />2°: ${sf_nom} (${sf_por}%)<br />3°: ${tf_nom} (${tf_por}%)`
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    // default: {
                    //   fill: "#D6D6DA",
                    //   outline: "none",
                    // },
                    hover: {
                      fill: "#ff5900",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#ff5900",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
};
