import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Paper,
  Modal,
  Typography,
  IconButton,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { UserContext } from "../contexts/user-context";
import { getQueryTab, setVisUsr } from "../common/CygniRequests";
import { DashboardContainer } from "../common/CygniContainers";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeighSmall: {
    height: 80,
  },
  fixedHeightNormal: {
    height: 280,
  },
  boldText: {
    fontWeight: "bold",
  },
  modalPaper: {
    position: "absolute",
    width: "55%",
    // height: "55%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
  listQueries: {
    width: "100%",
    maxWidth: 360,
    maxHeight: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Dashboard = () => {
  const classes = useStyles();

  // userContext
  const userContext = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [vis, setVis] = useState(null);
  const [tableros, setTableros] = useState(null);
  const [nombre, setNombre] = useState("");
  const [newTab, setNewTab] = useState(-1);

  useEffect(() => {
    // Carga tableros
    getQueryTab("TABLERO", userContext.id)
      .then(function (response) {
        setTableros(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
    loadUseVis();
  }, []);

  const loadUseVis = () => {
    // Cargar vis
    getQueryTab("USR-VIS", userContext.id)
      .then(function (response) {
        setVis(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeTab = (event) => {
    var f = tableros.find((tab) => tab.id == event.target.value);
    setNombre(f.nombre);
    setNewTab(event.target.value);
  };

  const saveVis = () => {
    if (!nombre || nombre === "" || !newTab || newTab === -1) {
      return;
    }
    setVisUsr(userContext.id, nombre, newTab)
      .then(function (response) {
        //console.log(response);// response.data.insertId // affectedRows
        handleClose();
        setNombre("");
        setNewTab(-1);
        loadUseVis();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography variant="h3" component="h3" align="center" color="primary">
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        {vis &&
          Object.entries(vis).map(([k, v]) => {
            return (
              <Grid item xs={12} md={4} key={k}>
                <DashboardContainer
                  title={v.nombre}
                  tab={v.nom_tab}
                  queries={v.queries}
                  vId={v.vis_id}
                />
              </Grid>
            );
          })}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{ height: "240px" }}
          >
            <Typography
              variant="h5"
              color="secondary"
              align="center"
              gutterBottom
            >
              Agregar visualización
            </Typography>
            <IconButton aria-label="new" color="secondary" onClick={handleOpen}>
              <Add fontSize="large" />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      {(!vis || vis.length < 1) && (
        <Typography
          variant="subtitle1"
          color="primary"
          align="center"
          style={{ marginTop: "1rem" }}
        >
          No tiene visualizaciones creadas.
        </Typography>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.modalPaper}>
          {/* {url && <img src={url} alt="pp" />} */}
          <Typography variant="h5" color="primary" gutterBottom>
            Nueva visualización
          </Typography>
          <TextField
            id="nombre"
            name="nombre"
            label="Nombre de la visualización"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event) => setNombre(event.target.value)}
            value={nombre}
          />
          <Typography
            variant="subtitle1"
            color="primary"
            gutterBottom
            style={{ marginTop: "1rem" }}
          >
            Seleccione un tablero
          </Typography>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={newTab}
            onChange={onChangeTab}
            style={{ maxHeight: "300px" }}
          >
            {tableros &&
              tableros.map((value, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    value={value.id + ""}
                    control={<Radio />}
                    label={value.nombre}
                  />
                );
              })}
          </RadioGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={saveVis}
            style={{ marginTop: "1rem" }}
          >
            Guardar
          </Button>
        </div>
      </Modal>
    </Container>
  );
};
