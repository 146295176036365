import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

export const CygniLineChart = React.memo(
  ({
    data,
    keysAndColors,
    type = "monotone",
    dataKey = "name",
    legend = true,
    legendRender = null,
    iconSize = 12,
    dot = true,
    chartClick = null,
    exportRef = null,
  }) => {
    return (
      <ResponsiveContainer>
        <LineChart data={data} onClick={chartClick} ref={exportRef}>
          <XAxis
            dataKey={dataKey}
            height={40}
            tick={{
              fontSize: 9.5,
              scaleToFit: true,
              textAnchor: "end",
              angle: -35,
            }}
          />
          <YAxis
            tick={{
              fontSize: 9.5,
              scaleToFit: true,
              textAnchor: "end",
              angle: -35,
            }}
          />
          <Tooltip />
          {legend && <Legend iconSize={iconSize} formatter={legendRender} />}
          <CartesianGrid stroke="#f5f5f5" />
          {keysAndColors.map((entry) => {
            const kvs = Object.entries(entry);
            return (
              <Line
                key={kvs[0][0]}
                type={type}
                dataKey={kvs[0][0]}
                stroke={kvs[0][1].split("|")[0]}
                dot={dot}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  }
);
