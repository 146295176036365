import axios from "axios";
import { getFormattedDate } from "./Cygni";

const dysordaUrl = "https://dysorda.com:9090/";
const intelicastUrl = "https://intelicast.net/Cygni/";
const cygniGin = "http://oblek.com.mx:8282/";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

// POR PROBAR
export function getAlcance(
  qry,
  f1,
  f2,
  notas = null,
  tipomedio = null,
  porTipoTipo = null,
  nombreMedio = null,
  ambito = null,
  estados = null,
  conductor = null,
  genero = null,
  tendencia = null,
  participacion = null,
  posicionamiento = null
) {
  var params = {
    busqueda1: {
      query: qry,
      fechainicio: f1,
      fechaFinal: f2,
    },
  };

  if (notas) params.busqueda1.notas = notas;
  if (tipomedio) params.busqueda1.tipo = tipomedio;
  if (porTipoTipo) params.busqueda1.porTipoTipo = porTipoTipo;
  if (nombreMedio) params.busqueda1.nombreMedio = nombreMedio;
  if (ambito) params.busqueda1.ambito = ambito;
  if (estados) params.busqueda1.cobertura = estados;
  if (conductor) params.busqueda1.conductor = conductor;
  if (genero) params.busqueda1.genero = genero;
  if (tendencia) params.busqueda1.tendencia = tendencia;
  if (participacion) params.busqueda1.participacion = participacion;
  if (posicionamiento) params.busqueda1.posicionamiento = posicionamiento;

  console.log(params);

  return new Promise((resolve, reject) => {
    axios
      .post(dysordaUrl + "alcance", params, config)
      .then(function (response) {
        console.log(response);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

// BACKEND
export function getLogin(user, password) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/usuario/login", { user: user, password: password }, config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getQueryTab(tipo, idUsr, idTab) {
  var params = tipo + "/" + idUsr;
  if ((tipo === "TAB-QRY" || tipo === "USR-VIS") && idTab) {
    params += "/" + idTab;
  }
  return new Promise((resolve, reject) => {
    axios
      .get("/api/usuario/busqueda/" + params, config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function setQryTab(tipo, datos) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/usuario/busqueda", { tipo: tipo, datos: datos }, config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function setBusqUsr(usrId, datos) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/api/usuario/busqueda_usuario",
        { usrId: usrId, datos: datos },
        config
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function setVisUsr(usrId, nombre, tabId) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/api/usuario/vis_usuario",
        { usrId: usrId, nombre: nombre, tabId: tabId },
        config
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getDelitosMun(edo, mun) {
  var fmun = mun.toString();
  while (fmun.length < 3) {
    fmun = "0" + fmun;
  }

  fmun = edo + fmun;

  return new Promise((resolve, reject) => {
    axios
      .get("/api/indicadores/delitos_mun/" + edo + "/" + fmun, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getDelitosGroup(edo, mun) {
  var fmun = mun.toString();
  while (fmun.length < 3) {
    fmun = "0" + fmun;
  }

  fmun = edo + fmun;

  return new Promise((resolve, reject) => {
    axios
      .get("/api/indicadores/delitos_group/" + edo + "/" + fmun, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getIndicadores(ind, edo, mun) {
  var fedo = edo.toString();
  var fmun = mun.toString();
  while (fedo.length < 2) {
    fedo = "0" + fedo;
  }
  while (fmun.length < 3) {
    fmun = "0" + fmun;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(
        "/api/indicadores/indicadores_gral/" + ind + "/" + fedo + "/" + fmun,
        config
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getDatosPriEdo(tipo, mod, edo, mun = null) {
  return new Promise((resolve, reject) => {
    // var params = edo + "/";
    // if (mun) params += "m/" + mun + "/";
    var params = tipo + "/" + mod + "/" + edo;
    if (tipo === "MUN") params += "/" + mun;
    axios
      // .get(djangoUrl + params + mod)
      .get(`/api/indicadores/dja_estadisticas/` + params)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

// INTELICAST
export function getTopPrimerasPlanas() {
  return new Promise(async (resolve, reject) => {
    // var estados = await axios.get(intelicastUrl + "estados.php", config); // 9 CDMX
    // var medios = await axios.get(intelicastUrl + "medios.php", config); // 1 - periodicos, 2 - revistas, etc
    // var cobertura = await axios.get(intelicastUrl + "cob_medios.php?medio=1&estado=9&clasificacion=AA",config);
    // cobertura = await axios.get(intelicastUrl + "cob_medios.php?medio=1&estado=12&clasificacion=AA",config);
    // AAA
    // 307 - Milenio Diario, 59 - El Economista, 60 - El Financiero, 61 - El Heraldo de México, 89 - Excélsior
    // 72 - El Universal, 199 - Reforma, 127 - La Crónica, 233 - El Sol de Toluca
    // AA
    // 859 - Metro, 662 - El Universal Gráfico, 134 - La Prensa, 174 - Ovaciones, 8032 - La Razón de México
    // 279 - Unomásuno, 13507 - Reporte Índigo Cínco Días, 11604 - 24 Horas, 1490 - Diario Imagen / Edomex /,
    // 12989 - El Gráfico Toluca
    const fecha = getFormattedDate(new Date(), "/", "dmy");
    axios
      .get(
        intelicastUrl +
          "primeras_planas.php?clave=307,59,60,61,89,72,199,127,233&fechaInicial=" +
          fecha +
          "&fechaFinal=" +
          fecha
      )
      .then(async function (response) {
        var data_TA = response.data;
        var data_DA = await axios.get(
          intelicastUrl +
            "primeras_planas.php?clave=859,662,134,174,8032,279,13507,11604,1490,12989&fechaInicial=" +
            fecha +
            "&fechaFinal=" +
            fecha
        );
        data_DA = data_DA.data;
        if (
          data_TA !== null &&
          data_TA !== undefined &&
          data_TA !== "undefined" &&
          data_TA !== ""
        ) {
          data_TA.forEach((element) => {
            switch (element.clave) {
              case "307":
                element.nombre = "Milenio Diario";
                element.clasif = "AAA";
                break;
              case "59":
                element.nombre = "El Economista";
                element.clasif = "AAA";
                break;
              case "60":
                element.nombre = "El Financiero";
                element.clasif = "AAA";
                break;
              case "61":
                element.nombre = "El Heraldo de México";
                element.clasif = "AAA";
                break;
              case "89":
                element.nombre = "Excélsior";
                element.clasif = "AAA";
                break;
              case "72":
                element.nombre = "El Universal";
                element.clasif = "AAA";
                break;
              case "199":
                element.nombre = "Reforma";
                element.clasif = "AAA";
                break;
              case "127":
                element.nombre = "La Crónica";
                element.clasif = "AAA";
                break;
              case "233":
                element.nombre = "El Sol de Toluca";
                element.clasif = "AAA";
                break;
              default:
                break;
            }
          });
        }
        if (
          data_DA !== null &&
          data_DA !== undefined &&
          data_DA !== "undefined" &&
          data_TA !== ""
        ) {
          data_DA.forEach((element) => {
            switch (element.clave) {
              case "859":
                element.nombre = "Metro";
                element.clasif = "AA";
                break;
              case "662":
                element.nombre = "El Universal Gráfico";
                element.clasif = "AA";
                break;
              case "134":
                element.nombre = "La Prensa";
                element.clasif = "AA";
                break;
              case "174":
                element.nombre = "Ovaciones";
                element.clasif = "AA";
                break;
              case "8032":
                element.nombre = "La Razón de México";
                element.clasif = "AA";
                break;
              case "279":
                element.nombre = "Unomásuno";
                element.clasif = "AA";
                break;
              case "13507":
                element.nombre = "Reporte Índigo Cínco Días";
                element.clasif = "AA";
                break;
              case "11604":
                element.nombre = "24 Horas";
                element.clasif = "AA";
                break;
              case "1490":
                element.nombre = "Diario Imagen / Edomex /";
                element.clasif = "AA";
                break;
              case "12989":
                element.nombre = "El Gráfico Toluca";
                element.clasif = "AA";
                break;
              default:
                break;
            }
          });
        }

        resolve([...data_TA, ...data_DA]);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getColumnistas(tipo) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.get("/api/medios/columnistas/" + tipo);
    resolve(result.data);
  });
}

export function getColumnistaNotas(tipo, clave) {
  var f1 = getFormattedDate(new Date(), "-", "dmy");
  var f2 = getFormattedDate(new Date(), "-", "dmy");
  var claveNombre = "";
  if (tipo === "9" || tipo === "10") {
    claveNombre = "&nombre='" + clave + "'";
  } else {
    claveNombre = "&clave=" + clave;
  }
  return new Promise(async (resolve, reject) => {
    var result = await axios.get(
      "/api/medios/columnistas/notas/" +
        tipo +
        claveNombre +
        "/" +
        f1 +
        "/" +
        f2
    );
    resolve(result.data);
  });
}

// CYGNI TOMCAT

export function getCategorias(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/categorias", {
      qry: qry,
      f1: f1,
      f2: f2,
    });
    resolve(result.data);
  });
}

export function getNotasEdos(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/notas_edos", {
      qry: qry,
      f1: f1,
      f2: f2,
    });
    resolve(result.data);
  });
}

export function getHistograma(qry, f1, f2, interval) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/histograma", {
      qry: qry,
      f1: f1,
      f2: f2,
      interval: interval,
    });
    resolve(result.data);
  });
}

export function getGraficas(qry, f1, f2, tipo) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/graficas", {
      qry: qry,
      f1: f1,
      f2: f2,
      tipo: tipo,
    });
    resolve(result.data);
  });
}

export function getMunsEnNotas(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/muns_en_notas", {
      busqueda: qry,
      f1: f1,
      f2: f2,
    });
    resolve(result.data);
  });
}

export function getNotasEnMuns(f1, f2, edo, mun) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/notas_en_muns", {
      f1: f1,
      f2: f2,
      estadoclave: edo,
      munclave: mun,
    });
    resolve(result.data);
  });
}

// NOT IN BACKEND
export function getNotasParaExportar(
  qry,
  f1,
  f2,
  notas = null,
  tipomedio = null,
  porTipoTipo = null,
  nombreMedio = null,
  ambito = null,
  estados = null,
  conductor = null,
  genero = null,
  tendencia = null,
  participacion = null,
  posicionamiento = null
) {
  var params = {
    busqueda1: {
      query: qry,
      fechainicio: f1,
      fechaFinal: f2,
    },
  };

  if (notas) params.busqueda1.notas = notas;
  if (tipomedio) params.busqueda1.tipo = tipomedio;
  if (porTipoTipo) params.busqueda1.porTipoTipo = porTipoTipo;
  if (nombreMedio) params.busqueda1.nombreMedio = nombreMedio;
  if (ambito) params.busqueda1.ambito = ambito;
  if (estados) params.busqueda1.cobertura = estados;
  if (conductor) params.busqueda1.conductor = conductor;
  if (genero) params.busqueda1.genero = genero;
  if (tendencia) params.busqueda1.tendencia = tendencia;
  if (participacion) params.busqueda1.participacion = participacion;
  if (posicionamiento) params.busqueda1.posicionamiento = posicionamiento;

  return new Promise((resolve, reject) => {
    axios
      .post(dysordaUrl + "temas", params, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getNotas(
  qry,
  f1,
  f2,
  page = "1",
  tipomedio = "null",
  porTipoTipo = "null",
  nombreMedio = "null",
  ambito = "null",
  estados = "null",
  conductor = "null",
  genero = "null",
  tendencia = "null",
  participacion = "null",
  posicionamiento = "null"
) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.post("/api/medios/notas", {
      qry: qry,
      f1: f1,
      f2: f2,
      page: page,
      tipomedio: tipomedio,
      porTipoTipo: porTipoTipo,
      nombreMedio: nombreMedio,
      ambito: ambito,
      estados: estados,
      conductor: conductor,
      genero: genero,
      tendencia: tendencia,
      participacion: participacion,
      posicionamiento: posicionamiento,
    });
    resolve(result.data);
  });
}

// CYGNI DYSORDA

export function getTotalPubs(nombre, fuente, periodo) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.get(
      `/api/redes/pub_tot/${nombre}/${fuente}/${periodo}`
    );
    resolve(result.data);
  });
}

export function getSentimientoTot(nombre, fuente, periodo) {
  return new Promise(async (resolve, reject) => {
    var result = await axios.get(
      `/api/redes/sent_tot/${nombre}/${fuente}/${periodo}`
    );
    resolve(result.data);
  });
}

export function getTemas(nombre, fuente, periodo) {
  return new Promise(async (resolve, reject) => {
    var pubs = await axios.post("/api/redes/word_cloud", {
      nombre: nombre,
      fuente: fuente,
      periodo: periodo,
      sentimiento: "todos",
      tipo: "p",
    });
    resolve(pubs.data);
  });
}

export function getSentRedes(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var res = await axios.get(`/api/redes/dys_sent/${qry}/${f1}/${f2}`);
    resolve(res.data);
  });
}

export function getMencTotRedes(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var res = await axios.get(`/api/redes/dys_menc_tot/${qry}/${f1}/${f2}`);
    resolve(res.data);
  });
}

export function getFuentesRedes(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var res = await axios.get(`/api/redes/dys_fuentes/${qry}/${f1}/${f2}`);
    resolve(res.data);
  });
}

export function getInfluenRedes(qry, f1, f2) {
  return new Promise(async (resolve, reject) => {
    var res = await axios.get(`/api/redes/dys_influen/${qry}/${f1}/${f2}`);
    resolve(res.data);
  });
}

// OTROS
export function getEstados() {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/indicadores/estados", config)
      .then(function (response) {
        var res = response;
        // delete res.data[1];
        res.data[10] = { cve: 9, valor: "Ciudad de México" };

        resolve(res.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getMunicipios(edo) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/indicadores/municipios/" + edo, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getCygniData(tipo, edo, mun, cond) {
  var condition = "/";

  if (cond && cond !== "") {
    condition = cond;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/data/${tipo}/${edo}/${mun}${condition}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getFuncionarios(id = "") {
  var param = "";

  if (id !== "") {
    param = id;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/dem/ciud/resp/inst/${param}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getImportPromovidos(estructura) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/demo/promovidos/${estructura.replace(/_/g, " ")}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getImportEncuestas(tipo) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/demo/encuestas/${tipo}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getEdosGeos() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/edos_geo`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getMunsGeos(edo) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/muns_geo/${edo}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getIndTotDel(edo, mun) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/ind_tot_del/${edo}/${mun}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getIndTotEduEmp(tipo, edo) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/ind_tot_edu_emp/${tipo}/${edo}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getIndTotViv(tipo, edo, mun) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/ind_tot_viv/${tipo}/${edo}/${mun}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getCovidTot(tipo, edo, mun) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/covid/${tipo}/${edo}/${mun}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getCovidDefTot(tipo, edo, mun) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/covid_def/${tipo}/${edo}/${mun}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getTopDelitosTot(edo = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/indicadores/top_delitos_tot/${edo}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function qryMediosGin(filters) {
  var params = {
    query: filters.qry,
    f1: filters.f1,
    f2: filters.f2,
    exact: true,
    csv: true,
  };

  if (filters.or && filters.or !== "") params["query-or"] = filters.or;
  if (filters.and && filters.and !== "") params["query-and"] = filters.and;
  if (filters.not && filters.not !== "") params["query-not"] = filters.not;
  if (
    filters.sep &&
    filters.sep !== "" &&
    (filters.sep === ";" || filters.sep === "tab")
  )
    params["sep"] = filters.sep;

  return new Promise((resolve, reject) => {
    axios
      .post(cygniGin + "query", params, config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getEdomexPri2021(pilar) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/edomex/pri/2021/${pilar}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getUtilDelitos(ind, edo, mun, tipo = "", anio = "", top = "") {
  var url = "/api/indicadores/delitos/" + ind;

  var fmun = mun.toString();
  if (mun !== "999") {
    while (fmun.length < 3) {
      fmun = "0" + fmun;
    }

    fmun = edo + fmun;
  }

  if (ind === "DAI" || ind === "fem")
    url += "/" + tipo + "/" + edo + "/" + fmun;
  if (ind === "mas_com") url += "/" + anio + "/" + edo + "/" + fmun + "/" + top;
  if (ind === "mas_cre") url += "/" + edo + "/" + fmun + "/" + top;

  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getUtilConeval(tipo, edo, mun) {
  var url = "/api/indicadores/coneval/" + tipo + "/" + edo + "/";

  var fmun = mun.toString();
  if (mun !== "999") {
    while (fmun.length < 3) {
      fmun = "0" + fmun;
    }

    fmun = edo + fmun;
  }

  url += fmun;

  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getUtilCenso(edo, mun, cols) {
  var url = "/api/indicadores/censo/2020";

  var fcols =
    "NOM_ENT as Entidad, ENTIDAD AS idEnt, NOM_MUN AS Municipio, MUN AS idMun, POBTOT AS Poblacion, ";

  if (cols === "1") {
    fcols +=
      "PSINDER AS SinSalud, PDER_SS AS ConSalud, PDER_IMSS AS IMSS, PDER_ISTE AS ISSSTE, PDER_ISTEE AS ISSSTE_Estatal, PAFIL_PDOM AS PemexDefMarina, PDER_SEGP AS Bienestar, PDER_IMSSB AS ImssBienestar, PAFIL_IPRIV AS Privada, PAFIL_OTRAI AS Otra";
  } else if (cols === "2") {
    fcols +=
      "PEA AS PobEconomAct, PE_INAC AS PobEconomInac, POCUPADA AS PobOcupada, PDESOCUP AS PobDesocupada";
  }

  return new Promise((resolve, reject) => {
    axios
      .post(url, { edo: edo, mun: mun, cols: fcols }, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getEdomexTemas(tipo, edo, mun) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/edomex_temas/${tipo}/${edo}/${mun}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}

export function getDurangoObras(top, tipo) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/cygni/durango_obras/${top}/${tipo}`, config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
}
