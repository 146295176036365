import React from "react";
import {
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
  Tooltip,
  Legend,
} from "recharts";

export const CygniRadialChart = ({ data, dataKey = "A", exportRef = null }) => {
  return (
    <ResponsiveContainer>
      <RadialBarChart
        innerRadius="40%"
        outerRadius="80%"
        data={data}
        startAngle={180}
        endAngle={0}
        ref={exportRef}
      >
        <RadialBar
          minAngle={15}
          label={{ fill: "#666", position: "insideStart" }}
          background
          clockWise={true}
          dataKey={dataKey}
        />
        <Legend
          iconSize={8}
          // width={120}
          // height={140}
          layout="vertical"
          verticalAlign="bottom"
          // layout="vertical"
          // verticalAlign="middle"
          align="center"
        />
        <Tooltip />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};
