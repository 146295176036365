import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Typography } from "@material-ui/core";
import { CygniLineChart } from "../../common/CygniLineChart";
import { CygniLoading } from "../../common/CygniLoading";
import { getSentRedes } from "../../common/CygniRequests";

function getTwitterAccount(query) {
  var account = "";
  switch (query.toLowerCase()) {
    case "amlo":
    case "peje":
    case "presidente":
    case "lopez obrador":
      account = "to:lopezobrador_ OR from:lopezobrador_";
      break;
    case "calderon":
    case "felipe calderon":
      account = "to:FelipeCalderon OR from:FelipeCalderon";
      break;
    case "margarita zavala":
      account = "to:Mzavalagc OR from:Mzavalagc";
      break;
    case "monreal":
    case "ricardo monreal":
      account = "to:RicardoMoneralA OR from:RicardoMoneralA";
      break;
    default:
      if (query.includes(",")) {
        account = '"' + query.split(",")[0] + '"';
      } else if (query.includes(".")) {
        account = '"' + query.split(".")[0] + '"';
      } else if (query.includes("!")) {
        account = '"' + query.split(".!")[0] + '"';
      } else {
        account = '"' + query + '"';
      }
      break;
  }
  return account;
}

export const RedesMencionesTendencia = React.memo(
  ({ query, fecha1, fecha2 }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      setData(null);

      var qry = getTwitterAccount(query);
      var f1 =
        fecha1.split("-")[2] +
        "-" +
        fecha1.split("-")[1] +
        "-" +
        fecha1.split("-")[0];
      var f2 =
        fecha2.split("-")[2] +
        "-" +
        fecha2.split("-")[1] +
        "-" +
        fecha2.split("-")[0];

      getSentRedes(qry, f1, f2)
        .then(function (res) {
          var sent = [];
          var rData = res.linea;
          if (rData !== null && rData !== undefined && rData !== "undefined") {
            Object.entries(rData).forEach(([k, v]) => {
              Object.entries(v).forEach(([kv, vv]) => {
                var value = sent.find((e) => e.name === kv.split("T")[0]);
                if (value === undefined) {
                  value = { name: kv.split("T")[0], pos: 0, neg: 0, neu: 0 };
                  if (k === "positivos") value.pos = vv;
                  if (k === "negativos") value.neg = vv;
                  if (k === "neutros") value.neu = vv;
                  sent.push(value);
                } else {
                  if (k === "positivos") value.pos = vv;
                  if (k === "negativos") value.neg = vv;
                  if (k === "neutros") value.neu = vv;
                }
              });
            });
          }

          setData(sent);
        })
        .catch(function (error) {
          console.error(error);
        });
    }, [query, fecha1, fecha2]);

    return (
      <PaperContainer
        title="Menciones por Tendencia"
        exportData={data}
        exportName="redes_menciones_tend"
      >
        {!data && <CygniLoading />}
        {data && data.length === 0 && (
          <Typography
            component="h5"
            variant="h5"
            align="center"
            style={{ marginTop: "10px" }}
          >
            No hay datos disponibles.
          </Typography>
        )}
        {data && data.length > 0 && (
          <CygniLineChart
            data={data}
            keysAndColors={[
              { pos: "#457ef7" },
              { neg: "#f74b45" },
              { neu: "#f7d045" },
            ]}
          />
        )}
      </PaperContainer>
    );
  }
);
