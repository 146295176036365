import React, { useState } from "react";
import clsx from "clsx";
import {
  Grid,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Box,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { PaperSubtitle, PaperTitle } from "./CygniTitles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(1),
    display: "flex",
    // overflow: "auto",
    flexDirection: "column",
  },
  fixedHeightNormal: {
    height: 280,
  },
  containerHeader: {
    backgroundColor: "#dadde0",
  },
  containerBody: {
    height: "100%",
    overflow: "auto",
  },
}));

export const PaperContainer = React.memo(
  ({
    children,
    title,
    subtitle = null,
    marginBottom = "3px",
    exportar = true,
    exportData = null,
    exportName = null,
    exportarNotas = null,
    customMenuItem = null,
    customMenuClick = null,
    downloadChart = null,
    toggleChart = null,
  }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const classes = useStyles();
    const fixedHeightPaperNormal = clsx(
      classes.paper,
      classes.fixedHeightNormal
    );
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const exportToCsv = () => {
      if (exportData !== null && exportName !== null) {
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, exportName + fileExtension);
      }
    };

    const customClick = (menuItem) => {
      customMenuClick(menuItem);
      handleClose();
    };

    return (
      <Paper
        className={fixedHeightPaperNormal}
        style={{ marginBottom: marginBottom }}
        elevation={2}
      >
        <Box display="flex" p={0.5} className={classes.containerHeader}>
          <Box pl={0.3} flexGrow={1}>
            <PaperTitle title={title} />
          </Box>
          {subtitle && (
            <Box pt={0.5}>
              <PaperSubtitle subtitle={subtitle} />
            </Box>
          )}
          <Box>
            <IconButton
              aria-label="settings"
              color="primary"
              edge="start"
              size="small"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {customMenuItem &&
                customMenuItem.map((menuItem) => (
                  <MenuItem
                    key={menuItem}
                    onClick={() => customClick(menuItem)}
                  >
                    {menuItem}
                  </MenuItem>
                ))}
              {exportar && <MenuItem onClick={exportToCsv}>Exportar</MenuItem>}
              {exportarNotas && (
                <MenuItem onClick={exportarNotas}>Exportar Notas</MenuItem>
              )}
              {downloadChart && (
                <MenuItem onClick={downloadChart}>Descargar Gráfica</MenuItem>
              )}
              {toggleChart && (
                <MenuItem onClick={toggleChart}>Cambiar Gráfica</MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
        {/* <Grid container className={classes.containerHeader}>
        <Grid item xs={11}>
          <PaperTitle title={title} />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="settings"
            color="primary"
            edge="start"
            size="small"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {customMenuItem &&
              customMenuItem.map((menuItem) => (
                <MenuItem key={menuItem} onClick={() => customClick(menuItem)}>
                  {menuItem}
                </MenuItem>
              ))}
            {exportar && <MenuItem onClick={exportToCsv}>Exportar</MenuItem>}
            {exportarNotas && (
              <MenuItem onClick={exportarNotas}>Exportar Notas</MenuItem>
            )}
            {downloadChart && (
              <MenuItem onClick={downloadChart}>Descargar Gráfica</MenuItem>
            )}
            {toggleChart && (
              <MenuItem onClick={toggleChart}>Cambiar Gráfica</MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid> */}
        <Box className={classes.containerBody}>{children}</Box>
      </Paper>
    );
  }
);
