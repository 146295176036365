import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  List,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useContext } from "react";
import { getQueryTab, setQryTab } from "../common/CygniRequests";
import { UserContext } from "../contexts/user-context";

const useStyles = makeStyles((theme) => ({
  listQueries: {
    width: "100%",
    // maxWidth: 360,
    maxHeight: 180,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ConfTableros = ({ paperStyle }) => {
  const classes = useStyles();
  // userContext
  const userContext = useContext(UserContext);
  const [queries, setQueries] = useState(null);
  const [data, setData] = useState(null);
  const [tabName, setTabName] = useState("");
  const [checked, setChecked] = React.useState([]);

  useEffect(() => {
    loadQueries();
    loadTableros();
  }, []);

  const loadQueries = () => {
    getQueryTab("QUERY", userContext.id)
      .then(function (response) {
        setQueries(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const loadTableros = () => {
    getQueryTab("TABLERO", userContext.id)
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const saveTab = () => {
    if (!tabName || tabName === "" || !checked || checked.length < 0) {
      return;
    }
    setQryTab("TABLERO", {
      usuario: userContext.id,
      nombre: tabName,
      ids: checked,
    })
      .then(function (response) {
        if (
          response.data &&
          response.data.insertId &&
          response.data.insertId !== 0
        ) {
          setTabName("");
          setChecked([]);
          loadTableros();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <Paper
        className={paperStyle}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        elevation={2}
      >
        <Typography variant="h5" color="primary" gutterBottom>
          Nuevo Tablero
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              id="nombre"
              label="Nombre"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(val) => setTabName(val.target.value)}
              value={tabName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ maxHeight: 180, overflow: "auto" }}
          >
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Seleccione queries para el tablero
            </Typography>
            <List dense className={classes.listQueries}>
              {queries &&
                queries.map((value, i) => {
                  const labelId = `checkbox-list-secondary-label-${value.id}`;
                  return (
                    <ListItem key={i} button>
                      <ListItemText
                        primary={`Query: ${value.nombre}`}
                        secondary={
                          <Typography color="textSecondary">
                            {value.twitter === "null"
                              ? value.query
                              : value.query + " - " + value.twitter}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value.id)}
                          checked={checked.indexOf(value.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button variant="contained" color="primary" onClick={saveTab}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={paperStyle}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        elevation={2}
      >
        <Typography variant="h5" color="primary" gutterBottom>
          Todos Mis Tableros
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>#TabId</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Nombre</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{"t#" + el.id}</TableCell>
                  <TableCell>{el.nombre}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};
