import React, { useState, useContext, Suspense, lazy } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { Router } from "@reach/router";
import { NavBar } from "./common/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { Dashboard } from "./dashboard/Dashboard";
import { Resultados } from "./resultados/Resultados";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { UserContext } from "./contexts/user-context";
// import { Riesgos } from "./riesgos/Riesgos";
// import { Encuestas } from "./encuestas/Encuestas";
import { NvaEncuesta } from "./encuestas/NvaEncuesta";
import { DetEncuesta } from "./encuestas/DetEncuesta";
import { Incidentes } from "./encuestas/Incidentes";
import { UsuariosRTD } from "./encuestas/UsuariosRTD";
import { Candidatos } from "./candidatos/Candidatos";
import { Config } from "./configuracion/Config";
import { Busqueda } from "./busqueda/Busqueda";
import { DetRiesgo } from "./riesgos/DetRiesgo";
// import { DatosAvanzados } from "./datos_avanzados/DatosAvanzados";
// import { CygniMaps } from "./mapas/CygniMaps";
import { Visualizacion } from "./dashboard/Visualizacion";
import { Medios } from "./medios/Medios";
import { Analisis } from "./analisis/Analisis";
import { CygniLoading } from "./common/CygniLoading";
import { DescargaBases } from "./utilerias/DescargaBases";
import { Utlis } from "./utilerias/Utils";
import { IndSeguridad } from "./utilerias/IndSeguridad";
import { IndPobreza } from "./utilerias/IndPobreza";
import { IndCenso } from "./utilerias/IndCenso";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

function App() {
  const classes = useStyles();
  // userContext
  const userContext = useContext(UserContext);

  const changeLoggedIn = (data) => {
    var tableros = [
      {
        id: "1",
        nombre: "Presidente 2024",
        actores: [
          { nombre: "AMLO", query: "amlo", twitter: "@lopezobrador_" }, //se usara ~ para separar palabras en query.. CygniList.js => CygniNotesList lo usara para highlight
          {
            nombre: "Felipe Calderón",
            query: "felipe_calderon",
            twitter: "@FelipeCalderon",
          },
          {
            nombre: "Enrique Alfaro",
            query: "enrique_alfaro",
            twitter: "@EnriqueAlfaroR",
          },
          {
            nombre: "Silvano Aureoles",
            query: "silvano_aureoles",
            twitter: "@Silvano_A",
          },
        ],
      },
      {
        id: "2",
        nombre: "Sinaloa 2021",
        actores: [
          {
            nombre: "Tatiana Clouthier",
            query: "tatiana_clouthier",
            twitter: "@tatclouthier",
          },
          {
            nombre: "Ruben Rocha",
            query: "ruben_rocha_moya",
            twitter: "@rochamoya_",
          },
          {
            nombre: "Gerardo Vargas Landeros",
            query: "gerardo_vargas_landeros",
            twitter: "@GVargasLanderos",
          },
          {
            nombre: "Heriberto Felix",
            query: "heriberto_felix_guerra",
            twitter: "@HeribertoFelixG",
          },
        ],
      },
      {
        id: "3",
        nombre: "Nuevo León 2021",
        actores: [
          {
            nombre: "Alfonso Romo",
            query: "alfonso_romo_garza",
            twitter: "Alfonso Romo Garza",
          },
          {
            nombre: "Idelfonso Guajardo",
            query: "idelfonso_guajardo",
            twitter: "@ildefonsogv",
          },
          {
            nombre: "Adrián de la Garza",
            query: "adrian_de_la_garza",
            twitter: "@AdrianDeLaGarza",
          },
          {
            nombre: "Clara Luz Flores",
            query: "clara_luz_flores",
            twitter: "@claraluzflores",
          },
        ],
      },
    ]; // data.tableros cuando se modifique el backend

    var tabs = tableros
      .map(
        (tab) =>
          tab.id +
          ";" +
          tab.nombre +
          ";" +
          tab.actores.map(
            (act) => act.nombre + "|" + act.query + "|" + act.twitter
          )
      )
      .join("~");

    setUser({
      ...user,
      id: data.id,
      usuario: data.usuario,
      nombre: data.nombre,
      email: data.email,
      cliente: data.cliente,
      tipo: data.tipo,
      region: data.region,
      dep_user: data.dep_user,
      logged: true,
      tableros: tabs,
    });

    localStorage.setItem("logged", "true");
    localStorage.setItem("id", data.id);
    localStorage.setItem("usuario", data.usuario);
    localStorage.setItem("nombre", data.nombre);
    localStorage.setItem("email", data.email);
    localStorage.setItem("cliente", data.cliente);
    localStorage.setItem("tipo", data.tipo);
    localStorage.setItem("region", data.region);
    localStorage.setItem("dep_user", data.dep_user);
    // localStorage.setItem("tableros", tabs);
  };

  const changeLoggedOut = () => {
    setUser({
      ...user,
      id: null,
      usuario: null,
      nombre: null,
      email: null,
      logged: false,
      tableros: [],
    });
    localStorage.setItem("logged", "false");
    localStorage.setItem("id", "");
    localStorage.setItem("usuario", "");
    localStorage.setItem("nombre", "");
    localStorage.setItem("email", "");
    localStorage.setItem("tableros", "");
    localStorage.setItem("cliente", "");
    localStorage.setItem("tipo", "");
    localStorage.setItem("region", "");
    localStorage.setItem("dep_user", "");
  };

  const [user, setUser] = useState({
    id: userContext.id,
    usuario: userContext.usuario,
    nombre: userContext.nombre,
    email: userContext.email,
    cliente: userContext.cliente,
    tipo: userContext.tipo,
    region: userContext.region,
    dep_user: userContext.dep_user,
    logged: userContext.logged,
    tableros: userContext.tableros,
    loggedIn: changeLoggedIn,
    loggedOut: changeLoggedOut,
  });

  // Change export const en el componente por const Componente y al final export default Component
  const CygniMaps = lazy(() => import("./mapas/CygniMaps"));
  const Encuestas = lazy(() => import("./encuestas/Encuestas"));
  const DatosAvanzados = lazy(() => import("./datos_avanzados/DatosAvanzados"));
  const Riesgos = lazy(() => import("./riesgos/Riesgos"));

  return (
    <UserContext.Provider value={user}>
      {user.logged ? (
        <div className={classes.root}>
          <CssBaseline />
          <NavBar />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Suspense fallback={<CygniLoading />}>
              <Router>
                <Dashboard path="/" />
                <Analisis path="/analisis" />
                <Visualizacion path="/vis/:id" />
                <CygniMaps path="/cygni_maps" />
                <Medios path="/medios" />
                <Resultados path="/resultados" />
                <Riesgos path="/riesgos" />
                <DetRiesgo path="/riesgo/:id/:edo/:nombre" />
                <Encuestas path="/encuestas" />
                <NvaEncuesta path="/nva_encuesta" />
                <DetEncuesta path="/det_encuesta/:id" />
                <UsuariosRTD path="/usuariosRTD" />
                <Incidentes path="/incidentes" />
                <Candidatos path="/candidatos" />
                <Busqueda path="/busqueda" />
                <DatosAvanzados path="/datos_avanzados" />
                <Utlis path="/util" />
                <DescargaBases path="/descarga_bases" />
                <IndSeguridad path="/ind_seguridad" />
                <IndPobreza path="/ind_pobreza" />
                <IndCenso path="/ind_censo" />
                <Config path="/config" />
                <Login path="/login" />
                <Logout path="/logout" />
              </Router>
            </Suspense>
          </main>
        </div>
      ) : (
        <Login />
      )}
    </UserContext.Provider>
  );
}

export default App;
