import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Fab,
} from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
}));

export const NvaEncuesta = () => {
  const classes = useStyles();

  const [encuesta, setEncuesta] = useState({
    titulo: "",
    nivel: "",
    frecuencia: "",
    descripcion: "",
    reactivos: [],
  });
  const [reactivo, setReactivo] = useState({
    indice: 0,
    texto: "",
    tipo: "text",
    ops: [],
  });

  const onChange = (e) => {
    setEncuesta({
      ...encuesta,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeReactivo = (e) => {
    setReactivo({
      ...reactivo,
      indice: encuesta.reactivos.length + 1,
      [e.target.name]:
        e.target.name !== "ops"
          ? e.target.value
          : e.target.value.split(";").map((r) => ({ texto: r, val: r })),
    });
  };

  const onAddReactivo = (e) => {
    setEncuesta({
      ...encuesta,
      reactivos: [...encuesta.reactivos, reactivo],
    });
    setReactivo({
      indice: 0,
      texto: "",
      tipo: "text",
      ops: [],
    });
  };

  const onDelete = (index) => {
    setEncuesta({
      ...encuesta,
      reactivos: encuesta.reactivos.filter((r, i) => i !== index),
    });
  };

  const saveEncuesta = (e) => {
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // API
    axios
      .post("http://162.243.166.142:5002/api/encuestas", encuesta, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Nueva Encuesta
      </Typography>
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="titulo"
            name="titulo"
            label="Título"
            margin="normal"
            required
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={encuesta.titulo}
            size="small"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="nivel"
                name="nivel"
                label="Nivel"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                onChange={onChange}
                value={encuesta.nivel}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="frecuencia"
                name="frecuencia"
                label="Frecuencia"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                onChange={onChange}
                value={encuesta.frecuencia}
                size="small"
              />
            </Grid>
          </Grid>
          <TextField
            id="descripcion"
            name="descripcion"
            label="Descripcion"
            margin="normal"
            required
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={encuesta.descripcion}
            size="small"
          />
          <hr />
        </form>
        <Typography component="h6" variant="h6">
          Agregue reactivos a su encuesta.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              id="texto"
              name="texto"
              label="Texto"
              margin="normal"
              required
              fullWidth
              variant="outlined"
              value={reactivo.texto}
              onChange={onChangeReactivo}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: "1rem" }}
              size="small"
            >
              <InputLabel id="tipoReactivo">Tipo de Reactivo</InputLabel>
              <Select
                labelId="tipoReactivo"
                id="tipo"
                name="tipo"
                value={reactivo.tipo}
                onChange={onChangeReactivo}
                label="Tipo de Reactivo"
              >
                <MenuItem value={"text"}>Texto libre</MenuItem>
                <MenuItem value={"radio"}>Una opción</MenuItem>
                <MenuItem value={"check"}>Opción múltiple</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "1rem" }}
              onClick={onAddReactivo}
            >
              Agregar Reactivo
            </Button>
          </Grid>
        </Grid>
        {reactivo.tipo !== "text" && (
          <TextField
            id="ops"
            name="ops"
            label="Opciones"
            margin="normal"
            required
            fullWidth
            variant="outlined"
            value={reactivo.ops.map((e) => e.texto).join(";")}
            onChange={onChangeReactivo}
          />
        )}
      </Paper>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Texto</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Opciones</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {encuesta &&
                encuesta.reactivos &&
                encuesta.reactivos.map((enc, index) => (
                  <TableRow key={index}>
                    <TableCell>{enc.texto}</TableCell>
                    <TableCell>{enc.tipo}</TableCell>
                    <TableCell>
                      {enc.ops &&
                        enc.ops.map((op, i) => <p key={i}>{op.val}</p>)}
                    </TableCell>
                    <TableCell style={{ width: "5px" }}>
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        onClick={() => onDelete(index)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Fab
        variant="extended"
        color="primary"
        className={classes.fab}
        onClick={saveEncuesta}
      >
        <Save />
        Guardar
      </Fab>
    </Container>
  );
};
