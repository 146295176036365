import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CygniLoading } from "../common/CygniLoading";
import { qryMediosGin } from "../common/CygniRequests";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Concepto/Actor", "OR", "AND", "NOT", "Parametrización"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Ingrese el concepto o actor principal que desea buscar en la base de datos`;
    case 1:
      return "Ingrese los conceptos o nombres de actor alternativos a la búsqueda principal";
    case 2:
      return `Ingrese la(s) palabra(s) que forzosamente deberá incluir la búsqueda`;
    case 3:
      return `Ingrese la(s) palabras(s) que se deberán excluir de la búsqueda`;
    case 4:
      return `Seleccione fechas de inicio y fin, así como las características del archivo para descargar`;
    default:
      return "Paso desconocido.";
  }
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const DescargaBases = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [params, setParams] = useState({
    qry: "",
    or: "",
    and: "",
    not: "",
    f1: formatDate(new Date()),
    f2: formatDate(new Date()),
    sep: ",",
  });
  const [data, setData] = useState(null);
  const steps = getSteps();
  const fields = ["qry", "or", "and", "not"];

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      console.log(params);
      var res = await qryMediosGin(params);
      console.log(res);
      setData(res.data);
    } else if ((activeStep === 0 && params.qry !== "") || activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setParams({
      qry: "",
      or: "",
      and: "",
      not: "",
      f1: formatDate(new Date()),
      f2: formatDate(new Date()),
      sep: ",",
    });
    setData(null);
  };

  const handleChange = (event) => {
    setParams({
      ...params,
      [event.target.name]: event.target.value,
    });
  };

  // const queryDesc = () => {
  //   var desc = `QUERY: "${params.qry}" OR (${params.or}) AND (${params.and}) NOT (${params.not})`;

  //   return <Typography>{desc}</Typography>;
  // };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography variant="h3" component="h3" align="center" color="primary">
        Descarga de Bases
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography gutterBottom>{getStepContent(index)}:</Typography>
              {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <Typography variant="caption" color="textSecondary">
                  <em>
                    Use + para separar conceptos/actores. Ej.
                    amlo+presidente+lopez obrador.
                  </em>
                </Typography>
              )}
              {activeStep !== steps.length - 1 && (
                <TextField
                  id={fields[index]}
                  name={fields[index]}
                  label={label}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(event) => handleChange(event)}
                  value={params[fields[index]]}
                />
              )}
              {activeStep === steps.length - 1 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="f1"
                      name="f1"
                      label="Fecha Inicial"
                      type="date"
                      defaultValue={params.f1}
                      onChange={(event) => handleChange(event)}
                      style={{ marginRight: "1rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="f2"
                      name="f2"
                      label="Fecha Final"
                      type="date"
                      defaultValue={params.f2}
                      onChange={(event) => handleChange(event)}
                      style={{ marginRight: "1rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Separador</FormLabel>
                      <RadioGroup
                        id="sep"
                        name="sep"
                        row
                        aria-label="sep"
                        // defaultValue=","
                        value={params.sep}
                        onChange={(event) => handleChange(event)}
                      >
                        <FormControlLabel
                          value=","
                          control={<Radio color="primary" />}
                          label="Coma ,"
                        />
                        <FormControlLabel
                          value=";"
                          control={<Radio color="primary" />}
                          label="Punto y coma ;"
                        />
                        <FormControlLabel
                          value="tab"
                          control={<Radio color="primary" />}
                          label="Tabulaciones"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    color="secondary"
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "BUSCAR" : "Siguiente"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <React.Fragment>
          {!data && <CygniLoading />}
          {data && data.error !== "" && (
            <Typography variant="subtitle" color="secondary">
              ERROR: {data.error}
            </Typography>
          )}
          {data && data.error === "" && (
            <React.Fragment>
              <Typography variant="caption" style={{ margin: "2rem" }}>
                <em>QUERY-ES: {data.query}</em>
              </Typography>
              <Divider />
              <a href={data.result} download style={{ margin: "2rem" }}>
                Presione aquí para descargar el archivo generado con las notas
                en formato .csv
              </a>
              <Divider />
              <Button
                variant="outlined"
                onClick={handleReset}
                style={{ margin: "2rem" }}
              >
                Reiniciar Búsqueda
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Container>
  );
};
