import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Typography } from "@material-ui/core";
import { CygniRadialChart } from "../../common/CygniRadialChart";
import { CygniLoading } from "../../common/CygniLoading";
import { getGraficas } from "../../common/CygniRequests";
import { numberWithCommas } from "../../common/Cygni";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

export const MediosParticipacion = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [png, ref] = useRechartToPng();
  const [tot, setTot] = useState({ circ: 0, dec: 0, ref: 0 });

  useEffect(() => {
    setData(null);
    setTot({ circ: 0, dec: 0, ref: 0 });

    var qry = query
      .trim()
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/á/g, "a")
      .replace(/é/g, "e")
      .replace(/í/g, "i")
      .replace(/ó/g, "o")
      .replace(/ú/g, "u")
      .replace(/ñ/g, "n");
    var f1 = fecha1 + " 00:00:00.0";
    var f2 = fecha2 + " 23:59:59.0";

    getGraficas(qry, f1, f2, "cygnimovilparticipacion")
      .then(function (response) {
        var part = [];
        var totales = { circ: 0, dec: 0, ref: 0 };
        var rData = response.valoradas.participacion;
        if (rData !== null && rData !== undefined && rData !== "undefined") {
          Object.entries(rData).forEach(([k, v]) => {
            var tot = 0;
            Object.entries(v).forEach(([kv, vv]) => {
              tot += vv.cantidad;
            });
            var fill = "";
            if (k === "referente") {
              fill = "#8884d8";
              totales.ref += tot;
            }
            if (k === "declarante") {
              fill = "#83a6ed";
              totales.dec += tot;
            }
            if (k === "circunstancial") {
              fill = "#8dd1e1";
              totales.circ += tot;
            }
            part.push({ name: k, PART: tot, fill: fill });
          });
        }

        setData(part);
        setTot(totales);
      })
      .catch(function (error) {
        console.error(error);
        setData([]);
      });
  }, [query, fecha1, fecha2]);

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "part.png");
  }, [png]);

  return (
    <PaperContainer
      title="Participación"
      subtitle={
        data &&
        data.length > 0 &&
        tot &&
        numberWithCommas(tot.ref) +
          " ref, " +
          numberWithCommas(tot.dec) +
          " dec, " +
          numberWithCommas(tot.circ) +
          " circ"
      }
      exportData={data}
      exportName="medios_particip"
      downloadChart={downloadChart}
    >
      {!data && <CygniLoading />}
      {data && data.length === 0 && (
        <Typography
          component="h5"
          variant="h5"
          align="center"
          style={{ marginTop: "10px" }}
        >
          No hay datos disponibles.
        </Typography>
      )}
      {data && data.length > 0 && (
        // <CygniRadialChart data={data} dataKey="PART" />
        <CygniRadialChart data={data} dataKey="PART" exportRef={ref} />
      )}
    </PaperContainer>
  );
});
