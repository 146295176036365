import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  GridList,
  GridListTile,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import React from "react";
import { numberWithCommas } from "../common/Cygni";
import { CygniBarChart } from "../common/CygniBarChart";
import { CygniLoadingFix } from "../common/CygniLoading";
import { CygniPieChart } from "../common/CygniPieChart";
import {
  getEstados,
  getMunicipios,
  getUtilDelitos,
} from "../common/CygniRequests";
import { ExportCSV } from "../common/ExportCSV";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  params: {
    position: "absolute",
    top: "4rem",
    right: "2rem",
    backgroundColor: "white", //"transparent", //"#fff",
    margin: "12px",
  },
}));

export const IndSeguridad = () => {
  const classes = useStyles();
  const [edoList, setEdoList] = React.useState(null);
  const [munList, setMunList] = React.useState(null);
  const [ind, setInd] = React.useState("-1");
  const [edo, setEdo] = React.useState("33");
  const [mun, setMun] = React.useState("999");
  const [tipo, setTipo] = React.useState("-1");
  const [anio, setAnio] = React.useState("2015");
  const [top, setTop] = React.useState("3");
  const [search, setSearch] = React.useState({
    ind: null,
    edo: null,
    mun: null,
    tipo: null,
    anio: null,
    top: null,
  });
  const [data, setData] = React.useState(null);
  const [chart, setChart] = React.useState(null);
  const [colData, setColData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showAppbar, setShowAppbar] = React.useState(true);
  const totKeyVal = {
    DAI: [
      { Secuestro: "#c4a208" },
      { Robo: "#063180" },
      { Homicidio: "#e94a36" },
      { Extorsion: "#465744" },
    ],
    fem: [{ Feminicidio: "#65199e" }],
    total: [{ total: "#ab5403" }],
  };

  React.useEffect(() => {
    getEstados()
      .then(function (response) {
        setEdoList(response);
      })
      .catch(function (error) {
        console.error(error);
        setEdoList(null);
      });
  }, []);

  React.useEffect(() => {
    if (edo !== "33") {
      getMunicipios(edo)
        .then(function (response) {
          setMunList(response);
        })
        .catch(function (error) {
          console.error(error);
          setMunList(null);
        });
    } else {
      setMunList(null);
      setMun("999");
    }
  }, [edo]);

  React.useEffect(() => {
    if (search.ind === null) return;
    if ((search.ind === "DAI" || search.ind === "fem") && search.tipo === "-1")
      return;
    setData(null);
    setColData(null);
    setLoading(true);
    _getUtilData();
  }, [search, setSearch]);

  const _getUtilData = async () => {
    var res = await getUtilDelitos(
      search.ind,
      search.edo,
      search.mun,
      search.tipo,
      search.anio,
      search.top
    );

    // como se limpian las var de los queries (@ent_rank, @current_ent, etc)
    // llegan más respuestas, por eso se escoge solo la buena
    if (search.ind === "mas_com") {
      res = res[2];
    } else if (search.ind === "mas_cre") {
      res = res[1];
    }

    var chartData = [];
    var chartDataObj = {};
    var chartDataPerEntMun = {
      2015: [],
      2016: [],
      2017: [],
      2018: [],
      2019: [],
      2020: [],
      2021: [],
    };
    if (res && res.length > 0) {
      res.forEach((el) => {
        if (search.ind === "DAI" || search.ind === "fem") {
          var r = chartData.find((f) => f.name === el.anio);
          var rf = chartDataPerEntMun[el.anio].find(
            (f) => f.name === el.entidad
          ); // ent

          // Por año
          if (r) {
            if (el.tipo_delito === "Homicidio") r.Homicidio += el.total;
            if (el.tipo_delito === "Secuestro") r.Secuestro += el.total;
            if (el.tipo_delito === "Robo") r.Robo += el.total;
            if (el.tipo_delito === "Extorsión") r.Extorsion += el.total;
            if (el.tipo_delito === "Feminicidio") r.Feminicidio += el.total;
          } else {
            if (search.ind === "DAI") {
              var cd = {
                name: el.anio,
                Homicidio: 0,
                Secuestro: 0,
                Robo: 0,
                Extorsion: 0,
              };
              if (el.tipo_delito === "Homicidio") cd.Homicidio += el.total;
              if (el.tipo_delito === "Secuestro") cd.Secuestro += el.total;
              if (el.tipo_delito === "Robo") cd.Robo += el.total;
              if (el.tipo_delito === "Extorsión") cd.Extorsion += el.total;
            } else if (search.ind === "fem") {
              var cd = {
                name: el.anio,
                Feminicidio: 0,
              };
              if (el.tipo_delito === "Feminicidio") cd.Feminicidio += el.total;
            }
            chartData.push(cd);
          }

          // por entidad
          if (rf) {
            rf.total += el.total;
          } else {
            var cd = { name: el.entidad, total: el.total };
            chartDataPerEntMun[el.anio].push(cd);
          }
        } else if (search.ind === "mas_com") {
          var r = chartData.find((f) => f.name === el.entidad);
          if (el.entidad in chartDataObj) {
            chartDataObj[el.entidad].push({
              name: el.tipo_delito,
              value: el.total,
            });
          } else {
            chartDataObj[el.entidad] = [
              { name: el.tipo_delito, value: el.total },
            ];
          }
        }
      });
    }

    setChart({
      year: chartData,
      entMun: chartDataPerEntMun,
      mas_com: chartDataObj,
    });
    setData(res);
    if (res.length > 0 && "error" in res === false)
      setColData(Object.keys(res[0]));
    setLoading(false);
  };

  const _consultar = () => {
    setSearch({
      ind: ind,
      edo: edo,
      mun: mun,
      tipo: tipo,
      anio: anio,
      top: top,
    });
  };

  let legendRender = function (value) {
    return <span style={{ fontSize: "0.7rem" }}>{value}</span>;
  };

  let customLabel = function ({ x, y, stroke, value }) {
    return (
      <text x={x} y={y} fill={stroke} fontSize={9}>
        {numberWithCommas(value)}
      </text>
    );
  };

  let categLabel = function ({ x, y, name }) {
    return (
      <text x={x} y={y} fontSize={search.edo === "33" ? 10 : 11}>
        {name}
      </text>
    );
  };

  const toggleAppbar = () => {
    setShowAppbar(!showAppbar);
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography
        variant="h4"
        component="h4"
        align="center"
        color="primary"
        gutterBottom
      >
        Indicadores Seguridad
      </Typography>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={10}> */}
      {loading && !data && <CygniLoadingFix />}
      {!loading && data && data.length > 0 && (
        <Box>
          {(search.ind === "DAI" || search.ind === "fem") && (
            <React.Fragment>
              <Paper
                variant="elevation"
                elevation={2}
                style={{
                  height: "20rem",
                  padding: "0.5rem",
                  marginBottom: "0.7rem",
                }}
              >
                <CygniBarChart
                  data={chart.year}
                  keysAndColors={totKeyVal[search.ind]}
                  legendRender={legendRender}
                  iconSize={11}
                  // exportRef={ref}
                  // barLabel={{ position: "top", fontSize: 9 }}
                  barLabel={customLabel}
                />
              </Paper>
              {search.edo === "33" && (
                <Paper
                  variant="elevation"
                  elevation={2}
                  style={{
                    height: "20rem",
                    padding: "0.5rem",
                    marginBottom: "0.7rem",
                  }}
                >
                  <GridList
                    cellHeight={230}
                    className={classes.gridList}
                    cols={1.2}
                  >
                    {Object.entries(chart.entMun).map((entry, i) => {
                      return (
                        <GridListTile key={"fem_dai_" + i} cols={1}>
                          <Typography
                            variant="subtitle1"
                            component="h6"
                            align="center"
                          >
                            {entry[0]}
                          </Typography>
                          <CygniBarChart
                            data={entry[1]}
                            keysAndColors={totKeyVal.total}
                            legendRender={legendRender}
                            iconSize={11}
                            // exportRef={ref}
                            // barLabel={{ position: "top", fontSize: 9 }}
                            barLabel={customLabel}
                          />
                        </GridListTile>
                      );
                    })}
                  </GridList>
                </Paper>
              )}
            </React.Fragment>
          )}
          {search.ind === "mas_com" && (
            <GridList
              cellHeight={240}
              className={classes.gridList}
              cols={search.edo === "33" ? 3.2 : 1}
              style={{ marginBottom: "0.7rem" }}
            >
              {Object.entries(chart.mas_com).map((entry, i) => {
                return (
                  <GridListTile key={"mas_com_" + i} cols={1}>
                    <Paper
                      variant="elevation"
                      elevation={3}
                      style={{
                        height: "100%",
                        margin: "0 0.2rem",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="h5"
                        align="center"
                      >
                        {entry[0]}
                      </Typography>
                      <CygniPieChart
                        dataKey="value"
                        data={entry[1]}
                        labelLine={true}
                        label={categLabel}
                        // chartClick={chartClick}
                        // exportRef={ref}
                        // legend={true}
                        outerRadius={search.edo === "33" ? "45%" : "60%"}
                      />
                    </Paper>
                  </GridListTile>
                );
              })}
            </GridList>
          )}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {colData &&
                    colData.map((el, i) => {
                      if (
                        el !== "cve_ent" &&
                        el !== "cve_mun" &&
                        el !== "pre"
                      ) {
                        return <TableCell key={"th_" + i}>{el}</TableCell>;
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {colData &&
                  colData.length > 0 &&
                  data.map((el, i) => {
                    return (
                      <TableRow key={"tr_" + i}>
                        {colData.map((sel, j) => {
                          if (
                            sel !== "cve_ent" &&
                            sel !== "cve_mun" &&
                            sel !== "pre"
                          ) {
                            return (
                              <TableCell key={"r" + j}>
                                {sel === "total"
                                  ? numberWithCommas(el[sel])
                                  : el[sel]}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {/* </Grid>
      </Grid> */}
      <Paper
        variant="elevation"
        elevation={4}
        style={{ width: "20rem" }}
        className={classes.params}
      >
        <Box display="flex" p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle1" component="h6">
              Parámetros
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="settings"
              color="inherit"
              edge="start"
              size="small"
              onClick={toggleAppbar}
            >
              {showAppbar ? <ToggleOn /> : <ToggleOff />}
            </IconButton>
          </Box>
        </Box>
        <Collapse in={showAppbar}>
          <Box p={1.5}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ marginBottom: "0.5rem" }}
              fullWidth
            >
              <InputLabel id="ind-seg-label">Indicador</InputLabel>
              <Select
                labelId="ind-seg-label"
                id="ing-seg"
                value={ind}
                onChange={(e) => setInd(e.target.value)}
              >
                <MenuItem value="-1">Seleccione un indicador</MenuItem>
                <MenuItem value="DAI">Delitos de Alto Impacto</MenuItem>
                <MenuItem value="fem">Feminicidios</MenuItem>
                <MenuItem value="mas_com">Delitos más cometidos</MenuItem>
                {/* <MenuItem value="mas_cre">Delitos con mayor crecimiento</MenuItem> */}
              </Select>
            </FormControl>
            {ind !== "-1" && (
              <React.Fragment>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ marginBottom: "0.5rem" }}
                  fullWidth
                >
                  <InputLabel id="ind-seg-edo-label">Estado</InputLabel>
                  <Select
                    labelId="ind-seg-edo-label"
                    id="ing-seg-edo"
                    value={edo}
                    onChange={(e) => setEdo(e.target.value)}
                  >
                    <MenuItem value="33">Nacional</MenuItem>
                    {edoList &&
                      edoList.map((el, i) => {
                        if (el.cve !== 33 && el.cve !== 50) {
                          return (
                            <MenuItem key={"edo_" + i} value={el.cve}>
                              {el.valor}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  style={{ marginBottom: "0.5rem" }}
                  size="small"
                  fullWidth
                >
                  <InputLabel id="ind-seg-mun-label">Municipio</InputLabel>
                  <Select
                    labelId="ind-seg-mun-label"
                    id="ing-seg-mun"
                    value={mun}
                    onChange={(e) => setMun(e.target.value)}
                  >
                    <MenuItem value="999">Todos los municipios </MenuItem>
                    {munList &&
                      munList.map((el, i) => {
                        if (el.cve !== 999 && el.cve !== 900) {
                          return (
                            <MenuItem key={"mun_" + i} value={el.cve}>
                              {el.valor}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </React.Fragment>
            )}
            {ind !== "-1" && (ind === "DAI" || ind === "fem") && (
              <FormControl
                variant="outlined"
                style={{ marginBottom: "0.5rem" }}
                size="small"
                fullWidth
              >
                <InputLabel id="ind-seg-tipo-label">Tipo</InputLabel>
                <Select
                  labelId="ind-seg-tipo-label"
                  id="ing-seg-tipo"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem value="-1">Seleccione Tipo (para tabla)</MenuItem>
                  <MenuItem value="det">Detallado</MenuItem>
                  <MenuItem value="det_tot">Detallado por año</MenuItem>
                  <MenuItem value="por_subtipo">Por Subtipo</MenuItem>
                  <MenuItem value="por_tipo">Por Tipo</MenuItem>
                </Select>
              </FormControl>
            )}
            {ind !== "-1" && ind === "mas_com" && (
              <FormControl
                variant="outlined"
                style={{ marginBottom: "0.5rem" }}
                size="small"
                fullWidth
              >
                <InputLabel id="ind-seg-anio-label">Año</InputLabel>
                <Select
                  labelId="ind-seg-anio-label"
                  id="ing-seg-anio"
                  value={anio}
                  onChange={(e) => setAnio(e.target.value)}
                >
                  <MenuItem value="2015">2015</MenuItem>
                  <MenuItem value="2016">2016</MenuItem>
                  <MenuItem value="2017">2017</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                </Select>
              </FormControl>
            )}
            {ind !== "-1" && (ind === "mas_com" || ind === "mas_cre") && (
              <FormControl
                variant="outlined"
                style={{ marginBottom: "0.5rem" }}
                size="small"
                fullWidth
              >
                <InputLabel id="ind-seg-top-label">Top</InputLabel>
                <Select
                  labelId="ind-seg-top-label"
                  id="ing-seg-top"
                  value={top}
                  onChange={(e) => setTop(e.target.value)}
                >
                  <MenuItem value="3">Top 3</MenuItem>
                  <MenuItem value="5">Top 5</MenuItem>
                  <MenuItem value="7">Top 7</MenuItem>
                </Select>
              </FormControl>
            )}
            {ind !== "-1" && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginBottom: "0.5rem" }}
                onClick={_consultar}
              >
                Consultar
              </Button>
            )}
            {ind !== "-1" && data && (
              <ExportCSV
                csvData={data}
                fileName={ind + "-" + edo + "-" + mun + "-" + tipo + "-" + top}
              />
            )}
          </Box>
        </Collapse>
      </Paper>
    </Container>
  );
};
