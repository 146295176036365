import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { getNotasEdos } from "../../common/CygniRequests";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { getEdoAbrev, numberWithCommas, queryPrep } from "../../common/Cygni";
import { CygniLoadingFix } from "../../common/CygniLoading";
import { VisContainer } from "../../common/CygniContainers";

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
  anexoText: {
    fontSize: "small",
    fontStyle: "italic",
  },
}));

export const TotalNotasTweets = React.memo(
  ({ tipo, fuente, queries, current }) => {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [estados, setEstados] = useState(null);

    useEffect(() => {
      setData(null);
      if (tipo === "TOTAL") {
        if (tipo === "MEDIOS") {
          // MEDIOS
        } else {
          // REDES
        }
      } else if (tipo === "TOTAL_EDO") {
        var edoData = {};
        var edos = [];
        var resMap = queries.map(async (el, i) => {
          if (
            current.id.includes(-1) ||
            (!current.id.includes(-1) && current.id.includes(el.qry_id))
          ) {
            var res = await getNotasEdos(
              queryPrep(el.query),
              current.fi.replace(/-/g, "_"),
              current.ff.replace(/-/g, "_")
            );

            edoData[el.qry_nombre] = res.data;
            res.edos.forEach((v) => {
              var found = edos.find((ed) => ed.cve === v.cve);
              if (!found) {
                edos.push({ cve: v.cve, nombre: v.nombre });
              }
            });
          }
        });

        // Wait for all requests, and then setState
        Promise.all(resMap).then(() => {
          setData(edoData);
          setEstados(edos);
        });
      }
    }, [current, tipo, queries]);

    return (
      <VisContainer
        title="Total Notas por Estado"
        desc="Número de notas encontradas por estado para el query."
        time={data ? true : false}
        showMenu={false}
      >
        {!data && <CygniLoadingFix />}
        {data && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {estados &&
                  estados.map((edo) => (
                    <TableCell
                      key={edo.cve}
                      className={clsx(classes.boldText, classes.anexoText)}
                      style={{ padding: "5px 2px" }}
                    >
                      <div
                        style={{
                          // transformOrigin: "0 0",
                          transform: "rotate(-90deg)",
                          width: "35px",
                          maxWidth: "37px",
                          margin: "auto",
                        }}
                      >
                        <small>{getEdoAbrev(edo.nombre)}</small>
                      </div>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                Object.entries(data).map((d, i) => (
                  <TableRow key={i}>
                    <TableCell
                      className={clsx(classes.boldText, classes.anexoText)}
                    >
                      <small>{d[0]}</small>
                    </TableCell>
                    {estados &&
                      estados.map((edo) => {
                        var f = d[1].find((de) => de.cve === edo.cve);
                        return (
                          <TableCell
                            key={i + "-" + edo.cve}
                            align="center"
                            style={{ padding: "3px 2px" }}
                          >
                            {f && <small>{numberWithCommas(f.valor)}</small>}
                            {!f && <small>0</small>}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </VisContainer>
    );
  }
);
