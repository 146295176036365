import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(19),
  //   fontWeight: theme.typography.fontWeightBold,
  // },
  // fab: {
  //   margin: 0,
  //   top: "auto",
  //   right: 20,
  //   bottom: 20,
  //   left: "auto",
  //   position: "fixed",
  // },
  // pos: {
  //   marginBottom: 12,
  // },
}));

export const UsuariosRTD = () => {
  const classes = useStyles();

  const [usuarios, setUsuarios] = useState([]);
  const [usr, setUsr] = useState({
    id: "",
    name: "",
    email: "",
    username: "",
    password: "",
    tipo: "",
    permisos: "",
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // API
    axios
      .get("http://162.243.166.142:5002/api/usuarios", config)
      .then(function (response) {
        setUsuarios(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setUsuarios([]);
      });
  };

  const onChange = (e) => {
    setUsr({
      ...usr,
      [e.target.name]: e.target.value,
    });
  };

  const onEdit = (id) => {
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // API
    axios
      .get("http://162.243.166.142:5002/api/usuarios/" + id, config)
      .then(function (response) {
        setUsr(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setUsr({
          id: "",
          name: "",
          email: "",
          username: "",
          password: "",
          tipo: "",
          permisos: "",
        });
      });
  };

  const saveUsuario = (e) => {
    console.log(usr);
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (usr.id === "") {
      // API
      axios
        .post("http://162.243.166.142:5002/api/usuarios", usr, config)
        .then(function (response) {
          console.log(response);
          setUsr({
            id: "",
            name: "",
            email: "",
            username: "",
            password: "",
            tipo: "",
            permisos: "",
          });
          loadUsers();
        })
        .catch(function (error) {
          console.error(error);
          setUsr({
            id: "",
            name: "",
            email: "",
            username: "",
            password: "",
            tipo: "",
            permisos: "",
          });
        });
    } else {
      // API
      axios
        .put("http://162.243.166.142:5002/api/usuarios/" + usr._id, usr, config)
        .then(function (response) {
          console.log(response);
          setUsr({
            id: "",
            name: "",
            email: "",
            username: "",
            password: "",
            tipo: "",
            permisos: "",
          });
          loadUsers();
        })
        .catch(function (error) {
          console.error(error);
          setUsr({
            id: "",
            name: "",
            email: "",
            username: "",
            password: "",
            tipo: "",
            permisos: "",
          });
        });
    }

    // API
    axios
      .post("http://162.243.166.142:5002/api/usuarios", usr, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Usuarios RTD
      </Typography>
      <Paper className={classes.paper} style={{ marginBottom: "1rem" }}>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                name="name"
                label="Nombre"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                onChange={onChange}
                value={usr.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                onChange={onChange}
                value={usr.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="username"
                name="username"
                label="Usuario"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                onChange={onChange}
                value={usr.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                name="password"
                label="Contraseña"
                type="password"
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                onChange={onChange}
                value={usr.password}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: "1rem" }}
                size="small"
              >
                <InputLabel id="tipo">Tipo</InputLabel>
                <Select
                  labelId="tipo"
                  id="tipo"
                  name="tipo"
                  value={usr.tipo}
                  onChange={onChange}
                  label="Tipo"
                >
                  <MenuItem value="ADMIN">Administrador</MenuItem>
                  <MenuItem value="CAPT">Captura</MenuItem>
                  <MenuItem value="CONS">Consulta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: "1rem" }}
                size="small"
              >
                <InputLabel id="permisos">Permisos</InputLabel>
                <Select
                  labelId="permisos"
                  id="permisos"
                  name="permisos"
                  value={usr.permisos}
                  onChange={onChange}
                  label="Permisos"
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {/* <MenuItem value="CAPT">Captura</MenuItem>
                  <MenuItem value="CONS">Consulta</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "1rem" }}
                onClick={saveUsuario}
              >
                Guardar Usuario
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios &&
                usuarios.map((u) => (
                  <TableRow key={u._id}>
                    <TableCell>{u.name}</TableCell>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>{u.username}</TableCell>
                    <TableCell>{u.tipo}</TableCell>
                    <TableCell style={{ width: "5px" }}>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={() => onEdit(u._id)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};
