import React, { useEffect, useState } from "react";
import { queryPrep } from "../../common/Cygni";
import { CygniRadarChart } from "../../common/CygniRadarChart";
import { CygniLoadingFix } from "../../common/CygniLoading";
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
} from "recharts";
import { VisContainer } from "../../common/CygniContainers";
import { getGraficas } from "../../common/CygniRequests";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#86cad8",
  "#8884d8",
  "#FFFFFF",
];

export const PosicionamientoNotas = React.memo(({ queries, current }) => {
  const [data, setData] = useState(null);
  const [png, ref] = useRechartToPng();

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "pos_notas.png");
  }, [png]);

  useEffect(() => {
    setData(null);

    var pos = [];
    var resMap = queries.map(async (el, i) => {
      if (
        current.id.includes(-1) ||
        (!current.id.includes(-1) && current.id.includes(el.qry_id))
      ) {
        var qry = queryPrep(el.query);
        var f1 = current.fi + " 00:00:00.0";
        var f2 = current.ff + " 23:59:59.0";

        var res = await getGraficas(qry, f1, f2, "cygnimovilposicionamiento");

        var rDataPos = res.valoradas.posicionamiento;
        if (
          rDataPos !== null &&
          rDataPos !== undefined &&
          rDataPos !== "undefined"
        ) {
          var x = 0;
          var y = 0;
          Object.entries(rDataPos).forEach(([k, v]) => {
            var tot = 0;
            Object.entries(v).forEach(([kv, vv]) => {
              tot += vv.cantidad;
            });
            switch (k) {
              case "fortaleza":
                x += tot;
                break;
              case "oportunidad":
                y += tot;
                break;
              case "debilidad":
                x -= tot;
                break;
              case "amenaza":
                y -= tot;
                break;
              default:
                break;
            }
          });
        }

        pos.push([
          { nombre: el.qry_nombre, query: el.query, x: x, y: y, z: 1 },
        ]);
      }
    });

    // Wait for all requests, and then setState
    Promise.all(resMap).then(() => {
      console.log(pos);
      setData(pos);
    });
  }, [current, queries]);

  return (
    <VisContainer
      title="Posicionamiento Medios"
      desc="Posicionamiento de actor(es) en medios tradicionales."
      time={data ? true : false}
      downloadChart={downloadChart}
    >
      {!data && <CygniLoadingFix />}
      {data &&
        !current.id.includes(-1) &&
        current.id.length === 1 &&
        data[0] &&
        data[0][0] &&
        data[0][0].nombre && (
          <CygniRadarChart data={data[0]} dataKey="FODA" exportRef={ref} />
        )}
      {data &&
        (current.id.includes(-1) || current.id.length > 1) &&
        data[0] &&
        data[0][0] &&
        data[0][0].nombre && (
          <ResponsiveContainer>
            <ScatterChart
              margin={{ top: 30, right: 30, bottom: 10, left: 10 }}
              ref={ref}
            >
              {/* <XAxis type="number" dataKey={"x"} name="fortaleza" unit="" />
              <YAxis type="number" dataKey={"y"} name="oportunidad" unit="" /> */}
              <XAxis
                type="number"
                dataKey={"x"}
                name="Fortaleza/Debilidad"
                tickLine={false}
              />
              <YAxis
                type="number"
                dataKey={"y"}
                name="Oportunidad/Amenaza"
                tickLine={false}
              />
              <ZAxis
                dataKey={"z"}
                range={[40, 2000]}
                name="notas"
                // unit="notas"
              />
              <ReferenceLine y={0} stroke="red" />
              <ReferenceLine x={0} stroke="red" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {data &&
                data.map((el, i) => {
                  return (
                    <Scatter
                      key={i}
                      name={el[0].nombre}
                      data={el}
                      fill={COLORS[i]}
                      shape="circle"
                      line
                    />
                  );
                })}
            </ScatterChart>
          </ResponsiveContainer>
        )}
    </VisContainer>
  );
});
