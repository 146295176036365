import {
  Container,
  Typography,
  makeStyles,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from "@material-ui/core";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import React from "react";
import { numberWithCommas } from "../common/Cygni";
import { CygniLoadingFix } from "../common/CygniLoading";
import { CygniPieChart } from "../common/CygniPieChart";
import {
  getEstados,
  getMunicipios,
  getUtilCenso,
} from "../common/CygniRequests";
import { ExportCSV } from "../common/ExportCSV";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  params: {
    position: "absolute",
    top: "4rem",
    right: "2rem",
    backgroundColor: "white", //"transparent", //"#fff",
    margin: "12px",
  },
}));

export const IndCenso = () => {
  const classes = useStyles();
  const [edoList, setEdoList] = React.useState(null);
  const [munList, setMunList] = React.useState(null);
  const [edo, setEdo] = React.useState("0");
  const [mun, setMun] = React.useState("0");
  const [tipo, setTipo] = React.useState("0");
  const [search, setSearch] = React.useState({
    edo: null,
    mun: null,
    tipo: null,
  });
  const [data, setData] = React.useState(null);
  const [colData, setColData] = React.useState(null);
  const [chart, setChart] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showAppbar, setShowAppbar] = React.useState(true);

  React.useEffect(() => {
    getEstados()
      .then(function (response) {
        setEdoList(response);
      })
      .catch(function (error) {
        console.error(error);
        setEdoList(null);
      });
  }, []);

  React.useEffect(() => {
    setMun("0");
    if (edo !== "0") {
      getMunicipios(edo)
        .then(function (response) {
          setMunList(response);
        })
        .catch(function (error) {
          console.error(error);
          setMunList(null);
        });
    } else {
      setMunList(null);
    }
  }, [edo]);

  React.useEffect(() => {
    if (search.tipo === null) return;
    if (search.tipo !== "0") {
      setData(null);
      setChart(null);
      setColData(null);
      setLoading(true);
      _getUtilData();
    }
  }, [search]);

  const _getUtilData = async () => {
    if (search.tipo !== "0") {
      var res = await getUtilCenso(search.edo, search.mun, search.tipo);

      let tot;
      let categ;

      if (search.edo !== "0" && search.mun !== "0") {
        tot = null;
        categ = null;
      } else {
        if (tipo === "1") {
          // SALUD
          tot = [
            { name: "ConSalud", value: res[0].ConSalud },
            { name: "SinSalud", value: res[0].SinSalud },
          ];
          categ = [
            { name: "IMSS", value: res[0].IMSS },
            { name: "ISSSTE", value: res[0].ISSSTE },
            { name: "ISSSTE-EST", value: res[0].ISSSTE_Estatal },
            { name: "Bienestar", value: res[0].Bienestar },
            { name: "ImssBienestar", value: res[0].ImssBienestar },
            { name: "Pemex-Def-Marina", value: res[0].PemexDefMarina },
            { name: "Privada", value: res[0].Privada },
            { name: "Otra", value: res[0].Otra },
          ];
        } else if (tipo === "2") {
          // ECONOMICAS
          tot = [
            { name: "PobOcupada", value: res[0].PobOcupada },
            { name: "PobDesocupada", value: res[0].PobDesocupada },
            { name: "PobEconomAct", value: res[0].PobEconomAct },
            { name: "PobEconomInac", value: res[0].PobEconomInac },
          ];
          categ = null;
        }
      }

      setData(res);
      setChart({ tot: tot, categ: categ });

      if (res.length > 0 && "error" in res === false)
        setColData(Object.keys(res[0]));
      setLoading(false);
    }
  };

  const _consultar = () => {
    if (tipo !== "0") {
      setSearch({
        edo: edo,
        mun: mun,
        tipo: tipo,
      });
    }
  };

  const toggleAppbar = () => {
    setShowAppbar(!showAppbar);
  };

  let categLabel = function ({ x, y, name }) {
    return (
      <text x={x} y={y} fontSize={search.edo === "33" ? 10 : 11}>
        {name}
      </text>
    );
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography
        variant="h4"
        component="h4"
        align="center"
        color="primary"
        gutterBottom
      >
        Censo 2020
      </Typography>
      {loading && !data && <CygniLoadingFix />}
      {!loading && data && data.length > 0 && (
        <Box>
          {chart && (search.edo === "0" || search.mun === "0") && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={search.tipo === "2" ? 12 : 6}>
                {chart.tot && (
                  <Paper
                    variant="elevation"
                    elevation={2}
                    style={{
                      height: "15rem",
                      padding: "0.5rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <CygniPieChart
                      dataKey="value"
                      data={chart.tot}
                      labelLine={true}
                      label={categLabel}
                    />
                  </Paper>
                )}
              </Grid>
              {chart.categ && search.tipo === "1" && (
                <Grid item xs={12} sm={6}>
                  <Paper
                    variant="elevation"
                    elevation={2}
                    style={{
                      height: "15rem",
                      padding: "0.5rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <CygniPieChart
                      dataKey="value"
                      data={chart.categ}
                      labelLine={true}
                      label={categLabel}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {colData &&
                    colData.map((el, i) => {
                      if (el !== "idEnt" && el !== "idMun") {
                        return <TableCell key={"th_" + i}>{el}</TableCell>;
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {colData &&
                  colData.length > 0 &&
                  data.map((el, i) => {
                    return (
                      <TableRow key={"tr_" + i}>
                        {colData.map((sel, j) => {
                          if (sel !== "idEnt" && sel !== "idMun") {
                            return (
                              <TableCell key={"r" + j} align="right">
                                {sel !== "Entidad" && sel !== "Municipio"
                                  ? numberWithCommas(el[sel])
                                  : el[sel]}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Paper
        variant="elevation"
        elevation={4}
        style={{ width: "20rem" }}
        className={classes.params}
      >
        <Box display="flex" p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle1" component="h6">
              Parámetros
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="settings"
              color="inherit"
              edge="start"
              size="small"
              onClick={toggleAppbar}
            >
              {showAppbar ? <ToggleOn /> : <ToggleOff />}
            </IconButton>
          </Box>
        </Box>
        <Collapse in={showAppbar}>
          <Box p={1.5}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ marginBottom: "0.5rem" }}
              fullWidth
            >
              <InputLabel id="ind-seg-tipo-label">Tipo</InputLabel>
              <Select
                labelId="ind-seg-tipo-label"
                id="ing-seg-tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              >
                <MenuItem value="0">Seleccione Tipo</MenuItem>
                <MenuItem value="1">Salud</MenuItem>
                <MenuItem value="2">Características Económicas</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ marginBottom: "0.5rem" }}
              fullWidth
            >
              <InputLabel id="ind-seg-edo-label">Estado</InputLabel>
              <Select
                labelId="ind-seg-edo-label"
                id="ing-seg-edo"
                value={edo}
                onChange={(e) => setEdo(e.target.value)}
              >
                <MenuItem value="0">Nacional</MenuItem>
                {edoList &&
                  edoList.map((el, i) => {
                    if (el.cve !== 33 && el.cve !== 50) {
                      return (
                        <MenuItem key={"edo_" + i} value={el.cve}>
                          {el.valor}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginBottom: "0.5rem" }}
              size="small"
              fullWidth
            >
              <InputLabel id="ind-seg-mun-label">Municipio</InputLabel>
              <Select
                labelId="ind-seg-mun-label"
                id="ing-seg-mun"
                value={mun}
                onChange={(e) => setMun(e.target.value)}
              >
                <MenuItem value="0">Todos los municipios </MenuItem>
                {munList &&
                  munList.map((el, i) => {
                    if (el.cve !== 999 && el.cve !== 900) {
                      return (
                        <MenuItem key={"mun_" + i} value={el.cve}>
                          {el.valor}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginBottom: "0.5rem" }}
              onClick={_consultar}
            >
              Consultar
            </Button>
            {data && (
              <ExportCSV
                csvData={data}
                fileName={"censo-2020-" + edo + "-" + mun}
              />
            )}
          </Box>
        </Collapse>
      </Paper>
    </Container>
  );
};
