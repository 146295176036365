import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Typography } from "@material-ui/core";
import { CygniRadarChart } from "../../common/CygniRadarChart";
import { CygniLoading } from "../../common/CygniLoading";
import { getGraficas } from "../../common/CygniRequests";
import { numberWithCommas } from "../../common/Cygni";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";

export const MediosPosicionamiento = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [png, ref] = useRechartToPng();
  const [tot, setTot] = useState({ for: 0, opor: 0, deb: 0, ame: 0 });

  useEffect(() => {
    setData(null);
    setTot({ for: 0, opor: 0, deb: 0, ame: 0 });

    var qry = query
      .trim()
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/á/g, "a")
      .replace(/é/g, "e")
      .replace(/í/g, "i")
      .replace(/ó/g, "o")
      .replace(/ú/g, "u")
      .replace(/ñ/g, "n");
    var f1 = fecha1 + " 00:00:00.0";
    var f2 = fecha2 + " 23:59:59.0";

    getGraficas(qry, f1, f2, "cygnimovilposicionamiento")
      .then(function (response) {
        var posi = [];
        var totales = { for: 0, opor: 0, deb: 0, ame: 0 };
        var rData = response.valoradas.posicionamiento;
        if (rData !== null && rData !== undefined && rData !== "undefined") {
          Object.entries(rData).forEach(([k, v]) => {
            var tot = 0;
            Object.entries(v).forEach(([kv, vv]) => {
              tot += vv.cantidad;
            });
            if (k == "fortaleza") totales.for += tot;
            if (k == "oportunidad") totales.opor += tot;
            if (k == "debilidad") totales.deb += tot;
            if (k == "amenaza") totales.ame += tot;
            posi.push({ subject: k, FODA: tot, fullMark: tot });
          });
        }

        setData(posi);
        setTot(totales);
      })
      .catch(function (error) {
        console.error(error);
        setData([]);
      });
  }, [query, fecha1, fecha2]);

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "pos.png");
  }, [png]);

  return (
    <PaperContainer
      title="FODA"
      subtitle={
        data &&
        data.length > 0 &&
        tot &&
        numberWithCommas(tot.for) +
          " for, " +
          numberWithCommas(tot.opor) +
          " opor, " +
          numberWithCommas(tot.deb) +
          " deb, " +
          numberWithCommas(tot.ame) +
          " ame"
      }
      exportData={data}
      exportName="medios_posic"
      downloadChart={downloadChart}
    >
      {!data && <CygniLoading />}
      {data && data.length === 0 && (
        <Typography
          component="h5"
          variant="h5"
          align="center"
          style={{ marginTop: "10px" }}
        >
          No hay datos disponibles.
        </Typography>
      )}
      {data && data.length > 0 && (
        // <CygniRadarChart data={data} dataKey="FODA" />
        <CygniRadarChart data={data} dataKey="FODA" exportRef={ref} />
      )}
    </PaperContainer>
  );
});
