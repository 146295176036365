import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link } from "@reach/router";
import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Send, Twitter, Face, MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  topBox: {
    padding: theme.spacing(0.4),
    backgroundColor: "#474a6b",
    color: "white",
  },
  fixedHeightNormal: {
    height: 240,
  },
  fixedHeightVisNormal: {
    height: 320,
  },
  boldText: {
    fontWeight: "bold",
  },
  square: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    backgroundColor: "#474a6b",
    marginRight: "5px",
  },
  midContent: {
    // height: "80px",
  },
  detContent: {
    overflow: "auto",
    flexDirection: "column",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  footerContent: {
    padding: "0.3rem 0.5rem",
  },
}));

export const DashboardContainer = React.memo(({ title, tab, queries, vId }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={2}
      className={clsx(classes.paper, classes.fixedHeightNormal)}
    >
      <Box className={classes.topBox}>
        <Typography variant="subtitle2" align="center">
          {title}
        </Typography>
      </Box>
      <Box className={classes.detContent}>
        <Typography
          className={classes.boldText}
          variant="overline"
          display="block"
          color="primary"
        >
          Queries:
        </Typography>
        {queries &&
          queries.map((qry, i) => {
            return (
              <React.Fragment key={i}>
                <div className={classes.square} />
                <Typography variant="caption" style={{ fontWeight: "bold" }}>
                  {qry.qry_nombre}
                </Typography>
                <Box style={{ marginLeft: "1rem" }}>
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<Face />}
                    label={qry.query.replace(/,/g, " | ")}
                    style={{ margin: "0.2rem" }}
                  />
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<Twitter />}
                    label={qry.twitter === "null" ? "@" : qry.twitter}
                    style={{ margin: "0.2rem" }}
                  />
                </Box>
                <Divider
                  style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                />
              </React.Fragment>
            );
          })}
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          style={{ padding: "0.2rem" }}
        >
          Tablero Base: {tab}
        </Typography>
      </Box>
      <Grid container justify="flex-end">
        <Button
          color="primary"
          endIcon={<Send />}
          component={Link}
          to={"/vis/" + vId}
        >
          Ver más
        </Button>
      </Grid>
    </Paper>
  );
});

export const VisContainer = React.memo(
  ({
    children,
    title,
    desc,
    time,
    showMenu = true,
    downloadImgMenu = true,
    downloadChart = null,
  }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    Number.prototype.padLeft = function (base, chr) {
      var len = String(base || 10).length - String(this).length + 1;
      return len > 0 ? new Array(len).join(chr || "0") + this : this;
    };

    function getCurrDateTime() {
      var d = new Date(),
        dformat =
          [
            (d.getMonth() + 1).padLeft(),
            d.getDate().padLeft(),
            d.getFullYear(),
          ].join("/") +
          " " +
          [
            d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft(),
          ].join(":");
      return dformat;
    }

    return (
      <Paper
        className={clsx(classes.paper, classes.fixedHeightVisNormal)}
        elevation={3}
      >
        <Box display="flex" className={classes.topBox}>
          <Box pl={0.3} flexGrow={1}>
            <Typography variant="subtitle2" align="center">
              {title}
            </Typography>
          </Box>
          {showMenu && (
            <Box>
              <IconButton
                aria-label="settings"
                color="inherit"
                edge="start"
                size="small"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {downloadImgMenu && (
                  <MenuItem onClick={downloadChart}>Descargar Gráfica</MenuItem>
                )}
              </Menu>
            </Box>
          )}
        </Box>
        <Box
          className={classes.detContent}
          style={{ height: "100%", width: "100%" }}
        >
          {children}
        </Box>
        <Divider />
        <Box className={classes.footerContent}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography variant="caption" color="textSecondary">
                {desc}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container justify="flex-end">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="right"
                >
                  <small>{time && getCurrDateTime()}</small>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
);
