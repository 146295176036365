import React from "react";
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  Tooltip,
} from "recharts";

export const CygniRadarChart = ({
  data,
  dataKey = "A",
  exportRef = null,
  legend = false,
}) => {
  return (
    <ResponsiveContainer>
      <RadarChart outerRadius={60} data={data} ref={exportRef}>
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis angle={30} domain={[0, 150]} />
        <Radar
          dataKey={dataKey}
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
        <Tooltip />
        {legend && <Legend />}
      </RadarChart>
    </ResponsiveContainer>
  );
};
