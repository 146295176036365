import {
  Container,
  Typography,
  makeStyles,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Collapse,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import React from "react";
import { numberWithCommas } from "../common/Cygni";
import { CygniBarChart } from "../common/CygniBarChart";
import { CygniLoadingFix } from "../common/CygniLoading";
import {
  getEstados,
  getMunicipios,
  getUtilConeval,
} from "../common/CygniRequests";
import { ExportCSV } from "../common/ExportCSV";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  params: {
    position: "absolute",
    top: "4rem",
    right: "2rem",
    backgroundColor: "white", //"transparent", //"#fff",
    margin: "12px",
  },
}));

export const IndPobreza = () => {
  const classes = useStyles();
  const [edoList, setEdoList] = React.useState(null);
  const [munList, setMunList] = React.useState(null);
  const [edo, setEdo] = React.useState("33");
  const [mun, setMun] = React.useState("999");
  const [search, setSearch] = React.useState({
    edo: null,
    mun: null,
  });
  const [data, setData] = React.useState(null);
  const [colData, setColData] = React.useState(null);
  const [chart, setChart] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showAppbar, setShowAppbar] = React.useState(true);
  const totKeyVal = [{ Extrema: "#e94a36" }, { Moderada: "#465744" }];

  React.useEffect(() => {
    getEstados()
      .then(function (response) {
        setEdoList(response);
      })
      .catch(function (error) {
        console.error(error);
        setEdoList(null);
      });
  }, []);

  React.useEffect(() => {
    setMun("999");
    if (edo !== "33") {
      getMunicipios(edo)
        .then(function (response) {
          setMunList(response);
        })
        .catch(function (error) {
          console.error(error);
          setMunList(null);
        });
    } else {
      setMunList(null);
    }
  }, [edo]);

  React.useEffect(() => {
    if (search.edo && search.edo !== "33") {
      setData(null);
      setChart(null);
      setColData(null);
      setLoading(true);
      _getUtilData();
    }
  }, [search]);

  const _getUtilData = async () => {
    if (search.edo && search.edo !== "33") {
      var res = await getUtilConeval("pob", search.edo, search.mun);
      // SORT TOP 5 PobMod y PobExt
      var pobMod = res
        .sort(function (a, b) {
          return b.PobModerada - a.PobModerada;
        })
        .slice(0, 5);
      var pobExt = res
        .sort(function (a, b) {
          return b.PobExtrema - a.PobExtrema;
        })
        .slice(0, 5);
      let combined = [...new Set([...pobMod, ...pobExt])].map((el) => {
        return {
          name: el.Municipio,
          Moderada: el.PobModerada,
          Extrema: el.PobExtrema,
        };
      });

      setData(res);
      setChart({ topMod: pobMod, topExt: pobExt, pobSet: combined });
      if (res.length > 0 && "error" in res === false)
        setColData(Object.keys(res[0]));
      setLoading(false);
    }
  };

  const _consultar = () => {
    if (edo && mun && edo !== "33") {
      setSearch({
        edo: edo,
        mun: mun,
      });
    }
  };

  const toggleAppbar = () => {
    setShowAppbar(!showAppbar);
  };

  let legendRender = function (value) {
    return <span style={{ fontSize: "0.7rem" }}>{value}</span>;
  };

  let customLabel = function ({ x, y, stroke, value }) {
    return (
      <text x={x} y={y} fill={stroke} fontSize={9}>
        {value}
      </text>
    );
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography
        variant="h4"
        component="h4"
        align="center"
        color="primary"
        gutterBottom
      >
        Indicadores Pobreza (CONEVAL)
      </Typography>
      {loading && !data && <CygniLoadingFix />}
      {!loading && data && data.length > 0 && (
        <Box>
          <Paper
            variant="elevation"
            elevation={2}
            style={{
              height: "20rem",
              padding: "0.5rem",
              marginBottom: "0.7rem",
            }}
          >
            <CygniBarChart
              data={chart.pobSet}
              keysAndColors={totKeyVal}
              legendRender={legendRender}
              iconSize={11}
              // exportRef={ref}
              // barLabel={{ position: "top", fontSize: 9 }}
              barLabel={customLabel}
            />
          </Paper>
          {search.mun === "999" && (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    variant="elevation"
                    elevation={2}
                    style={{
                      height: "15rem",
                      padding: "0.5rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      color="secondary"
                      align="center"
                    >
                      Top 5 Municipios con Pobreza Moderada
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Municipio</TableCell>
                            <TableCell>Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {chart.topMod &&
                            chart.topMod.length > 0 &&
                            chart.topMod.map((tel, i) => (
                              <TableRow key={"tel_" + i}>
                                <TableCell>{tel.Municipio}</TableCell>
                                <TableCell align="right">
                                  {tel.PobModerada}%
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    variant="elevation"
                    elevation={2}
                    style={{
                      height: "15rem",
                      padding: "0.5rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      color="secondary"
                      align="center"
                    >
                      Top 5 Municipios con Pobreza Extrema
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Municipio</TableCell>
                            <TableCell>Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {chart.topExt &&
                            chart.topExt.length > 0 &&
                            chart.topExt.map((tel, i) => (
                              <TableRow key={"tel_" + i}>
                                <TableCell>{tel.Municipio}</TableCell>
                                <TableCell align="right">
                                  {tel.PobExtrema}%
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {colData &&
                    colData.map((el, i) => {
                      if (el !== "idEnt" && el !== "idMun") {
                        return <TableCell key={"th_" + i}>{el}</TableCell>;
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {colData &&
                  colData.length > 0 &&
                  data.map((el, i) => {
                    return (
                      <TableRow key={"tr_" + i}>
                        {colData.map((sel, j) => {
                          if (sel !== "idEnt" && sel !== "idMun") {
                            return (
                              <TableCell key={"r" + j} align="right">
                                {sel !== "Entidad" && sel !== "Municipio"
                                  ? numberWithCommas(el[sel])
                                  : el[sel]}
                                {sel !== "PobTot" &&
                                  sel !== "Entidad" &&
                                  sel !== "Municipio" &&
                                  "%"}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Paper
        variant="elevation"
        elevation={4}
        style={{ width: "20rem" }}
        className={classes.params}
      >
        <Box display="flex" p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle1" component="h6">
              Parámetros
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="settings"
              color="inherit"
              edge="start"
              size="small"
              onClick={toggleAppbar}
            >
              {showAppbar ? <ToggleOn /> : <ToggleOff />}
            </IconButton>
          </Box>
        </Box>
        <Collapse in={showAppbar}>
          <Box p={1.5}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ marginBottom: "0.5rem" }}
              fullWidth
            >
              <InputLabel id="ind-seg-edo-label">Estado</InputLabel>
              <Select
                labelId="ind-seg-edo-label"
                id="ing-seg-edo"
                value={edo}
                onChange={(e) => setEdo(e.target.value)}
              >
                <MenuItem value="33">Seleccione un estado</MenuItem>
                {edoList &&
                  edoList.map((el, i) => {
                    if (el.cve !== 33 && el.cve !== 50) {
                      return (
                        <MenuItem key={"edo_" + i} value={el.cve}>
                          {el.valor}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginBottom: "0.5rem" }}
              size="small"
              fullWidth
            >
              <InputLabel id="ind-seg-mun-label">Municipio</InputLabel>
              <Select
                labelId="ind-seg-mun-label"
                id="ing-seg-mun"
                value={mun}
                onChange={(e) => setMun(e.target.value)}
              >
                <MenuItem value="999">Todos los municipios</MenuItem>
                {munList &&
                  munList.map((el, i) => {
                    if (el.cve !== 999 && el.cve !== 900) {
                      return (
                        <MenuItem key={"mun_" + i} value={el.cve}>
                          {el.valor}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginBottom: "0.5rem" }}
              onClick={_consultar}
            >
              Consultar
            </Button>
            {data && (
              <ExportCSV
                csvData={data}
                fileName={"coneval-pob-" + edo + "-" + mun}
              />
            )}
          </Box>
        </Collapse>
      </Paper>
    </Container>
  );
};
