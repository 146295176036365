import React, { useEffect, useState } from "react";
import { getGraficas } from "../../common/CygniRequests";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { numberWithCommas, queryPrep } from "../../common/Cygni";
import { CygniLoadingFix } from "../../common/CygniLoading";
import { VisContainer } from "../../common/CygniContainers";

export const AmbitoNotas = React.memo(({ queries, current }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);

    var ambitos = [];
    var resMap = queries.map(async (el) => {
      if (
        current.id.includes(-1) ||
        (!current.id.includes(-1) && current.id.includes(el.qry_id))
      ) {
        var res = await getGraficas(
          queryPrep(el.query),
          current.fi.replace(/-/g, "_"),
          current.ff.replace(/-/g, "_"),
          "cygnimovilambito"
        );

        ambitos.push({
          query: el.qry_nombre,
          i: res.internacional ? res.internacional : 0,
          n: res.nacional ? res.nacional : 0,
          e: res.estatal ? res.estatal : 0,
        });
      }
    });

    // Wait for all requests, and then setState
    Promise.all(resMap).then(() => {
      setData(ambitos);
    });
  }, [current, queries]);

  return (
    <VisContainer
      title="Ámbito Medios"
      desc="Ámbito de actor(es) en medios tradicionales."
      time={data ? true : false}
      showMenu={false}
    >
      {!data && <CygniLoadingFix />}
      {data && !current.id.includes(-1) && current.id.length === 1 && (
        <React.Fragment>
          <Typography
            component="h4"
            variant="h4"
            color="primary"
            align="center"
          >
            {/* <Link
              href="#"
              onClick={() => chartClick(getAmbitoClaveIntelite("nacional"))}
            > */}
            {data[0].n && numberWithCommas(data[0].n)}
            {/* </Link> */}
          </Typography>
          <Typography component="h5" variant="h6" align="center">
            Nacionales
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            color="primary"
            align="center"
          >
            {/* <Link
              href="#"
              onClick={() => chartClick(getAmbitoClaveIntelite("estatal"))}
            > */}
            {data[0].e && numberWithCommas(data[0].e)}
            {/* </Link> */}
          </Typography>
          <Typography component="h5" variant="h6" align="center">
            Estatales
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            color="primary"
            align="center"
          >
            {/* <Link
              href="#"
              onClick={() =>
                chartClick(getAmbitoClaveIntelite("internacional"))
              }
            > */}
            {data[0].i && numberWithCommas(data[0].i)}
            {/* </Link> */}
          </Typography>
          <Typography component="h5" variant="h6" align="center">
            Internacionales
          </Typography>
        </React.Fragment>
      )}
      {data && (current.id.includes(-1) || current.id.length > 1) && (
        <List>
          {data.length > 0 &&
            data.map((el, i) => {
              return (
                <ListItem key={i}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" color="primary">
                        {el.query}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle1" color="textSecondary">
                        {numberWithCommas(el.n) +
                          " nacionales, " +
                          numberWithCommas(el.e) +
                          " estatales y " +
                          numberWithCommas(el.i) +
                          " internacionales."}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      )}
    </VisContainer>
  );
});
