import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { TagCloud } from "react-tagcloud";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  boldText: {
    fontWeight: "bold",
  },
  //   fixedHeighSmall: {
  //     height: 80,
  //   },
  //   fixedHeightNormal: {
  //     height: 340,
  //   },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightBold,
  },
  //   fab: {
  //     margin: 0,
  //     top: "auto",
  //     right: 20,
  //     bottom: 20,
  //     left: "auto",
  //     position: "fixed",
  //   },
}));

export const DetEncuesta = (props) => {
  const classes = useStyles();

  const [encuesta, setEncuesta] = useState({});
  const [respuestas, setRespuestas] = useState([]);
  const [totales, setTotales] = useState({});

  useEffect(() => {
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // API
    axios
      .get("http://162.243.166.142:5002/api/encuestas/" + props.id, config)
      .then(function (response) {
        // console.log(response.data);
        setEncuesta(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setEncuesta({});
      });

    axios
      .get("http://162.243.166.142:5002/api/respuestas/" + props.id, config)
      .then(function (response) {
        var tots = {};
        var wordTots = {};
        response.data.forEach((x) =>
          x.respuestas.forEach((xi) => {
            var ind = xi.indice.toString();
            var tp = xi.tipo;
            var resp = xi.resp.toString();

            if (tots.hasOwnProperty(ind)) {
              if (tp === "text") {
                var w_sep = resp.split(/\s/);
                for (const i in w_sep) {
                  var word = w_sep[i];
                  if (!(word in wordTots[ind])) {
                    wordTots[ind][word] = 1;
                  } else {
                    wordTots[ind][word] += 1;
                  }
                }
                // tots[ind].push(wordTots);
                // tots[ind].push({ op: resp, count: 1 });
              } else {
                const el = tots[ind].filter((e) => e.op === resp);
                if (el.length < 1) {
                  tots[ind].push({ op: resp, count: 1 });
                } else {
                  tots[ind].forEach((e) => {
                    if (e.op === resp) {
                      e.count += 1;
                    }
                  });
                }
              }
            } else {
              if (tp === "text") {
                var words = {};
                var w_sep_text = resp.split(/\s/);
                for (const i in w_sep_text) {
                  var word_text = w_sep_text[i];
                  if (!(word_text in words)) {
                    words[word_text] = 1;
                  } else {
                    words[word_text] += 1;
                  }
                }
                // tots[ind] = [words];
                wordTots[ind] = words;
              } else {
                tots[ind] = [{ op: resp, count: 1 }];
              }
              // tots[ind] = [{ op: resp, count: 1 }];
            }
            //
            Object.entries(wordTots).forEach((entry) => {
              const [k, v] = entry;
              var ws = [];
              // tots[k] = [v];
              Object.entries(v)
                .sort((a, b) => b[1] - a[1])
                .forEach((e) => {
                  const [ki, vi] = e;
                  ws.push({ value: ki, count: vi });
                });
              tots[k] = ws;
            });
          })
        );

        setTotales(tots);
        // console.log(tots);
        setRespuestas(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setRespuestas([]);
      });
  }, []);

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#9c16f5",
    "#037801",
    "#d303fc",
  ];

  let renderLabel = function (entry) {
    return entry.op;
  };

  const data = [
    { value: "JavaScript", count: 38 },
    { value: "React", count: 30 },
    { value: "Nodejs", count: 28 },
    { value: "Express.js", count: 25 },
    { value: "HTML5", count: 33 },
    { value: "MongoDB", count: 18 },
    { value: "CSS3", count: 20 },
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Detalle de la Encuesta
      </Typography>
      <Paper className={classes.paper} style={{ marginBottom: "1rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" component="h5">
              Título: <small>{encuesta.titulo}</small>
            </Typography>
            <Typography variant="h5" component="h5">
              Nivel: <small>{encuesta.nivel}</small>
            </Typography>
            <Typography variant="h5" component="h5">
              Frecuencia: <small>{encuesta.frecuencia}</small>
            </Typography>
            <Typography variant="h5" component="h5">
              Fecha: <small>{encuesta.date}</small>
            </Typography>
            <Typography variant="h5" component="h5" align="justify">
              Descripción: <small>{encuesta.descripcion}</small>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h5"
              component="h5"
              align="center"
              color="primary"
            >
              Respuestas recibidas:
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              color="primary"
            >
              {respuestas.length}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {/* <Paper className={classes.paper}> */}
      {encuesta &&
        encuesta.reactivos &&
        encuesta.reactivos.map((enc) => (
          <ExpansionPanel key={enc._id}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {enc.indice + ".- " + enc.texto}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {/* <Grid item xs={12} sm={1}>
                    <Typography className={classes.boldText}>
                      Tipo: {enc.tipo}
                    </Typography>
                    <Typography className={classes.boldText}>
                      Respuestas posibles:
                      {enc.ops &&
                        enc.ops.map((op) => <li key={op._id}>{op.texto}</li>)}
                    </Typography>
                  </Grid> */}
              <Grid container spacing={1} style={{ minHeight: "200px" }}>
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.boldText}>
                            Opción
                          </TableCell>
                          <TableCell className={classes.boldText}>
                            Cantidad
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {totales &&
                          totales[enc.indice] &&
                          totales[enc.indice].map((t, i) => {
                            if (enc.tipo === "text") {
                              if (i < 3) {
                                return (
                                  <TableRow key={i + "_" + t.value}>
                                    <TableCell className={classes.boldText}>
                                      {t.value}
                                    </TableCell>
                                    <TableCell className={classes.boldText}>
                                      {t.count}
                                    </TableCell>
                                  </TableRow>
                                );
                              } else {
                                return (
                                  <React.Fragment
                                    key={i + "_" + t.value}
                                  ></React.Fragment>
                                );
                              }
                            } else {
                              return (
                                <TableRow key={i}>
                                  <TableCell className={classes.boldText}>
                                    {t.op}
                                  </TableCell>
                                  <TableCell className={classes.boldText}>
                                    {t.count}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {enc.tipo === "text" && (
                    <Typography
                      component="small"
                      variant="caption"
                      display="block"
                      align="right"
                      color="secondary"
                    >
                      Top 3 palabras
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} align="center">
                  {enc.tipo === "text" && (
                    <TagCloud
                      minSize={12}
                      maxSize={35}
                      tags={totales[enc.indice]}
                    />
                  )}
                  {enc.tipo !== "text" && (
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="count"
                          nameKey="op"
                          isAnimationActive={false}
                          data={totales[enc.indice]}
                          // cx={100}
                          // cy={100}
                          outerRadius={60}
                          fill="#8884d8"
                          label={renderLabel}
                        >
                          {totales[enc.indice] &&
                            totales[enc.indice].map((entry, index) => (
                              <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      {/* </Paper> */}
    </Container>
  );
};
