import React, { useState, useContext } from "react";
import clsx from "clsx";
import { Link } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import {
  Menu,
  ChevronLeft,
  Settings,
  Build,
  ExitToApp,
  PinDrop
} from "@material-ui/icons";
import { mainListItems, secondaryListItems } from "./DrawerListItems";
import { UserContext } from "../contexts/user-context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

export const NavBar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // userContext
  const userContext = useContext(UserContext);

  const logout = () => {
    userContext.loggedOut();
  };

  return (
    <React.Fragment>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <Menu />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Cygni <Typography variant="caption">2.3</Typography>
          </Typography>
          <Typography variant="caption" color="inherit" noWrap>
            Usuario: {userContext.usuario}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        {userContext &&
          (userContext.tipo === "ADMIN" ||
            userContext.tipo === "ALL" ||
            userContext.tipo === "MEDIOS") && (
            <React.Fragment>
              <List>{mainListItems}</List>
              <Divider />
            </React.Fragment>
          )}
        {userContext &&
          (userContext.tipo === "ADMIN" ||
            userContext.tipo === "ALL" ||
            userContext.tipo === "DATOS") && (
            <React.Fragment>
              <List>{secondaryListItems}
                {userContext.cliente !== "EXTERNO" && <ListItem button component={Link} to="/datos_avanzados">
                  <ListItemIcon>
                    <PinDrop />
                  </ListItemIcon>
                  <ListItemText primary="Datos Avanzados" />
                </ListItem>}
              </List>
              <Divider />
            </React.Fragment>
          )}
        <List>
          <ListSubheader inset>Sistema</ListSubheader>
          {userContext && userContext.cliente === "INTERNO" && (
            <ListItem button component={Link} to="/util">
              <ListItemIcon>
                <Build />
              </ListItemIcon>
              <ListItemText primary="Utilerías" />
            </ListItem>
          )}
          <ListItem button component={Link} to="/config">
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Configuración" />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Cerrar" />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};
