import React from "react";
import { Box, Typography } from "@material-ui/core";

export const SectionTitle = React.memo(({ title }) => {
  return (
    <Typography component="h2" variant="h5" color="primary" align="center">
      {title}
    </Typography>
  );
});

export const PaperTitle = React.memo(({ title }) => {
  return (
    <Typography component="h6" variant="h6" color="primary">
      {title}
    </Typography>
  );
});

export const PaperSubtitle = React.memo(({ subtitle }) => {
  return (
    <Typography variant="caption" color="textPrimary">
      <em>{subtitle}</em>
    </Typography>
  );
});
