import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { CircularProgress, Modal, Typography } from "@material-ui/core";
import { CygniBarChart } from "../../common/CygniBarChart";
import {
  queryPrep,
  getDatesDiffInDays,
  getInterval_dwmy,
  numberWithCommas,
} from "../../common/Cygni";
import { CygniModalList } from "../../common/CygniModal";
import {
  getHistograma,
  getNotasParaExportar,
} from "../../common/CygniRequests";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CygniLoading } from "../../common/CygniLoading";
import { useRechartToPng } from "recharts-to-png";

export const MediosHistograma = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [moduleData, setModuleData] = useState(null);
  const [open, setOpen] = useState(false);
  const [exportStatus, setExportStatus] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [png, ref] = useRechartToPng();
  const qry = queryPrep(query);
  const f1 = fecha1.replace(/-/g, "_");
  const f2 = fecha2.replace(/-/g, "_");
  const diffDays = getDatesDiffInDays(fecha1, fecha2);
  const interval = getInterval_dwmy(diffDays);

  useEffect(() => {
    setData(null);
    setExportStatus("");

    // Histograma
    getHistograma(qry, f1, f2, interval)
      .then(function (response) {
        setData(response.data);
        setModuleData(response.moduleData);
      })
      .catch(function (error) {
        setData([]);
        setModuleData(null);
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  const chartClick = (chartData, index) => {
    if (chartData && chartData.activePayload !== null) {
      handleOpen(chartData.activePayload[0].payload);
    }
  };

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const handleOpen = async (selectedData) => {
    setOpen(true);
    setSelectedData(selectedData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exportarNotas = () => {
    if (data && moduleData) {
      setExportStatus("LOADING");
      getNotasParaExportar(qry, fecha1, fecha2, moduleData.totNotas)
        .then(function (response) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const exportData = response.data.busqueda1;
          const ws = XLSX.utils.json_to_sheet(exportData);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, "query_histograma" + fileExtension);
          setExportStatus("DONE");
        })
        .catch(function (error) {
          console.error(error);
          setExportStatus("ERROR");
        });
    }
  };

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "histograma.png");
  }, [png]);

  return (
    <React.Fragment>
      <PaperContainer
        title="Notas por Día"
        subtitle={
          data &&
          data.length > 0 &&
          moduleData &&
          moduleData.totNotas &&
          numberWithCommas(moduleData.totNotas) + " notas"
        }
        exportData={data}
        exportName="medios_histograma"
        exportarNotas={exportarNotas}
        downloadChart={downloadChart}
      >
        {exportStatus === "LOADING" && (
          <React.Fragment>
            <Typography color="primary" align="center" variant="caption">
              Exportando notas...
            </Typography>
            <CircularProgress
              size={15}
              color="secondary"
              style={{ margin: "auto" }}
            />
          </React.Fragment>
        )}
        {exportStatus === "DONE" && (
          <Typography color="primary" align="center" variant="caption">
            Exportación de notas finalizada.
          </Typography>
        )}
        {exportStatus === "ERROR" && (
          <Typography color="secondary" align="center" variant="caption">
            Exportación de notas falló.
          </Typography>
        )}
        {!data && <CygniLoading />}
        {data && data.length === 0 && (
          <Typography
            component="h5"
            variant="h5"
            align="center"
            style={{ marginTop: "10px" }}
          >
            No hay datos disponibles.
          </Typography>
        )}
        {data && data.length > 0 && (
          <CygniBarChart
            data={data}
            keysAndColors={[{ notas: "#f55a1d" }]}
            chartClick={chartClick}
            exportRef={ref}
            legend={false}
          />
        )}
      </PaperContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CygniModalList
            qry={qry}
            f1={f1}
            f2={f2}
            interval={interval}
            selectedData={selectedData}
            title="Detalle de notas"
            loadMore={true}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
});
