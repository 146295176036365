import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { WordsHighlight } from "react-words-highlight";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "small",
  },
  highlightWord: {
    backgroundColor: "yellow",
  },
}));

export const CygniSimpleList = ({
  data,
  primary = "name",
  secondary = "val",
  primarySufix = "",
  secondarySufix = "",
  clickItemOpenUrl = false,
  onItemClick = null,
}) => {
  function openUrl(url) {
    window.open(url, "_blank");
  }

  function handleOnItemClick(val) {
    if (clickItemOpenUrl) {
      openUrl(val.url);
      return;
    }

    if (onItemClick !== null) {
      onItemClick(val.clave);
      return;
    }
  }

  return (
    <List>
      {data &&
        data.length > 0 &&
        data.map((d, i) => (
          <ListItem key={i} button onClick={() => handleOnItemClick(d)}>
            <ListItemText
              primary={
                primary === "name"
                  ? d.name + primarySufix
                  : d.val + primarySufix
              }
              secondary={
                secondary === "val"
                  ? d.val + secondarySufix
                  : d.name + secondarySufix
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

export const CygniNotesList = ({
  data,
  primary = "name",
  primarySufix = "",
  clickItemOpenUrl = false,
  query = "",
}) => {
  function openUrl(url) {
    window.open(url, "_blank");
  }

  function handleOnItemClick(val) {
    if (clickItemOpenUrl) {
      openUrl(val);
    }
  }

  const classes = useStyles();

  return (
    <List>
      {data &&
        data.length > 0 &&
        data.map((d, i) => (
          <ListItem key={i} button onClick={() => handleOnItemClick(d.url)}>
            <ListItemText
              primary={
                primary === "name"
                  ? d.name + primarySufix
                  : d.val + primarySufix
              }
              primaryTypographyProps={{
                className: classes.title,
                color: "primary",
              }}
              secondaryTypographyProps={{
                className: classes.subtitle,
              }}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                  >
                    {d.val}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary"
                  >
                    {"Medio: " + d.nombre}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="caption"
                    color="secondary"
                  >
                    {d.ambito + " - " + d.clasif + " - " + d.tipoMedio}
                  </Typography>
                  <br />
                  <WordsHighlight
                    text={d.texto}
                    query={query
                      .replace(/~/g, " ")
                      .replace(/_/g, " ")
                      .replace(/\./g, " ")
                      .replace(/,/g, " ")}
                    highlightTag="strong"
                    highlightClassName={classes.highlightWord}
                  />
                  {/* {d.texto.substring(0, 597) + "..."} */}
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

// export const CygniAvatarList = () => {
//   return <List></List>;
// };
