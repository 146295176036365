import React, { useState, useEffect } from "react";
import { PaperContainer } from "../../common/PaperContainer";
import { Modal, Typography } from "@material-ui/core";
import { CygniPieChart } from "../../common/CygniPieChart";
import {
  queryPrep,
  getPorTipoMedioClaveIntelite,
  numberWithCommas,
} from "../../common/Cygni";
import { CygniModalList } from "../../common/CygniModal";
import { getGraficas } from "../../common/CygniRequests";
import { CygniLoading } from "../../common/CygniLoading";
import * as FileSaver from "file-saver";
import { useRechartToPng } from "recharts-to-png";
// import { CygniBarChart } from "../../common/CygniBarChart";

export const MediosClasificacion = React.memo(({ query, fecha1, fecha2 }) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [filtro, setFiltro] = useState("null");
  const [tot, setTot] = useState(null);
  const [png, ref] = useRechartToPng();
  const qry = queryPrep(query);
  const f1 = fecha1 + " 00:00:00.0";
  const f2 = fecha2 + " 23:59:59.0";
  const f1Buscar = fecha1.replace(/-/g, "_");
  const f2Buscar = fecha2.replace(/-/g, "_");
  // const [changeChart, setChangeChart] = useState(false);

  useEffect(() => {
    setData(null);

    // Gráficas
    getGraficas(qry, f1, f2, "cygnimovilmedio")
      .then(function (response) {
        setData(response);
        var totales = 0;
        Object.entries(response).forEach(([_, v]) => {
          totales += v.value;
        });
        setTot(totales);
      })
      .catch(function (error) {
        setData([]);
        console.error(error);
      });
  }, [query, fecha1, fecha2]);

  const chartClick = (chartData, index) => {
    if (chartData && chartData.payload !== null) {
      handleOpen(chartData.payload.payload);
    }
  };

  const handleOpen = async (selectedData) => {
    setOpen(true);
    setFiltro(getPorTipoMedioClaveIntelite(selectedData.name));
  };

  const handleClose = () => {
    setOpen(false);
  };

  let categLabel = function (entry) {
    return entry.name;
  };

  const downloadChart = React.useCallback(async () => {
    FileSaver.saveAs(png, "clasif_notas.png");
  }, [png]);

  return (
    <React.Fragment>
      <PaperContainer
        title="Clasificación"
        subtitle={
          data && data.length > 0 && tot && numberWithCommas(tot) + " notas"
        }
        exportData={data}
        exportName="medios_clasif"
        downloadChart={downloadChart}
        // toggleChart={() => setChangeChart(!changeChart)}
      >
        {!data && <CygniLoading />}
        {data && data.length === 0 && (
          <Typography
            component="h5"
            variant="h5"
            align="center"
            style={{ marginTop: "10px" }}
          >
            No hay datos disponibles.
          </Typography>
        )}
        {/* {data && data.length > 0 && !changeChart && ( */}
        {data && data.length > 0 && (
          <CygniPieChart
            dataKey="value"
            data={data}
            labelLine={true}
            label={categLabel}
            chartClick={chartClick}
            exportRef={ref}
            legend={true}
          />
        )}
        {/* {data && data.length > 0 && changeChart && (
          <CygniBarChart
            data={data}
            keysAndColors={[{ value: "#6d4fe3" }]}
            chartClick={chartClick}
            exportRef={ref}
          />
        )} */}
      </PaperContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CygniModalList
            qry={qry}
            f1={f1Buscar}
            f2={f2Buscar}
            title="Detalle de notas"
            loadMore={true}
            porTipoTipo={filtro}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
});
